import { Card, Col, Divider, Modal, Row, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import PersonalAnalyst from '../../../components/widgets/Different-Widgets/personalAnalyst';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import AnalystInformation from '../MortagageAplication/DocumetationNew/Modals/AgentInformation';
import StagesMortgages from '../../../components/widgets/Different-Widgets/stagesMortgagesWidget';
import AutomaticDocumentationStartupModal from 'mortgage-documents/src/document-boxes/AutomaticDocumentation/Modal/AutomaticDocumentationStartupModal';
import DocumentationAccordions from './DocumentationAccordion/DocumentationAccordions';
import CardsMkt from './Components/CardsMkt';
import { Request } from '../Documentation/Utils/types';
import numeral from 'numeral';
import i18n from '../../../i18n';
import moment from 'moment';

const { Text } = Typography;
const security = require('../../../assets/onboarding/icon_security.png');
const logoSpain = require('../../../assets/onboarding/logo_españa.png');
const logoSpainBank = require('../../../assets/onboarding/logo_banco_españa.png');

export type ReduxStore = {
	auth: StoreAuth;
	data: StoreAuth;
};

export type StoreAuth = {
	token: string;
	roles: string;
	operations: any;
	dataProfile: any;
};

const DocumentatitonCore = ({ stages, userMortgageId, valuesDataAgent }) => {
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [analystData, setAnalystData] = useState<any>(undefined);

	const { t } = useTranslation();
	const dataProfile = useSelector<ReduxStore, any>((state) => state.data.dataProfile);
	const [modalStage, setModalStage] = useState<any>(false);
	const [mortgage, setMortgage] = useState<any>(undefined);
	const [showAppraisal, setShowAppraisal] = useState<boolean>(false);
	const [allDenied, setAllDenied] = useState<boolean>(false);
	const [nameOperations, setNameOperations] = useState<any>(undefined);
	const [owners, setOwners] = useState<any>(undefined);
	const [dataResume, setDataResume] = useState<boolean>(false);
	const [nameBanks, setNameBanks] = useState<any[]>([]);

	useEffect(() => {
		getOperationSummary();
		getPhoto();
		getDataOperation();
		getBanks();
		if (stages === 'banks') {
			getBankRequest();
		}
	}, []);

	useEffect(() => {
		renderTitleByStages();
		renderTextByStages();
	}, [showAppraisal, stages]);

	const getOperationSummary = () => {
		axios
			.get(`${env.api.url}/operations/operation/summary`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				const operation = response.data.data.find(
					(operation) => operation.operationId === userMortgageId
				);
				setNameOperations(operation);

				setOwners(operation.owners);
			});
	};

	const getBanks = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-banks`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setNameBanks(response.data.data);
			});
	};

	const getBankRequest = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-operation-bank-requests/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				const denyBank = response.data.data.filter((request: Request) => {
					if (request.status === 'deny') {
						return request;
					}
				});
				if (denyBank.length === response.data.data.length) {
					setAllDenied(true);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getDataOperation = () => {
		axios
			.get(`${env.api.url}/v1/operations/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setMortgage(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getPhoto = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/agents/${userMortgageId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setAnalystData(response.data.data);
			});
	};

	const renderTitleByStages = () => {
		if (stages === 'documents') {
			return t('name.docsNew2', { name: dataProfile?.name });
		} else if (stages === 'analysis') {
			return dataProfile?.name + ', ' + t('name.analysisNew');
		} else if (stages === 'banks' && allDenied === false) {
			return dataProfile?.name + ', ' + t('name.banksNew');
		} else if (stages === 'banks' && allDenied) {
			return dataProfile?.name + ', ' + t('name.banksNewDenied');
		} else if (stages === 'oferts' && !showAppraisal) {
			return t('name.ofertsNew', { name: dataProfile?.name });
		} else if (stages === 'appraisal' || showAppraisal) {
			return t('name.appraisalNew', { name: dataProfile?.name });
		} else if (stages === 'formalization') {
			return t('name.formalizationNew');
		}
	};

	const renderTextByStages = () => {
		if (stages === 'documents') {
			return t('name.docsNew1');
		} else if (stages === 'analysis') {
			return t('name.analysisNew2');
		} else if (stages === 'banks' && allDenied === false) {
			return t('name.banksNew2');
		} else if (stages === 'banks' && allDenied) {
			return t('name.banksNew3');
		} else if (stages === 'oferts' && !showAppraisal) {
			return t('name.ofertsNew2') + '. ' + t('name.ofertsNew3');
		} else if (stages === 'appraisal' || showAppraisal) {
			return t('name.appraisalNew2');
		} else if (stages === 'formalization') {
			return t('name.formalizationNew2');
		}
	};

	const renderJob = (value) => {
		const options = [
			{ label: 'viability.fixedearner', value: 'EMPLOYEE_FIXED' },
			{ label: 'viability.employeeTemporary', value: 'EMPLOYEE_TEMPORAL' },
			{ label: 'form.autonomous', value: 'FREELANCER' },
			{ label: 'form.businessOwner', value: 'BUSINESS_OWNER' },
			{ label: 'form.official', value: 'FUNCTIONARY' },
			{ label: 'form.pensioner', value: 'PENSIONER' },
			{ label: 'form.rentier', value: 'RENTIER' },
			{ label: 'form.officialInterim', value: 'FUNCTIONARY_INTERIM' },
			{ label: 'form.unemployed', value: 'UNEMPLOYED' },
			{ label: 'form.unknow', value: 'UNKNOW' }
		];
		return t(options.find((item) => item.value === value).label);
	};

	let documentViability;
	if (mortgage) {
		if (mortgage.subtype === 'subrogation') {
			axios
				.get(`${env.api.url}/v1/mortgages/generate-pdf-viabilidad-subrogacion/${userMortgageId}`, {
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				})
				.then((response) => {
					documentViability = response.data.url;
				});
		} else if (mortgage?.operationData.mortgage.houseState === 'have_decide') {
			documentViability = `${env.api.url}/v1/banks/generate-pdf-viabilidad-casaElegida/${userMortgageId}/${i18n.language}?token=${userToken}`;
		} else if (
			mortgage?.operationData.mortgage.houseState === 'searching' &&
			mortgage?.operationData?.mortgage?.viabilityData?.operation &&
			mortgage?.operationData.mortgage.viabilityData.operation.mortgageAmountLimit === 'savings'
		) {
			documentViability = `${env.api.url}/v1/banks/generate-pdf-viabilidad-limite-ahorro/${userMortgageId}/${i18n.language}?token=${userToken}`;
		} else if (
			mortgage?.operationData.mortgage.houseState === 'searching' &&
			mortgage?.operationData?.mortgage?.viabilityData?.operation &&
			mortgage?.operationData.mortgage.viabilityData.operation.mortgageAmountLimit === 'fixed'
		) {
			documentViability = `${env.api.url}/v1/banks/generate-pdf-viabilidad-limite-endeudamiento/${userMortgageId}/${i18n.language}?token=${userToken}`;
		}
	}

	const DocumentsResidentMortgageEs =
		'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_es.pdf';
	const DocumentsResidentMortgageEn =
		'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_en.pdf';
	const DocumentsResidentMortgageCat =
		'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/docu_bienvenida_hipoteca_residentes_cat.pdf';

	let documentToDownload;

	if (i18n.language === 'es' || i18n.language === 'es-ES') {
		documentToDownload = DocumentsResidentMortgageEs;
	} else if (i18n.language === 'en') documentToDownload = DocumentsResidentMortgageEn;
	else {
		documentToDownload = DocumentsResidentMortgageCat;
	}

	const renderClick = () => {
		window.open(documentViability);
		window.open(documentToDownload);
	};

	return (
		<div
			style={{
				paddingLeft: isMobileOnly ? 16 : 40,
				paddingRight: isMobileOnly ? 16 : 40,
				paddingBottom: 150,
				width: '100%'
			}}>
			<Row gutter={24}>
				<Col lg={18} md={18} sm={18} xs={24} xl={18} xxl={18}>
					{isMobileOnly && (
						<img src="/images/documents/mascotaMobile.png" style={{ height: 65 }} alt="mascota" />
					)}
					{!isMobileOnly && (
						<div
							onClick={() => setModalStage(!modalStage)}
							style={{
								position: 'absolute',
								top: -50,
								right: 15,
								background: '#ECFCF8',
								borderTopLeftRadius: '8px',
								borderTopRightRadius: '8px',
								height: 32,
								padding: '0px 16px',
								textAlign: 'center',
								cursor: 'pointer',
								zIndex: 1000
							}}>
							<div style={{ marginTop: 4 }}>
								<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
									{t('b2bOperationList.stage') + ': '}
								</Text>
								<Text style={{ fontSize: 12, fontWeight: 500, color: '#2F4858' }}>
									{showAppraisal
										? t(`stages.${'appraisal'}`)
										: stages === 'banks' || stages === 'oferts'
										? t('step.ofertsCustomer')
										: stages === 'analysis'
										? t('stages.documents')
										: t(`stages.${stages}`)}
								</Text>
								<InfoCircleOutlined
									style={{ color: '#05C69A', fontSize: 12, marginLeft: 5 }}></InfoCircleOutlined>
							</div>
						</div>
					)}
					{isMobileOnly && (
						<div
							style={{
								position: 'absolute',
								top: 25,
								right: 15,
								background: '#ECFCF8',
								borderBottomLeftRadius: '8px',
								borderTopRightRadius: '8px',
								height: 32,
								padding: '0px 16px',
								textAlign: 'center'
							}}>
							<div style={{ marginTop: 4 }}>
								<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
									{t('b2bOperationList.stage') + ': '}
								</Text>
								<Text style={{ fontSize: 12, fontWeight: 500, color: '#2F4858' }}>
									{showAppraisal
										? t(`stages.${'appraisal'}`)
										: stages === 'banks' || stages === 'oferts'
										? t('step.ofertsCustomer')
										: stages === 'analysis'
										? t('stages.documents')
										: t(`stages.${stages}`)}
								</Text>
								<InfoCircleOutlined
									style={{ color: '#05C69A', fontSize: 12, marginLeft: 5, cursor: 'pointer' }}
									onClick={() => setModalStage(!modalStage)}></InfoCircleOutlined>
							</div>
						</div>
					)}
					<Card className="gb-card-0px" style={{ marginTop: isMobileOnly ? -10 : -20 }}>
						<Row
							style={{
								marginBottom: isMobileOnly ? undefined : -20,
								marginTop: isMobileOnly ? undefined : -20
							}}>
							{!isMobileOnly && (
								<Col span={3} style={{ minWidth: 110 }}>
									<div
										style={{
											position: 'absolute',
											bottom: 20,
											left: 16,
											width: '100%',
											height: '100%',
											maxHeight: 96,
											backgroundImage: 'url(/images/documents/bob.png)',
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'contain',
											backgroundPosition: 'center bottom'
										}}></div>
								</Col>
							)}
							<Col span={isMobileOnly ? 24 : 13} style={{ padding: isMobileOnly ? 16 : 24 }}>
								<div style={{ marginTop: 8 }}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: isMobileOnly ? 16 : 20,
											fontWeight: 600
										}}>
										{renderTitleByStages()}
									</Text>
								</div>
								<div>
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
										{renderTextByStages() + ' '}
										{stages === 'documents' && (
											<a
												href={'https://www.gibobs.com/seguridad/'}
												target={'_blank'}
												style={{
													textDecorationLine: 'underline',
													cursor: 'pointer',
													color: '#748EA0',
													fontSize: 14,
													fontWeight: 400
												}}>
												{t('name.docsNew3')}
											</a>
										)}
									</Text>
								</div>
							</Col>
							{!isMobileOnly && (
								<div
									style={{
										background: 'var(--Neutral-L2, #C0DAEE)',
										width: 1,
										height: 60,
										marginTop: 45
									}}></div>
							)}
							{isMobileOnly && (
								<div
									style={{
										background: 'var(--Neutral-L2, #C0DAEE)',
										width: '90%',
										height: 1,
										marginLeft: 16
									}}></div>
							)}
							<Col
								span={isMobileOnly ? 24 : 7}
								style={{ padding: isMobileOnly ? 16 : 24, marginTop: isMobileOnly ? -14 : 10 }}>
								<div style={{ marginTop: 8 }}>
									<Text
										style={{
											color: '#2F4858',
											fontSize: isMobileOnly ? 16 : 14,
											fontWeight: 600
										}}>
										{mortgage?.subtype === 'mortgage'
											? t('resume.docsTitle')
											: t('doc.summaryData')}
									</Text>
								</div>

								<Row style={{ marginTop: 5 }}>
									<Col
										span={isMobileOnly ? 8 : 10}
										style={{ display: 'flex', flexDirection: 'column' }}>
										<Text style={{ color: '#748EA0', fontSize: 10, fontWeight: 400 }}>
											{mortgage?.subtype === 'mortgage'
												? t('yourHouse.doc')
												: t('form.pendingCapital')}
										</Text>
										<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
											{mortgage?.subtype === 'mortgage'
												? numeral(mortgage?.operationData?.mortgage?.maxBudget).format('0,0 $')
												: numeral(mortgage?.operationData?.subrogation?.maxBudget).format('0,0 $')}
										</Text>
									</Col>
									<Col
										span={isMobileOnly ? 10 : 12}
										style={{ display: 'flex', flexDirection: 'column' }}>
										<Text style={{ color: '#748EA0', fontSize: 10, fontWeight: 400 }}>
											{mortgage?.subtype === 'mortgage'
												? t('amountHouse.doc')
												: t('form.currentInterest')}
										</Text>
										{mortgage?.subtype === 'mortgage' && (
											<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
												{numeral(
													mortgage?.operationData?.mortgage?.viabilityData?.operation
														?.mortgageAmount === 0
														? Number(
																mortgage?.operationData?.mortgage?.viabilityData?.operation?.taxes +
																	mortgage?.operationData?.mortgage?.maxBudget -
																	mortgage?.operationData?.mortgage?.viabilityData?.operation
																		?.savings
														  ) + 2000
														: mortgage?.operationData?.mortgage?.viabilityData?.operation
																?.mortgageAmount
												).format('0,0.00 $')}
											</Text>
										)}
										{mortgage?.subtype === 'subrogation' &&
											mortgage?.operationData?.subrogation?.interest &&
											mortgage?.operationData?.subrogation?.haveATin && (
												<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
													{mortgage?.operationData?.subrogation?.interest === 'fixed'
														? t('form.fixed')
														: mortgage?.operationData?.subrogation?.interest === 'variable'
														? t('form.variable')
														: t('loan.mixedOnboarding')}
													{mortgage?.operationData?.subrogation?.interest === 'fixed'
														? ` (${mortgage?.operationData?.subrogation?.haveATin
																.toString()
																.replace('.', ',')}%)`
														: mortgage?.operationData?.subrogation?.interest === 'variable'
														? ` (Eur + ${mortgage?.operationData?.subrogation?.haveATin
																.toString()
																.replace('.', ',')}%)`
														: ` (${mortgage?.operationData?.subrogation?.initialInterestVariable
																.toString()
																.replace(
																	'.',
																	','
																)}% / Eur + ${mortgage?.operationData?.subrogation?.finalInterestVariable
																.toString()
																.replace('.', ',')}%) `}
												</Text>
											)}
									</Col>
									{isMobileOnly && (
										<Col span={6} style={{ display: 'flex', flexDirection: 'column' }}>
											<div
												onClick={() => setDataResume(true)}
												style={{
													color: '#02C3CD',
													fontSize: 14,
													fontWeight: 500,
													textAlign: 'right'
												}}>
												{t('promoterLoan.seeMore')}
											</div>
										</Col>
									)}
								</Row>

								{!isMobileOnly && (
									<div
										onClick={() => setDataResume(true)}
										style={{
											color: '#02C3CD',
											fontSize: 14,
											fontWeight: 500,
											position: 'relative',
											bottom: 20,
											left: 40,
											textAlign: 'right',
											cursor: 'pointer'
										}}>
										{t('promoterLoan.seeMore')}
									</div>
								)}
							</Col>
						</Row>
					</Card>
					<DocumentationAccordions
						userMortgageId={userMortgageId}
						stages={stages}
						showAppraisal={showAppraisal}
						setShowAppraisal={setShowAppraisal}
					/>
				</Col>

				<Col lg={6} md={6} sm={6} xs={24} xl={6} xxl={6} style={{ marginTop: -20 }}>
					{!isMobileOnly && (
						<>
							<Card className="gb-card-16px">
								<PersonalAnalyst
									analyst={
										nameOperations !== undefined ? nameOperations : valuesDataAgent
									}></PersonalAnalyst>
							</Card>

							<Card className="gb-card-16px" style={{ marginTop: 16, textAlign: 'center' }}>
								<Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
									{t('advantages.gibobs')}
								</Text>
								<Divider style={{ marginTop: 8, marginBottom: 8 }}></Divider>
								<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
									{t('advantages.gibobs2')}
								</Text>{' '}
								<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
									{t('advantages.gibobs3')}
								</Text>
								<div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
									<img
										src="/images/documents/startup.png"
										style={{ width: 100, height: 'auto' }}
										alt="startup"
									/>
								</div>
							</Card>
						</>
					)}
				</Col>
			</Row>

			{stages !== 'documents' && (
				<Row
					style={{ width: '100%', marginTop: 24, marginBottom: 16 }}
					gutter={isMobileOnly ? undefined : 18}>
					{stages === 'banks' && allDenied && (
						<Col
							lg={12}
							md={24}
							sm={24}
							xs={24}
							xl={12}
							xxl={12}
							style={{ marginTop: isMobileOnly && 8, marginBottom: isMobileOnly && 16 }}>
							<CardsMkt type="noOffers" />
						</Col>
					)}
					<Col lg={12} md={24} sm={24} xs={24} xl={12} xxl={12} style={{ marginBottom: 5 }}>
						{stages === 'analysis' && <CardsMkt type="mortgageProgram" />}
						{stages === 'banks' && allDenied === false && <CardsMkt type="quoteCalculator" />}
						{stages === 'banks' && allDenied && <CardsMkt type="quoteCalculatorAllDenied" />}
						{stages === 'oferts' && <CardsMkt type="chooseMortgage" />}
						{(stages === 'appraisal' || stages === 'formalization') && (
							<CardsMkt type="beforeSign" />
						)}
					</Col>
					{allDenied === false && (
						<Col
							lg={12}
							md={24}
							sm={24}
							xs={24}
							xl={12}
							xxl={12}
							style={{ marginTop: isMobileOnly && 8 }}>
							<CardsMkt type="amigobsPlain" />
						</Col>
					)}
				</Row>
			)}

			<Row style={{ display: 'flex', justifyContent: 'center' }}>
				{isMobileOnly ? (
					<>
						<div style={{ marginTop: 16, textAlign: 'center' }}>
							<div>
								<img src={security} alt="security icon" width={18} />
								<Text style={{ fontSize: 10, fontWeight: 400, color: '#748EA0', marginLeft: 8 }}>
									{t('doc.ourSecureInformation').replace(/:$/, '.')}
								</Text>
							</div>
							<div style={{ display: 'flex', marginTop: 8, justifyContent: 'center' }}>
								<div style={{ marginLeft: 24, display: 'flex', alignItems: 'center' }}>
									<img src={logoSpain} alt="logo spain" style={{ height: 22, width: 'auto' }} />
									<Text style={{ fontSize: 7, fontWeight: 400, color: '#748EA0', marginLeft: 6 }}>
										N° de registro:
										<br />
										911/2019 S.2
									</Text>
								</div>
								<div style={{ marginLeft: 24, display: 'flex', alignItems: 'center' }}>
									<img
										src={logoSpainBank}
										alt="logo spain bank"
										style={{ height: 18, width: 'auto' }}
									/>
									<Text style={{ fontSize: 7, fontWeight: 400, color: '#748EA0', marginLeft: 6 }}>
										Certificada como Intermediaria
										<br />
										de Crédito Inmobiliario nº: D145
									</Text>
								</div>
							</div>
						</div>
					</>
				) : (
					<div style={{ marginTop: 32, display: 'flex', alignItems: 'center' }}>
						<div>
							<img src={security} alt="security icon" width={24} />
							<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0', marginLeft: 8 }}>
								{t('doc.ourSecureInformation').replace(/:$/, '.')}
							</Text>
						</div>
						<div style={{ marginLeft: 24, display: 'flex', alignItems: 'center' }}>
							<img src={logoSpain} alt="logo spain" style={{ height: 32, width: 'auto' }} />
							<Text style={{ fontSize: 8, fontWeight: 400, color: '#748EA0', marginLeft: 6 }}>
								N° de registro:
								<br />
								911/2019 S.2
							</Text>
						</div>
						<div style={{ marginLeft: 32, display: 'flex', alignItems: 'center' }}>
							<img
								src={logoSpainBank}
								alt="logo spain bank"
								style={{ height: 25, width: 'auto' }}
							/>
							<Text style={{ fontSize: 8, fontWeight: 400, color: '#748EA0', marginLeft: 6 }}>
								Certificada como Intermediaria
								<br />
								de Crédito Inmobiliario nº: D145
							</Text>
						</div>
					</div>
				)}
			</Row>

			{isMobileOnly && (
				<AnalystInformation
					name={undefined}
					phone={undefined}
					email={undefined}
					image={undefined}
					dataValues={analystData}
					valuesDataAgent={valuesDataAgent}
				/>
			)}

			<Modal
				title={t('document.modatTitlteSteper')}
				className="ModalCommon"
				width={480}
				cancelButtonProps={{
					style: { display: 'none' }
				}}
				okButtonProps={{
					style: { display: 'none' }
				}}
				open={modalStage}
				onCancel={() => setModalStage(!modalStage)}
				destroyOnClose={true}
				closable={true}>
				<div style={{ marginTop: -35, marginBottom: -30 }}>
					<StagesMortgages data={{ data: { stage: stages } }} />
				</div>
			</Modal>

			<Modal
				title={t('title.resumeDoc')}
				className={isMobileOnly ? 'ModalCommonSmall' : 'ModalCommon'}
				width={isMobileOnly ? 378 : 581}
				cancelButtonProps={{
					style: { display: 'none' }
				}}
				okButtonProps={{
					style: { display: 'none' }
				}}
				open={dataResume}
				onCancel={() => setDataResume(false)}
				destroyOnClose={true}
				closable={true}>
				<div style={{ marginTop: -35, marginBottom: -30 }}>
					<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
						{t('title.resumeDoc1')}
					</Text>
				</div>
				{mortgage?.subtype === 'subrogation' ? (
					<>
						<Row style={{ marginTop: 52 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('form.locationHouse')}:
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 9 }}>
								{mortgage?.operationData?.subrogation?.province}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('form.houseCost')}
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 7 }}>
								{numeral(mortgage?.operationData?.subrogation?.maxBudget).format('0,0 $')}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('form.pendingCapital')}:
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 7 }}>
								{numeral(mortgage?.operationData?.subrogation?.amount).format('0,0 $')}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('form.dateInitial')}:
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 9 }}>
								{moment(mortgage?.operationData?.subrogation?.dateSign).format('MM-YYYY')}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('form.endDate')}:
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 9 }}>
								{moment(mortgage?.operationData?.subrogation?.dateEndMortgage).format('MM-YYYY')}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('loan.entityBank')}:
							</Text>
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 600,
									marginLeft: 9,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									maxWidth: 'calc(100% - 150px)'
								}}>
								{
									nameBanks.find(
										(bank) => bank.id === mortgage?.operationData?.subrogation?.mortgageCurrentBank
									)?.name
								}
							</Text>
						</Row>
						{mortgage?.operationData?.subrogation?.initialInterestVariable &&
							mortgage?.operationData?.subrogation?.interest &&
							mortgage?.operationData?.subrogation?.haveATin &&
							mortgage?.operationData?.subrogation?.finalInterestVariable && (
								<Row style={{ marginTop: 8 }}>
									<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
										{t('form.currentInterest')}:&nbsp;
									</Text>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{mortgage?.operationData?.subrogation?.interest === 'fixed'
											? t('form.fixed')
											: mortgage?.operationData?.subrogation?.interest === 'variable'
											? t('form.variable')
											: t('loan.mixedOnboarding')}
										{mortgage?.operationData?.subrogation?.interest === 'fixed'
											? ` (${mortgage?.operationData?.subrogation?.haveATin
													.toString()
													.replace('.', ',')}%)`
											: mortgage?.operationData?.subrogation?.interest === 'variable'
											? ` (Eur + ${mortgage?.operationData?.subrogation?.haveATin
													.toString()
													.replace('.', ',')}%)`
											: ` (${mortgage?.operationData?.subrogation?.initialInterestVariable
													.toString()
													.replace('.', ',')}% 
											/ Eur + ${mortgage?.operationData?.subrogation?.finalInterestVariable
												.toString()
												.replace('.', ',')}%) `}
									</Text>
								</Row>
							)}
					</>
				) : (
					<>
						<Row style={{ marginTop: 52 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('viability.provinceViability')}:
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 9 }}>
								{mortgage?.operationData?.mortgage?.province}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('property.used')}
							</Text>
							{!isMobileOnly && (
								<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 37 }}>
									{mortgage?.operationData?.mortgage?.propertyType === 'newConstruction'
										? t('form.newBuild')
										: t('form.secondHand')}
									,
									{mortgage?.operationData?.mortgage?.houseType === 'main'
										? t('form.main')
										: t('form.second')}
								</Text>
							)}
							{isMobileOnly && (
								<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 32 }}>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{mortgage?.operationData?.mortgage?.propertyType === 'newConstruction'
											? t('form.newBuild')
											: t('form.secondHand')}
										,
									</Text>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{mortgage?.operationData?.mortgage?.houseType === 'main'
											? t('form.main')
											: t('form.second')}
									</Text>
								</div>
							)}
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('budget.price')}:
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 7 }}>
								{numeral(mortgage?.operationData?.mortgage?.maxBudget).format('0,0 $')}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('form.Savings')}:
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 9 }}>
								{numeral(mortgage?.operationData?.mortgage?.savings).format('0,0 $')}
							</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{t('amount.need')}
							</Text>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600, marginLeft: 13 }}>
								{numeral(
									mortgage?.operationData?.mortgage?.viabilityData?.operation?.mortgageAmount === 0
										? Number(
												mortgage?.operationData?.mortgage?.viabilityData?.operation?.taxes +
													mortgage?.operationData?.mortgage?.maxBudget -
													mortgage?.operationData?.mortgage?.viabilityData?.operation?.savings
										  ) + 2000
										: mortgage?.operationData?.mortgage?.viabilityData?.operation?.mortgageAmount
								).format('0,0 $')}
							</Text>
						</Row>
					</>
				)}

				<div style={{ marginTop: 32 }}>
					{owners &&
						owners.map((owner, index) => {
							return (
								<>
									<Row>
										<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
											{t(`form.owner${index + 1}`) + ':'}
										</Text>
										<Text
											style={{ color: '#748EA0', fontSize: 14, fontWeight: 600, marginLeft: 6 }}>
											{owner.name}
											{owner.age ? ', ' : ''}
										</Text>{' '}
										&nbsp;
										{owner.age && (
											<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
												{owner.age} {t('viability.years')}
											</Text>
										)}
									</Row>
									<Divider style={{ marginTop: 0, color: '#C0DAEE', background: '#C0DAEE' }} />
									<Row style={{ marginTop: -20, marginBottom: 32 }}>
										{owner.activity && (
											<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
												{renderJob(owner.activity)}
											</Text>
										)}
										{mortgage?.subtype !== 'subrogation' && (
											<>
												{owner.seniority !== undefined && owner.seniority > 0 && (
													<Text
														style={{
															color: '#2F4858',
															fontSize: 14,
															fontWeight: 600,
															marginLeft: 6
														}}>
														{' / ' + owner.seniority + ' ' + t('viability.years') + ' / '}&nbsp;
													</Text>
												)}
												{owner.netIncomes !== undefined && owner.netIncomes > 0 && (
													<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
														{numeral(owner.netIncomes).format('0,0 $') +
															' ' +
															t('viability.monthsEstimated')}
													</Text>
												)}
											</>
										)}
									</Row>
									{mortgage?.subtype !== 'subrogation' && (
										<Row style={{ marginTop: -30, marginBottom: 32 }}>
											<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
												{t('others.loansDocs')}
											</Text>
											<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
												{': ' + numeral(owner.pensionPayment).format('0,0 $')}
											</Text>
										</Row>
									)}
								</>
							);
						})}
				</div>

				{!isMobileOnly && (
					<Row>
						<DownloadOutlined style={{ fontSize: 20, fontWeight: 600, color: '#0088C6' }} />
						<Text
							onClick={() => renderClick()}
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#0088C6',
								textDecorationLine: 'underline',
								marginLeft: 4,
								cursor: 'pointer'
							}}>
							{t('download.viabilityDocs')}
						</Text>
						<img
							src="/images/documents/calculator.png"
							alt="calcu"
							width={20}
							height={20}
							style={{ marginLeft: 14 }}></img>
						<a
							href={'/calculators/fee'}
							target={'_blank'}
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#0088C6',
								textDecorationLine: 'underline',
								marginLeft: 4,
								cursor: 'pointer'
							}}>
							{t('calculator.viabilityDocs')}
						</a>
					</Row>
				)}

				{isMobileOnly && (
					<div style={{ textAlign: 'center' }}>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<DownloadOutlined style={{ fontSize: 20, fontWeight: 600, color: '#0088C6' }} />
							<Text
								onClick={() => renderClick()}
								style={{
									fontSize: 14,
									fontWeight: 600,
									color: '#0088C6',
									textDecorationLine: 'underline',
									marginLeft: 4,
									cursor: 'pointer'
								}}>
								{t('download.viabilityDocs')}
							</Text>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 10
							}}>
							<img
								src="/images/documents/calculator.png"
								alt="calcu"
								width={20}
								height={20}
								style={{ marginLeft: 14 }}></img>
							<a
								href={'/calculators/fee'}
								target={'_blank'}
								style={{
									fontSize: 14,
									fontWeight: 600,
									color: '#0088C6',
									textDecorationLine: 'underline',
									marginLeft: 4,
									cursor: 'pointer'
								}}>
								{t('calculator.viabilityDocs')}
							</a>
						</div>
					</div>
				)}
			</Modal>

			{stages === 'documents' && mortgage && (
				<AutomaticDocumentationStartupModal
					userMortgageId={userMortgageId}
					clickTab={'owner1'}
					mortgage={mortgage}
					env={env}
					platform="gibobs"
					traceSadabdell={undefined}
				/>
			)}
		</div>
	);
};
export default DocumentatitonCore;
