import React from 'react';
import { Row, Col, Typography, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

const MortgageAmountModal = ({ visible, onCancel, result, tmp }) => {
	const { t } = useTranslation();

	const renderNumeral = (result) => {
		const amount = result.simulationData.resultQuoteMortage.operation.property;
		const impuestos = result.simulationData.resultQuoteMortage.operation.taxes + 2000;
		const intereses =
			result?.simulationData?.resultQuoteMortage?.operation.types.fixed.quote *
				result?.simulationData?.resultQuoteMortage.operation.quotes -
			result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount;
		const total = amount + impuestos + intereses;
		return numeral(total).format('0,0 $');
	};

	if (tmp === 'pay') {
		return (
			<Modal
				className="ModalMobileFooterColum"
				okButtonProps={{ style: { display: 'none' } }}
				open={visible}
				title={<Text>{t('calculator.modal4')}</Text>}
				width={480}
				cancelButtonProps={{ style: { display: 'none' } }}
				closable={true}
				onCancel={() => onCancel(false)}>
				<Row style={{ marginTop: 36 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('onboarding.priceOfHouse')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#02C3CD',
								fontSize: 14,
								fontWeight: 400
							}}>
							{result && result.simulationData !== undefined
								? numeral(result.simulationData.resultQuoteMortage.operation.property).format(
										'0,0 $'
								  )
								: '180.000 €'}
						</Text>
					</Col>
				</Row>

				<Row style={{ marginTop: 8 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('viability.taxes')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#FF4D4F',
								fontSize: 14,
								fontWeight: 400
							}}>
							{result && result.simulationData !== undefined
								? '+' +
								  numeral(result.simulationData.resultQuoteMortage.operation.taxes).format('0,0 $')
								: '+18.000 €'}
						</Text>
					</Col>
				</Row>

				<Row style={{ marginTop: 8 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('calculator.modal3')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#FF4D4F',
								fontSize: 14,
								fontWeight: 400
							}}>
							{'+2.000 €'}
						</Text>
					</Col>
				</Row>

				<Row style={{ marginTop: 8 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('calculator.modal4Interest')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#FF4D4F',
								fontSize: 14,
								fontWeight: 400
							}}>
							{'+'}
							{numeral(
								result?.simulationData?.resultQuoteMortage?.operation.types.fixed.quote *
									result?.simulationData?.resultQuoteMortage.operation.quotes -
									result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount
							).format('0,0 $')}
						</Text>
					</Col>
				</Row>

				<div style={{ borderTop: '1px solid black', height: 2, marginTop: 10 }}></div>

				<Row style={{ marginTop: 16, marginBottom: 16 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 600
							}}>
							{t('calculator.modal4InterestPay')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#02C3CD',
								fontSize: 14,
								fontWeight: 600
							}}>
							{result && result.simulationData !== undefined ? renderNumeral(result) : '230.000 €'}
						</Text>
					</Col>
				</Row>
			</Modal>
		);
	} else {
		return (
			<Modal
				className="ModalMobileFooterColum"
				okButtonProps={{ style: { display: 'none' } }}
				open={visible}
				title={<Text>{t('onboarding.amountCalculation')}</Text>}
				width={480}
				cancelButtonProps={{ style: { display: 'none' } }}
				closable={true}
				onCancel={onCancel}
				centered>
				<Row style={{ marginTop: 40 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('onboarding.priceOfHouse')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#02C3CD',
								fontSize: 14,
								fontWeight: 400
							}}>
							{result && result?.simulationData !== undefined
								? numeral(result?.simulationData?.resultQuoteMortage?.operation?.property).format(
										'0,0 $'
								  )
								: '180.000 €'}
						</Text>
					</Col>
				</Row>

				<Row style={{ marginTop: 8 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('viability.taxes')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#FF4D4F',
								fontSize: 14,
								fontWeight: 400
							}}>
							{result && result?.simulationData !== undefined
								? '+' +
								  numeral(result?.simulationData?.resultQuoteMortage?.operation?.taxes).format(
										'0,0 $'
								  )
								: '+18.000 €'}
						</Text>
					</Col>
				</Row>

				<Row style={{ marginTop: 8 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('calculator.modal3')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#FF4D4F',
								fontSize: 14,
								fontWeight: 400
							}}>
							{t('+2.000 €')}
						</Text>
					</Col>
				</Row>

				<Row style={{ marginTop: 8 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 400
							}}>
							{t('certificate.moneyOfAllOwner')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#52C41A',
								fontSize: 14,
								fontWeight: 400
							}}>
							{result && result?.simulationData !== undefined
								? '-' +
								  numeral(
										Math.abs(result?.simulationData?.resultQuoteMortage?.operation.savings)
								  ).format('0,0 $')
								: '-2.000 €'}
						</Text>
					</Col>
				</Row>

				<div style={{ borderTop: '1px solid black', height: 2, marginTop: 10 }}></div>

				<Row style={{ marginTop: 8 }}>
					<Col span={16}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 600
							}}>
							{t('onboarding.amountCalculation')}
						</Text>
					</Col>

					<Col span={8} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#02C3CD',
								fontSize: 14,
								fontWeight: 600
							}}>
							{result && result?.simulationData !== undefined
								? numeral(
										Math.abs(
											result?.simulationData?.resultQuoteMortage?.operation.mortgageAmount + 2000
										)
								  ).format('0,0 $')
								: '120.000 €'}
						</Text>
					</Col>
				</Row>

				<Row style={{ marginTop: 30, marginBottom: isMobileOnly ? -20 : 16 }}>
					<Col span={20}>
						<Text
							style={{
								color: '#2F4858',
								fontSize: 12,
								fontWeight: 600
							}}>
							{t('onboarding.houseAmountPercentaje')}
						</Text>
					</Col>

					<Col span={4} style={{ textAlign: 'right' }}>
						<Text
							style={{
								color: '#02C3CD',
								fontSize: 14,
								fontWeight: 600
							}}>
							{result && result?.simulationData !== undefined
								? (
										(result?.simulationData?.resultQuoteMortage?.operation?.mortgageAmount * 100) /
										result?.simulationData?.resultQuoteMortage?.operation?.property
								  ).toFixed('1') + '%'
								: '72 %'}
						</Text>
					</Col>
				</Row>
			</Modal>
		);
	}
};

export default MortgageAmountModal;
