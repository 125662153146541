import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Avatar, Dropdown, Tooltip, Layout, Menu, Typography, message, Col, Row } from 'antd';
import {
	CaretDownOutlined,
	UserOutlined,
	LogoutOutlined,
	RetweetOutlined,
	FlagOutlined,
	BugOutlined
} from '@ant-design/icons';
import Spain from '../../assets/Banderas/Spain.svg';
import Cataluna from '../../assets/Banderas/Cataluna.svg';
import EstadosUnidos from '../../assets/Banderas/EstadosUnidos.png';
import Gibobs from '../../assets/icons/Gibobs.svg';
import Info from '../../assets/icons/Info.png';
import Contacto from '../../assets/icons/Contacto.png';
import ContactoPage from '../../Pages/ContactoPage';
import axios from 'axios';
import { useTranslation, withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import env from '../../environment';
import { useSelector, useDispatch } from 'react-redux';
import { callAct, changeRol } from '../../store/auth/actions';
import { logout } from '../../store/auth/actions';
import { getAnalytics, logEvent } from 'firebase/analytics';
import user from '../../assets/icons/user.png';
import topStartups from '../../assets/onboarding/bandera_top_startups.png';

const { Header } = Layout;
const { Text } = Typography;

function HeaderComponent() {
	const dispatch = useDispatch();
	const [taskToEdit, setTaskToEdit] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [dataPersonal, setDataPersonal] = useState(null);
	const changeCallAct = useSelector((state) => state.auth.callAct);
	const rolUser = useSelector((state) => state.auth.roles);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const allRoles = dataProfile?.roles?.length > 0 ? dataProfile.roles : 'client';
	let temp =
		allRoles && Array.isArray(allRoles)
			? allRoles
			: allRoles && allRoles.length === 1 && allRoles[0] === 'realestate'
			? allRoles
			: allRoles && allRoles.split(',');
	let arrayRoles = temp;

	const userToken = useSelector((state) => state.auth.token);
	const { t } = useTranslation();
	const analytics = getAnalytics();

	const hideAddTaskModal = () => {
		setShowModal(false);
		setTaskToEdit(null);
	};

	useEffect(() => {
		dataPersonalOperation();
	}, [changeCallAct]);

	useEffect(() => {
		if (rolUser === 'realestate' && arrayRoles[0] === 'hadmin') {
			dispatch(changeRol('realestate'));
			//allRoles[0] = "realestate"
			localStorage.setItem('allRoles', ['realestate', 'client']);
		}
		dataPersonalOperation();
	}, []);

	const dataPersonalOperation = () => {
		axios
			.get(`${env.api.url}/v1/user/profile`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDataPersonal(response.data.data.profile);
				dispatch(callAct(false));
			});
	};

	const enviarNuevoMensaje = (values) => {
		axios
			.post(`${env.api.url}/v1/user/contact-email`, values, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				message.success('Tu mensaje fue enviado');
			});
		setShowModal(false);
	};

	const changeLanguage = (lng) => {
		axios.patch(
			`${env.api.url}/v1/user/update-user-language`,
			{
				language: lng
			},
			{
				headers: { Authorization: `Bearer ${userToken}` }
			}
		);
		i18n.changeLanguage(lng);
	};

	const subMenu1 = (
		<Menu>
			<Menu.Item onClick={() => changeLanguage('es')}>
				<img src={Spain} alt="SpainFlag" height="15px" style={{ marginRight: '5px' }} />
				{t('form.es')}
			</Menu.Item>

			<Menu.Item onClick={() => changeLanguage('en')}>
				<img src={EstadosUnidos} alt="EUFlag" height="15px" style={{ marginRight: '5px' }} />
				{t('form.en')}
			</Menu.Item>
			<Menu.Item onClick={() => changeLanguage('cat')}>
				<img src={Cataluna} alt="CataloniaFlag" height="15px" style={{ marginRight: '5px' }} />
				{t('form.cat')}
			</Menu.Item>
		</Menu>
	);

	const logoutUser = () => {
		logEvent(analytics, 'select_content', {
			content_type: 'configuration.list',
			content_id: 'user.logout'
		});
		dispatch(logout({ history }));
	};

	const createAccountFriend = () => {
		dispatch(changeRol('friend'));
	};

	const createAccountParticular = () => {
		if (allRoles !== undefined && Array.isArray(arrayRoles)) {
			let rol = arrayRoles.find((item) => item === 'realestate' || item === 'client');
			if (rol === 'realestate' || rol === 'client') {
				dispatch(changeRol('client'));
			}
		}
		if (allRoles !== undefined && !Array.isArray(arrayRoles)) {
			if (allRoles === 'realestate') {
				dispatch(changeRol('client'));
			}
		}
		if (allRoles !== undefined && Array.isArray(arrayRoles)) {
			if (allRoles === 'client' || allRoles === 'hadmin' || allRoles === 'friend') {
				dispatch(changeRol('client'));
			}
		}
	};

	const createAccountINMO = () => {
		if (allRoles !== undefined && Array.isArray(arrayRoles)) {
			let rol = arrayRoles.find((item) => item === 'client');
			if (rol === 'client') {
				dispatch(changeRol('realestate'));
			}
		}
		if (allRoles !== undefined) {
			if (rolUser === 'client') {
				dispatch(changeRol('realestate'));
			}
		}
		if (allRoles !== undefined) {
			if (rolUser === 'friend') {
				dispatch(changeRol('realestate'));
			}
		}
	};

	const subMenu2 = (
		<Menu>
			{arrayRoles && arrayRoles[0] === 'realestate' && rolUser === 'realestate' && (
				<>
					<Menu.Item onClick={() => createAccountParticular()}>
						<NavLink
							to={{
								pathname: `/dashboard-client`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.particular')}</Text>
						</NavLink>
					</Menu.Item>
					{/*<Menu.Item onClick={() => createAccountFriend()}>
              <NavLink
                to={{
                  pathname: `/`
                }}
              >
                <Text style={{ color: "#02C3CD" }}>{t("header.plain")}</Text>
              </NavLink>

              </Menu.Item>*/}
				</>
			)}

			{arrayRoles && arrayRoles[0] === 'realestate' && rolUser === 'client' && (
				<>
					{/*} <Menu.Item onClick={() => createAccountFriend()}>
            <NavLink
              to={{
                pathname: `/`,
              }}
            >
              <Text style={{ color: "#02C3CD" }}>{t("header.plain")}</Text>
            </NavLink>
            </Menu.Item>*/}
					<Menu.Item onClick={() => createAccountINMO()}>
						<NavLink
							to={{
								pathname: `/dashboard-business`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.company')}</Text>
						</NavLink>
					</Menu.Item>
				</>
			)}
			{arrayRoles && arrayRoles[0] === 'realestate' && rolUser === 'friend' && (
				<>
					<Menu.Item onClick={() => createAccountParticular()}>
						<NavLink
							to={{
								pathname: `/dashboard-client`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.particular')}</Text>
						</NavLink>
					</Menu.Item>
					<Menu.Item onClick={() => createAccountINMO()}>
						<NavLink
							to={{
								pathname: `/dashboard-business`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.company')}</Text>
						</NavLink>
					</Menu.Item>
				</>
			)}

			{Array.isArray(arrayRoles) &&
				(arrayRoles[0] === 'hadmin' || arrayRoles[0] === 'client') &&
				(rolUser === 'hadmin' || rolUser === 'client') && (
					<Menu.Item onClick={() => createAccountFriend()}>
						<NavLink
							to={{
								pathname: `/friend`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.plain')}</Text>
						</NavLink>
					</Menu.Item>
				)}

			{Array.isArray(arrayRoles) &&
				(arrayRoles[0] === 'hadmin' || arrayRoles[0] === 'client') &&
				rolUser === 'friend' && (
					<Menu.Item onClick={() => createAccountParticular()}>
						<NavLink
							to={{
								pathname: `/dashboard-client`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.particular')}</Text>
						</NavLink>
					</Menu.Item>
				)}
		</Menu>
	);

	const menu = (
		<Menu>
			<Menu.Item style={{ display: 'flex' }}>
				<RetweetOutlined style={{ marginTop: '5px' }} />
				<Dropdown overlay={subMenu2} placement="bottomLeft">
					<Text style={{ marginLeft: 12 }}>{t('header.changeProfile')}</Text>
				</Dropdown>
			</Menu.Item>

			<Menu.Item>
				<NavLink
					to={{
						pathname: `/my-account`,
						state: {
							dataPersonal: dataPersonal
						}
					}}>
					<UserOutlined />
					<Text style={{ marginLeft: 12 }}>{t('init.account')}</Text>
				</NavLink>
			</Menu.Item>

			<Menu.Item style={{ display: 'flex' }}>
				<FlagOutlined style={{ marginTop: '5px' }} />
				<Dropdown overlay={subMenu1} placement="bottom">
					<Text style={{ marginLeft: 12 }}>{t('init.languages')}</Text>
				</Dropdown>
			</Menu.Item>

			{arrayRoles &&
				arrayRoles[0] ===
					'hadmin' /*||( dataPersonal && dataPersonal.email === "javier.canadilla@gibobs.com"))*/ && (
					<Menu.Item>
						<NavLink
							to={{
								pathname: `/dev-tools`,
								state: {
									dataPersonal: dataPersonal
								}
							}}>
							<BugOutlined style={{ color: 'red' }} />
							<span style={{ marginLeft: 4 }}>{t('devTools')}</span>
						</NavLink>
					</Menu.Item>
				)}

			<Menu.Item danger onClick={logoutUser}>
				<LogoutOutlined />
				<Text style={{ marginLeft: 12 }}>{t('init.logout')}</Text>
			</Menu.Item>
		</Menu>
	);

	/*
  <Menu.Item style={{ display: "flex" }}>
        <FlagOutlined style={{ marginTop: "5px" }} />
        <Dropdown overlay={subMenu1} placement="bottomCenter">
          <Text>{t("init.languages")}</Text>
        </Dropdown>
      </Menu.Item>*/

	const showSendMessagesModal = () => {
		setShowModal(true);
	};

	return (
		<Row>
			<Col sm={24} xs={24} lg={24} xl={24}>
				<Header
					className="ant-layout-header g-header"
					style={{
						position: 'relative',
						zIndex: 3,
						backgroundColor: '#FFFFFF',
						boxShadow: '0 0 2px #666',
						display: 'flex',
						justifyContent: 'space-between'
					}}>
					<div
						style={{
							marginLeft: '47%',
							position: 'relative',
							marginRight: 'auto'
						}}>
						<img
							src={Gibobs}
							style={{
								marginTop: '3px',
								height: '60px',
								alignSelf: 'center'
							}}
							alt="Gibobs"
						/>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>
						{/* <Input
          // prefix={<SearchOutlined />}
          onSearch={onSearch}
          placeholder="input search text"
          style={{
            height: "32px",
            borderRadius: "4px",
            borderColor: "#c0daee",
            fontSize: "10px",
            width: " 180px",
            marginRight: "24px",
            alignItems: "center",
          }}
        /> */}
						<Tooltip
							placement="bottom"
							title={t('header.help')}
							color={'#2F4858'}
							overlayClassName="toolTipProperty">
							<NavLink to="/help" style={{ marginTop: 15 }}>
								<img src={Info} alt="Info" style={{ height: '24px', marginRight: '4px' }} />
							</NavLink>
						</Tooltip>
						{rolUser !== 'realestate' ? (
							<Tooltip
								placement="bottom"
								title={t('header.contact')}
								color={'#2F4858'}
								overlayClassName="toolTipProperty">
								<img
									src={Contacto}
									alt="Contacto"
									style={{
										marginTop: '1px',
										height: '24px',
										cursor: 'pointer',
										marginRight: rolUser === 'friend' ? 64 : '44px'
									}}
									onClick={showSendMessagesModal}
								/>
							</Tooltip>
						) : null}
						{rolUser === 'realestate' ? (
							<Tooltip
								placement="bottom"
								title="Contacto"
								color={'#2F4858'}
								overlayClassName="toolTipProperty">
								<img
									src={Contacto}
									alt={t('header.contact')}
									style={{
										marginTop: '1px',
										height: '24px',
										marginRight: '24px',
										marginRight: rolUser === 'friend' ? 64 : '44px'
									}}
									onClick={showSendMessagesModal}
								/>
							</Tooltip>
						) : null}
						<ContactoPage
							key={taskToEdit}
							visible={showModal || taskToEdit}
							onCreate={enviarNuevoMensaje}
							onCancel={hideAddTaskModal}
							width={800}
							taskToEdit={taskToEdit}
							dataPersonal={dataPersonal}
						/>

						{rolUser === 'client' && (
							<span>
								<Text
									style={{
										fontWeight: 600,
										color: '#2F4858',
										fontSize: '14px',
										marginRight: -52,
										position: 'relative',
										top: -5
									}}>
									<NavLink
										style={{ color: '#2F4858', fontWeight: 600 }}
										to={{
											pathname: `/my-account`,
											state: {
												dataPersonal: dataPersonal
											}
										}}>
										{dataProfile && dataProfile.name}
									</NavLink>
								</Text>
								<Text
									style={{
										fontWeight: 400,
										marginRight: 10,
										position: 'relative',
										top: 10,
										color: '#02C3CD',
										fontSize: 12
									}}>
									{t('header.particular')}
								</Text>
							</span>
						)}
						{rolUser === 'realestate' && (
							<span>
								<Text
									style={{
										fontWeight: 600,
										marginRight: 10,
										color: '#2F4858',
										fontSize: '14px',
										marginRight: -52,
										position: 'relative',
										top: -5
									}}>
									<NavLink
										style={{ color: '#2F4858', fontWeight: 600 }}
										to={{
											pathname: `/my-account`,
											state: {
												dataPersonal: dataPersonal
											}
										}}>
										{dataProfile && dataProfile.name}
									</NavLink>
								</Text>
								<Text
									style={{
										fontWeight: 400,
										marginRight: 10,
										position: 'relative',
										top: 10,
										color: '#02C3CD',
										fontSize: 12
									}}>
									{t('header.company')}
								</Text>
							</span>
						)}
						{rolUser === 'friend' && (
							<span>
								<Text
									style={{
										fontWeight: 600,
										marginRight: 10,
										color: '#2F4858',
										fontSize: '14px',
										marginRight: -75,
										position: 'relative',
										top: -5
									}}>
									<NavLink
										style={{ color: '#2F4858', fontWeight: 600 }}
										to={{
											pathname: `/my-account`,
											state: {
												dataPersonal: dataPersonal
											}
										}}>
										{dataProfile && dataProfile.name}
									</NavLink>
								</Text>
								<Text
									style={{
										fontWeight: 400,
										marginRight: 10,
										position: 'relative',
										top: 10,
										color: '#02C3CD',
										fontSize: 12
									}}>
									{t('header.plain')}
								</Text>
							</span>
						)}
						{rolUser === 'hadmin' && (
							<span>
								<Text
									style={{
										fontWeight: 600,
										color: '#2F4858',
										fontSize: '14px',
										marginRight: -42,
										position: 'relative',
										top: -5
									}}>
									<NavLink
										style={{ color: '#2F4858', fontWeight: 600 }}
										to={{
											pathname: `/my-account`,
											state: {
												dataPersonal: dataPersonal
											}
										}}>
										{dataProfile && dataProfile.name}
									</NavLink>
								</Text>
								<Text
									style={{
										fontWeight: 400,
										marginRight: 10,
										position: 'relative',
										top: 10,
										color: '#02C3CD',
										fontSize: 12
									}}>
									{t('hadmin')}
								</Text>
							</span>
						)}

						{dataProfile && dataProfile.avatar ? (
							<Tooltip
								placement="bottom"
								title={dataProfile ? dataProfile.name : 'Usuario'}
								overlayClassName="toolTipProperty"
								color={'#2F4858'}>
								<NavLink
									to={{
										pathname: `/my-account`,
										state: {
											dataPersonal: dataPersonal
										}
									}}>
									<Avatar
										size={32}
										src={`data:image/png;base64,${dataProfile && dataProfile.avatar.data}`}
									/>
								</NavLink>
							</Tooltip>
						) : (
							<NavLink
								to={{
									pathname: `/my-account`,
									state: {
										dataPersonal: dataPersonal
									}
								}}>
								<Avatar size={32} src={user} />
							</NavLink>
						)}
						{menu && subMenu1 && subMenu2 && (
							<Dropdown overlay={menu} placement="bottomRight" arrow>
								<CaretDownOutlined
									style={{
										color: '#748EA0',
										marginRight: '20px',
										marginLeft: '7px'
									}}
								/>
							</Dropdown>
						)}
					</div>
				</Header>
				{(location.pathname === '/dashboard-client' || location.pathname === '/friend') && (
					<img
						src={topStartups}
						alt="Logo top startups"
						style={{
							position: 'absolute',
							top: 64,
							right: 40,
							zIndex: 2,
							width: 'auto',
							height: 100
						}}
					/>
				)}
			</Col>
		</Row>
	);
}

export default withTranslation()(HeaderComponent);
