import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin, Button } from 'antd';
import WelcomeSelection from './welcomeSelection';
import DifferentSteps from '../Components/DifferentSteps';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

const BasicStage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const [pages, setPages] = useState(0);
	const [type, setType] = useState('');
	const [loading, setLoading] = useState(false);
	const [dataOperation, setDataOperation] = useState(undefined);
	const [basicDeclarativesCompleted, setBasicDeclarativesCompleted] = useState(undefined);
	const [operationTypeToBeDefined, setOperationTypeToBeDefined] = useState(undefined);
	const userToken = useSelector((state) => state.auth.token);
	const { operationId } = useParams();
	const [isModalOpen, setIsModalOpen] = useState(undefined);
	const [showModalWelcome, setShowModalWelcome] = useState(undefined);
	const [dataOperationAll, setDataOperationAll] = useState(undefined);

	useEffect(() => {
		if (sessionStorage.getItem('modalSub') === 'true' && showModalWelcome) {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [showModalWelcome]);

	useEffect(() => {
		if (dataOperation?.process?.showModalWelcome === undefined && dataOperation !== undefined) {
			updateModalInformation();
		}
	}, [dataOperation]);

	const handleOk = () => {
		setIsModalOpen(false);
		sessionStorage.setItem('modalSub', false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		sessionStorage.setItem('modalSub', false);

		navigate('/dashboard-client');
	};

	const updateModalInformation = () => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${operationId}`,
				{
					key: 'showModalWelcome',
					participant: 'process',
					value: true
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
		
	};

	const getOperationData = () => {
		axios
			.get(`${env.api.url}/operations/operation/operationData/${operationId}/client`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					getDetails();

					setDataOperation(response.data.data);
					setShowModalWelcome(response?.data?.data?.process?.showModalWelcome);
					if (
						response.data.data.process &&
						response.data.data.process.basicDeclarativesCompleted !== undefined
					) {
						setBasicDeclarativesCompleted(response.data.data.process.basicDeclarativesCompleted);
					} else {
						setLoading(false);
					}
					setOperationTypeToBeDefined(response.data.data.process.operationTypeToBeDefined);
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getDetails = () => {
		axios
			.get(`${env.api.url}/v1/operations/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setType(response.data.data.subtype);
				setDataOperationAll(response.data.data);
			});
	};

	useEffect(() => {
		if (operationId !== '1') {
			setLoading(true);
			getOperationData();
			getDetails();
		}
	}, [operationId]);

	useEffect(() => {
		if (type && basicDeclarativesCompleted) {
			autoStages(type, basicDeclarativesCompleted);
		}
	}, [basicDeclarativesCompleted, type]);

	const autoStages = (type, flag) => {
		if (flag && type === 'subrogation') {
			setPages(5);
		}
		if (flag && type === 'mortgage') {
			setPages(5);
		}
		setLoading(false);
	};

	

	return (
		<div>
			{!loading && dataOperation !== undefined && type !== '' ? (
				<>
					{operationTypeToBeDefined !== undefined && operationTypeToBeDefined === true ? (
						<WelcomeSelection setPages={(e) => setPages(e)} setType={(e) => setType(e)} />
					) : (
						<DifferentSteps
							stage={pages}
							setPages={(e) => setPages(e)}
							pages={pages}
							type={type}
							refreshData={() => getOperationData()}
							dataOperation={dataOperation}
							dataOperationAll={dataOperationAll}
							setDataOperation={setDataOperation}
							//{...stepsData[type][pages]}
						/>
					)}
				</>
			) : (
				<Spin indicator={antIcon} className="spin" />
			)}
			<Modal
				zIndex={1200}
				title={
					<div style={{ width: isMobileOnly ? 230 : undefined }}>
						{t('onboarding.homePopupTitle')}
					</div>
				}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				centered
				className="modal-onboarding-stepper"
				width={isMobileOnly ? 324 : 480}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { display: 'none' }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { display: 'none' }
				}}>
				<Button
					type="primary"
					className="button-primari-gibobs"
					style={{ width: '100%', marginTop: 32 }}
					onClick={() => handleOk()}>
					{t('onboarding.homePopupOk')}
				</Button>
			</Modal>
		</div>
	);
};

export default BasicStage;
