import React, { useState, useEffect } from 'react';
import {
	Layout,
	Card,
	Typography,
	Button,
	Col,
	Row,
	Tabs,
	Badge,
	Upload,
	message,
	Modal,
	Form,
	Input,
	Select,
	Alert,
	Switch,
	Radio,
} from 'antd';
import {
	CheckOutlined,
	PlusOutlined,
	DeleteOutlined,
	DownloadOutlined,
	ExclamationCircleOutlined,
	ExclamationCircleFilled
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import env from '../../environment';
import AfterBanks from '../../components/AfterBanks/Afterbanks';
import PersonalAnalyst from '../../components/widgets/Different-Widgets/personalAnalyst';
import { saveAs } from 'file-saver';
import loadingLogo from '../../assets/init/spinner.gif';
import countPin from '../../assets/Products/countPin.png';
import LoaderLogo from '../../components/LoaderLogo';
import BreadcrumbsParticular from '../HerramientasVenta/FollowUpOperations/Components/BreadcrumbsParticular';
import DocumentsCertificate from './DocumentsCertifcate';
import StepsNewStyle from '../HerramientasVenta/FollowUpOperations/Components/StepsNewStyle';
import CountDown from 'ant-design-pro/lib/CountDown';
import moment from 'moment';
import { InputDate } from 'afrodita';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

var zip = require('jszip')();

const { Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { confirm } = Modal;

const OperationCertificated = () => {
	const { t } = useTranslation();
	const param = useParams();
	const navigate = useNavigate();;
	const { operationId } = useParams();

	const [nameOperations, setNameOperations] = useState(undefined);

	const [clickTab, setClickTab] = useState('owner1');
	const [valuesDataForCertificate, setValuesDataForCertificate] = useState([]);
	const userToken = useSelector((state) => state.auth.token);
	const [valuesData, setValuesData] = useState([]);
	const [saveFileDataField, setSaveFileDataField] = useState(null);
	const [tags, setTags] = useState([]);
	const [, setOwner] = useState('');

	const activeNbric = false;
	const [visible, setVisible] = useState(activeNbric); // true para activar
	const [visible1, setVisible1] = useState(false);
	const [visible2, setVisible2] = useState(false);
	const [visible3, setVisible3] = useState(false);
	const [visible4, setVisible4] = useState(false);
	const [visible5, setVisible5] = useState(false);
	const [visible6, setVisible6] = useState(false);
	const [visibleModalDownloadOptions, setVisibleModalDownloadOptions] = useState(false);
	const [downloadOptionsRadio, setDownloadOptionsRadio] = useState(undefined);

	const [form] = Form.useForm();
	const [fileGroup, setFileGroup] = useState([]);
	const [loading, setLoading] = useState(false);

	const [indexOwner, setIndexOwner] = useState(undefined);

	const [currentOwner, setCurrentOwner] = useState(undefined);

	const [clickTabDocumentation, setClickTabDocumentation] = useState('1');
	const [documentsByOwner, setDocumentsByOwner] = useState([]);

	const [docAutomatic, setDocAutomatic] = useState(undefined);
	const [detailsCertificate, setDetailsCertificate] = useState(undefined);
	const [account, setAccount] = useState(null);

	const [fileBanks3Moths, setFileBanks3Moths] = useState(undefined); //upload manual
	const [fileBanks3MothsGroups, setFileBanks3MothsGroups] = useState(undefined); //upload manual

	const [fileAfterbanks, setFileAfterbanks] = useState(undefined); //upload automatic
	
	const [alertResent, setAlertResent] = useState(false);
	const [hiddenAutorze, setHiddenAutorze] = useState(false);
	const [, setOwnerRefresh] = useState('');
	const [errorPin, setErrorPin] = useState(false);
	var myVar = undefined;
	const targetTime = new Date().getTime() + 300000;
	const [, setVisibleButtonParticipants] = useState(undefined);
	const [modalUpdateData, setModalUpdateData] = useState(false);

	const [modalBankFlip, setModalBankFlip] = useState(false);
	const [widgetLink, setWidgetLink] = useState(undefined);
	let timerReference = undefined;
	const [completedBankFlip, setCompletedBankFlip] = useState(false);

	const uploadProps = {
		beforeUpload: (file) => {
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	let countShowMessage = 0;

	useEffect(() => {
		if (modalBankFlip && widgetLink) {
			timerReference = setInterval(() => {
				getBankFlipProcess();
			}, 2000);

			if (completedBankFlip) {
				clearInterval(timerReference);
			}
		}
	}, [widgetLink, modalBankFlip]);

	useEffect(() => {
		getGroupsDoc();
		getDocuments();
		if (nameOperations && nameOperations.operationData && nameOperations.stage === 'documents') {
			setClickTab(nameOperations.operationData.certificate.currentOwner);
		}
		getDetailsCertificate('');
		//esto lo hago para siempre sea manual, cuando se habilite nbric hay que quitarlo
		// let owner = "owner" + indexOwner;
		// saveData(owner, "manualDoc", true, false);

		///////////////////////////
	}, []);

	useEffect(() => {
		getGroupsDoc();
		getDocuments();
	}, [clickTab]);

	useEffect(() => {
		if (
			detailsCertificate &&
			detailsCertificate.operationData &&
			detailsCertificate.stage === 'documents'
		) {
			setClickTab(detailsCertificate.operationData.certificate.currentOwner);
		}
	}, [detailsCertificate]);

	useEffect(() => {
		if (visible3) {
			myVar = setInterval(() => {
				getDocuments();
			}, 10000);

			if (fileAfterbanks) {
				myStopFunction();
			}
		}
	}, [visible3]);

	const myStopFunction = () => {
		clearInterval(myVar);
	};

	const getBankFlipProcess = () => {
		axios
			.get(
				`${env.api.url}/docs/bankflip/check-provider-reference/${widgetLink.providerReferences.id}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.data.flags.sessionCompleted) {
					setCompletedBankFlip(true);
					setModalBankFlip(false);
					clearInterval(timerReference);
					window.location.reload();
				}
			})
			.catch((error) => { console.log(error)});
	};

	const getGroupsDoc = () => {
		axios
			.get(`${env.api.url}/v1/documents/request-operation-documentation/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				response.data.data.documents = _.orderBy(
					response.data.data.documents,
					[
						(d) => {
							return d.participants[clickTab];
						},
						(d) => d.description
					],
					['desc', 'asc']
				);

				response.data.data.documents.find((item) => item.name !== 'bankreader');

				setValuesDataForCertificate(response.data.data);
				setFileBanks3MothsGroups(
					response.data.data.documents.find((item) => item.name === 'bankreader')
				);
				// setFileBanks3Moths(response.data.data.documents.find((item) => item.name === "bankreader"))
				setVisibleButtonParticipants(
					response.data.data.documents.find(
						(item) =>
							item.completed[clickTab] === false && item.participants[clickTab] === 'required'
					)
				);
			})
			.catch((error) => { console.log(error)});
	};

	const handleUploadFile = (file) => {
		axios
			.put(
				`${env.api.url}/v1/documents/operation/attachment`,
				{
					operationId: operationId,
					file: file[1].file,
					fileName: file[1].fileName,
					owner: clickTab,
					type: fileGroup.name,
					tags: tags
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				getDocuments();
				getGroupsDoc();
				getDetailsCertificate();
				setLoading(false);
				if (countShowMessage === 0) {
					message.success(t('document.message'));
					countShowMessage = 1;
				}
			})
			.catch((error) => { console.log(error)});
		countShowMessage = 0;
	};

	const saveFileData = (file, name) => {
		setLoading(true);
		const documentFile = [saveFileDataField];
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile);
	};

	const handleClickPlus = (idowner, file) => {
		setFileGroup(file);
		let array = [idowner];
		if (idowner === 'buy') {
			setTags(array);
			setOwner('owner1');
		} else {
			setOwner(idowner);
		}
	};

	const getDocuments = async () => {
		const response = await axios.get(
			`${env.api.url}/v1/documents/get-operation-documents/${operationId}`,
			{
				headers: { Authorization: `Bearer ${userToken}` }
			}
		);

		const unsorted = response.data.data;

		const sorted = unsorted
			.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
			.reverse();

		let temp = undefined;

		if (nameOperations && nameOperations.stage === 'analysis') {
			temp = sorted.filter((item) => item.owner === clickTab);
			setValuesData(temp);
		} else {
			temp = sorted.filter((item) => item.owner === currentOwner && item.type !== 'bankreader');
			setValuesData(temp);
		}

		let documentsTemp = response.data.data.filter(
			(item) => item.type !== 'nif' && item.owner === currentOwner && item.type !== 'bankreader'
		);

		setDocumentsByOwner(documentsTemp);

		let tempFileAfterbanks = [];
		tempFileAfterbanks = response.data.data.filter(
			(item) =>
				item.tags.includes('automatic') && item.type === 'bankreader' && item.owner === currentOwner
		);

		if (tempFileAfterbanks.length === 0) {
			tempFileAfterbanks = undefined;
		}
		setFileAfterbanks(tempFileAfterbanks);

		let tempFileBanks3Moths = [];
		tempFileBanks3Moths = response.data.data.filter(
			(item) => item.tags.length === 0 && item.type === 'bankreader' && item.owner === currentOwner
		);
		if (tempFileBanks3Moths.length === 0) {
			tempFileBanks3Moths = undefined;
		}
		setFileBanks3Moths(tempFileBanks3Moths);

		///////////////////////////////////////////////
		//descargar un zip con todos los documentos

		if (documentsTemp.length > 0) {
			await axios
				.all(
					documentsTemp.map((l) =>
						axios.get(`${env.api.url}/v1/documents/get-document-url/${l.id}`, {
							headers: { Authorization: `Bearer ${userToken}` }
						})
					)
				)
				.then((res) => {
					const merged = [].concat(res);
					setAccount(merged);
				});
		}
	};

	const deleteDocument = (documentId) => {
		axios
			.get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				getDocuments();
				getGroupsDoc();
			})
			.catch((error) => { console.log(error)});
	};

	const modalDocument = (documentId) => {
		confirm({
			className: 'ModalCommonConfirm',
			title: 'Borrar documento',
			icon: null,
			content: '¿Seguro que deseas borrar este documento?',
			okText: 'Borrar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk() {
				deleteDocument(documentId);
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	const handleClickModal1cancel = (value) => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', true, false);
		setVisible1(value);
		setDocAutomatic(true);
	};

	const handleClickVisible6Cancel = () => {
		setVisible6(false);
		setVisible3(false);
	};

	const handleClickConfirmedDoc = () => {
		saveData(currentOwner, 'confirmedDocumentation', true, false, 'analysis');
		setVisible5(false);
	};

	const handleNextParticipant = async () => {
		let temp = indexOwner + 1;
		let owner = 'owner' + temp;

		await dataMultiple(
			currentOwner,
			'confirmedDocumentation',
			true,
			'certificate',
			'currentOwner',
			owner,
			false
		);

		setVisible4(false);
		window.location.reload();
	};

	const handleGetDocOficialZip = () => {
		if (account) {
			axios
				.all(
					account.map((l) =>
						axios.get(l.data.data.url, {
							responseType: 'arraybuffer'
						})
					)
				)
				.then((res) => {
					const docs = res.map((doc, i) => {
						return {
							name: account[i].data.data.name,
							base64: Buffer.from(doc.data, 'binary').toString('base64')
						};
					});
					exportZip(docs);
				});
		}
	};

	const exportZip = (newArray) => {
		newArray.map((archivo) => {
			zip.file(archivo.name, archivo.base64, { base64: true });
		});
		zip.generateAsync({ type: 'blob' }).then(function (content) {
			saveAs(content, `Gibobs-documents-oficials.zip`);
		});
		zip = require('jszip')();
	};

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '100vh',
					backgroundColor: 'white'
				}}>
				<img
					src={loadingLogo}
					alt="asdf"
					style={{ position: 'absolute', top: '50%' }}
					width="100px"
					height="100px"
				/>
			</div>
		);
	}

	const handleFileDownload = (documentId, item) => {
		axios
			.get(`${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					const link = document.createElement('a');
					link.href = response.data.data.url;
					link.setAttribute('download', `${item.name}`);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
				}
			})
			.catch((error) => { console.log(error)});
	};

	const renderDocumentInformation = (index, type) => {
		const data = valuesData.filter((item) => {
			let documentFlag = false;

			if (item.owner === `${type}${index}`) {
				documentFlag = true;
			} else if (type === 'owner' && index === 1 && !item.owner) {
				documentFlag = true;
			}

			return documentFlag;
		});

		const files = data.map((item) => {
			if (item.type !== null && item.page !== null && item.owner) {
				return (
                    //documentacion validada

                    (<Row
						style={{
							display: 'flex',
							marginTop: 22,
							marginLeft: -10,
							width: '100%'
						}}>
                        <Col xs={23}>
							<CheckOutlined style={{ color: '#52C41A' }} />

							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 400,
									marginLeft: 10
								}}>
								{' '}
								{item.name}
							</Text>
						</Col>
                        <Col
							xs={1}
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'right'
							}}>
							<div
								hidden={item.type === 'nif'}
								onClick={() => {
									modalDocument(item.id);
								}}>
								<DeleteOutlined style={{ color: '#FF4D4F' }} />
							</div>
							<div
								onClick={() => handleFileDownload(item.id, item)}
								style={{
									justifyContent: item.type === 'nif' ? 'right' : undefined,
									marginLeft: item.type === 'nif' ? 32 : 18
								}}>
								<DownloadOutlined style={{ color: '#2F4858' }} />
							</div>
						</Col>
                    </Row>)
                );
			}
		});

		const filesDoc =
			valuesDataForCertificate.documents &&
			valuesDataForCertificate.documents.map((item) => {
				if (item.completed[clickTab] === false) {
					if (item.participants[clickTab] === 'required') {
						return (
                            //documentacion requerida

                            (<Row
								style={{
									display: 'flex',
									marginTop: 22,
									marginLeft: -10,
									width: '100%'
								}}>
                                <Col xs={23}>
									<ExclamationCircleFilled style={{ color: '#2F4858' }} />
									<Text
										style={{
											color: '#2F4858',
											fontSize: 14,
											fontWeight: 600,
											marginLeft: 10
										}}>
										{' '}
										{item.title}
									</Text>
								</Col>
                                <Col
									xs={1}
									style={{
										display: 'flex',
										width: '100%',
										justifyContent: 'right'
									}}>
									<div
										className="PlusButton"
										style={{
											width: '14px',
											height: '14px'
										}}>
										<Upload multiple={true} {...uploadProps}>
											<a onClick={() => handleClickPlus(clickTab, item)} style={{ border: 'none' }}>
												<PlusOutlined style={{ color: '#02C3CD', fontSize: 15 }} />
											</a>
										</Upload>
									</div>
								</Col>
                            </Row>)
                        );
					} else if (
						detailsCertificate &&
						detailsCertificate.operationData &&
						item.participants[clickTab] === 'optional'
					) {
						if (item.completed[clickTab] === false) {
							return (
                                //documentacion opcional

                                (<Row
									style={{
										display: 'flex',
										marginTop: 22,
										marginLeft: -10,
										width: '100%'
									}}>
                                    <Col xs={23}>
										<ExclamationCircleOutlined style={{ color: '#2F4858' }} />
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400,
												marginLeft: 10
											}}>
											{' '}
											{item.title}
										</Text>
									</Col>
                                    <Col
										xs={1}
										style={{
											display: 'flex',
											width: '100%',
											justifyContent: 'right'
										}}>
										<div
											className="PlusButton"
											style={{
												width: '14px',
												height: '14px',
												marginLeft: 15
											}}>
											<Upload multiple={true} {...uploadProps}>
												<a
													onClick={() => handleClickPlus(clickTab, item)}
													style={{ border: 'none' }}>
													<PlusOutlined style={{ color: '#02C3CD', fontSize: 15 }} />
												</a>
											</Upload>
										</div>
									</Col>
                                </Row>)
                            );
						}
					}
				}
			});
		return (
			<div style={{ marginLeft: '15px' }}>
				{files}
				{filesDoc}
			</div>
		);
	};

	const renderTabs = (index, type) => {
		let requireFlag = false;

		if (valuesDataForCertificate.documents) {
			valuesDataForCertificate.documents.forEach((data) => {
				if (data.completed[`${type}${index}`] === false) {
					if (data.participants[`${type}${index}`] === 'required') {
						requireFlag = true;
					}
				}
			});
		}

		return (
			<TabPane
				tab={
					<span>
						{requireFlag ? notificationB() : null}

						{t(`mydocuments.participant`) + index}
					</span>
				}
				key={`${type}${index}`}>
				<Text
					style={{
						marginTop: 24,
						color: '#2F4858',
						fontSize: 12,
						fontWeight: 400
					}}>
					{t('stages.documents').toUpperCase()}&nbsp;
					{nameOperations &&
						nameOperations.operationData &&
						nameOperations.operationData[`owner${index}`] && (
							<Text
								style={{
									marginTop: 24,
									color: '#02C3CD',
									fontSize: 12,
									fontWeight: 400
								}}>
								{t('certificate.of').toUpperCase() +
									' ' +
									nameOperations.operationData[`owner${index}`].name.toUpperCase()}
							</Text>
						)}
				</Text>

				{renderDocumentInformation(index, type)}
			</TabPane>
		);
	};

	const handleChangeSwitch = (value) => {
		//setVisible(false)

		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', value);
		setDocAutomatic(value);
		getDetailsCertificate('');
	};

	const handleChangeSwitchBank = (value) => {
		setVisible(false);
		let owner = 'owner' + indexOwner;
		saveData(owner, 'afterBanks', value);
		getDetailsCertificate('');
	};

	const renderTabsBanks = (index) => {
		let requireFlag = false;
		let requireFlag2 = false;

		if (
			valuesDataForCertificate.documents &&
			detailsCertificate &&
			detailsCertificate.operationData &&
			detailsCertificate.operationData.operation.owners
		) {
			for (let index = 1; index <= detailsCertificate.operationData.operation.owners; index++) {
				valuesDataForCertificate.documents.forEach((data) => {
					if (data.completed[`owner${index}`] === false) {
						if (data.participants[`owner${index}`] === 'required') {
							requireFlag = true;
						}
					}
				});
			}
		}

		if (valuesDataForCertificate.documents) {
			valuesDataForCertificate.documents.forEach((data) => {
				if (data.completed[`owner${index}`] === false) {
					if (fileAfterbanks === undefined && fileBanks3Moths === undefined) {
						requireFlag2 = true;
					}
				}
			});
		}

		return (<>
            <TabPane
                tab={
                    <span>
                        {requireFlag ? notificationB() : null}

                        {t('Doc. oficial')}
                    </span>
                }
                key={1}>
                <div
                    style={{
                        display: 'flex',
                        position: 'relative',
                        float: 'right',
                        top: -50
                    }}>
                    {detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].manualDoc === false &&
                        activeNbric && (
                            <div style={{ display: 'flex' }}>
                                <Text
                                    style={{
                                        marginRight: 10,
                                        color: '#2F4858',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        marginBottom: 38
                                    }}>
                                    {t('certificate.downloadSwitch')}
                                </Text>

                                <Switch defaultChecked={true} onChange={() => handleChangeSwitch(true)} />
                            </div>
                        )}

                    {detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].manualDoc &&
                        activeNbric && (
                            <div style={{ display: 'flex' }}>
                                <Text
                                    style={{
                                        marginRight: 10,
                                        marginLeft: 30,
                                        color: '#2F4858',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        marginBottom: 38
                                    }}>
                                    {t('certificate.downloadSwitchManual')}
                                </Text>

                                <Switch defaultChecked={false} onChange={() => handleChangeSwitch(false)} />
                            </div>
                        )}
                </div>
                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].manualDoc && (
                        <Card
                            className="card-legend"
                            style={{
                                borderColor: '#C0F0F3',
                                borderRadius: 4,
                                background: '#EFFCFC'
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex' }}>
                                    <ExclamationCircleFilled
                                        style={{
                                            color: '#2F4858',
                                            marginRight: 16,
                                            marginTop: 2
                                        }}
                                    />
                                    <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 600 }}>
                                        {t('certificate.legend3')}{' '}
                                    </Text>
                                </div>
                                <div style={{ display: 'flex', marginTop: 8 }}>
                                    <ExclamationCircleOutlined
                                        style={{
                                            color: '#2F4858',
                                            marginRight: 16,
                                            marginTop: 2
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {t('certificate.legend2')}{' '}
                                    </Text>
                                </div>
                                <div style={{ display: 'flex', marginTop: 8 }}>
                                    <CheckOutlined
                                        style={{
                                            color: '#52C41A',
                                            marginRight: 16,
                                            marginTop: 2
                                        }}
                                    />{' '}
                                    <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                        {t('certificate.legend1')}{' '}
                                    </Text>
                                </div>
                            </div>
                        </Card>
                    )}

                {nameOperations && nameOperations.operationData && (
                    <div style={{ marginTop: 24 }}>
                        <Text
                            style={{
                                marginTop: 24,
                                color: '#2F4858',
                                fontSize: 12,
                                fontWeight: 400
                            }}>
                            {t('stages.documents').toUpperCase()}&nbsp;
                            <Text
                                style={{
                                    marginTop: 24,
                                    color: '#02C3CD',
                                    fontSize: 12,
                                    fontWeight: 400
                                }}>
                                {t('certificate.of').toUpperCase() +
                                    ' ' +
                                    nameOperations.operationData[currentOwner].name.toUpperCase() +
                                    ' ' +
                                    nameOperations.operationData[currentOwner].surname1.toUpperCase()}
                            </Text>
                        </Text>
                    </div>
                )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted === undefined &&
                    (detailsCertificate.operationData[currentOwner].manualDoc === undefined ||
                        detailsCertificate.operationData[currentOwner].manualDoc === false) &&
                    currentOwner !== 'owner1' && (
                        <Text
                            style={{
                                marginRight: 10,
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginBottom: 38
                            }}>
                            {t('certificate.downloadParticipants2')}
                        </Text>
                    )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    currentOwner === 'owner1' &&
                    !detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted && (
                        <Text
                            style={{
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginTop: 8
                            }}>
                            {t('certificate.downloadTextDesc')}
                        </Text>
                    )}

                {docAutomatic &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    (detailsCertificate.operationData[currentOwner].manualDoc ||
                        detailsCertificate.operationData[currentOwner].manualDoc === undefined) &&
                    renderDocumentInformation(indexOwner, 'owner')}

                {account !== null &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    detailsCertificate.operationData[currentOwner].manualDoc !== undefined &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted && (
                        <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                            <Col xs={2}>
                                <CheckOutlined style={{ color: '#52C41A' }} />
                            </Col>
                            <Col xs={20}>
                                <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {' '}
                                    {t('certificate.docOficialZip')}
                                </Text>
                            </Col>
                            <Col xs={2} style={{ display: 'flex', width: '100%' }}>
                                <div onClick={() => handleGetDocOficialZip()}>
                                    <DownloadOutlined
                                        style={{
                                            marginRight: 22,
                                            color: '#2F4858',
                                            fontSize: 14
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}

                {account !== null &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted &&
                    currentOwner !== 'owner1' && (
                        <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                            <Col xs={2}>
                                <CheckOutlined style={{ color: '#52C41A' }} />
                            </Col>
                            <Col xs={20}>
                                <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {' '}
                                    {t('certificate.docOficialZip')}
                                </Text>
                            </Col>
                            <Col xs={2} style={{ display: 'flex', width: '100%' }}>
                                <div onClick={() => handleGetDocOficialZip()}>
                                    <DownloadOutlined
                                        style={{
                                            marginRight: 22,
                                            color: '#2F4858',
                                            fontSize: 14
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}

                {account === null &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted && (
                        //mostrar hasta que me diga el back que ya estan listos los docs
                        (<div style={{ marginTop: 8 }}>
                            <CheckOutlined style={{ color: '#52C41A' }} />
                            <Text
                                style={{
                                    color: '#748EA0',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginLeft: 10
                                }}>
                                {t('certificate.docOficialDesc')}
                            </Text>
                        </div>)
                    )}
            </TabPane>
            <TabPane
                tab={
                    <span>
                        {requireFlag2 ? notificationB() : null}

                        {t('Doc. bancaria')}
                    </span>
                }
                key={2}>
                {((detailsCertificate &&
                    detailsCertificate.operationData &&
                    (!detailsCertificate.operationData[currentOwner].afterBanksConnect ||
                        detailsCertificate.operationData[currentOwner].afterBanksConnect === undefined) &&
                    fileAfterbanks === undefined &&
                    !detailsCertificate.operationData[currentOwner].afterBanks) ||
                    (detailsCertificate &&
                        detailsCertificate.operationData &&
                        !detailsCertificate.operationData[currentOwner].afterBanks)) && (
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                            float: 'right',
                            top: -50
                        }}>
                        <Text
                            style={{
                                marginLeft: 20,
                                marginRight: 10,
                                color: '#2F4858',
                                fontSize: 14,
                                fontWeight: 400,
                                marginBottom: 38
                            }}>
                            {t('certificate.downloadSwitch')}
                        </Text>

                        <Switch defaultChecked={true} onChange={() => handleChangeSwitchBank(true)} />
                    </div>
                )}

                {((detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanks &&
                    fileAfterbanks === undefined) ||
                    (detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].afterBanks)) && (
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                            float: 'right',
                            top: -50
                        }}>
                        <Text
                            style={{
                                marginRight: 10,
                                marginLeft: 30,
                                color: '#2F4858',
                                fontSize: 14,
                                fontWeight: 400,
                                marginBottom: 38
                            }}>
                            {t('certificate.downloadSwitchManual')}
                        </Text>

                        <Switch defaultChecked={false} onChange={() => handleChangeSwitchBank(false)} />
                    </div>
                )}

                {((detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanks &&
                    fileAfterbanks === undefined) ||
                    (detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].afterBanks)) && (
                    <Card
                        className="card-legend"
                        style={{
                            borderColor: '#C0F0F3',
                            borderRadius: 4,
                            background: '#EFFCFC'
                        }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>
                                <ExclamationCircleFilled
                                    style={{ color: '#2F4858', marginRight: 16, marginTop: 2 }}
                                />
                                <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 600 }}>
                                    {t('certificate.legend3')}{' '}
                                </Text>
                            </div>
                            <div style={{ display: 'flex', marginTop: 8 }}>
                                <ExclamationCircleOutlined
                                    style={{ color: '#2F4858', marginRight: 16, marginTop: 2 }}
                                />
                                <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {t('certificate.legend2')}{' '}
                                </Text>
                            </div>
                            <div style={{ display: 'flex', marginTop: 8 }}>
                                <CheckOutlined style={{ color: '#52C41A', marginRight: 16, marginTop: 2 }} />{' '}
                                <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {t('certificate.legend1')}{' '}
                                </Text>
                            </div>
                        </div>
                    </Card>
                )}

                {nameOperations && nameOperations.operationData && (
                    <div style={{ marginTop: 24 }}>
                        <Text
                            style={{
                                marginTop: 24,
                                color: '#2F4858',
                                fontSize: 12,
                                fontWeight: 400
                            }}>
                            {t('stages.documents').toUpperCase()}&nbsp;
                            <Text
                                style={{
                                    marginTop: 24,
                                    color: '#02C3CD',
                                    fontSize: 12,
                                    fontWeight: 400
                                }}>
                                {t('certificate.of').toUpperCase() +
                                    ' ' +
                                    nameOperations.operationData[currentOwner].name !==
                                    undefined && nameOperations.operationData[`owner${index}`].name.toUpperCase()}
                            </Text>
                        </Text>
                    </div>
                )}

                {clickTabDocumentation === '2' && (
                    <Row style={{ display: 'flex', marginTop: 0, width: '100%' }}>
                        <Text
                            style={{
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginTop: 8
                            }}>
                            {t('certificate.downloadTextDesc')}
                        </Text>
                    </Row>
                )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanksConnect &&
                    documentsByOwner.length === 0 &&
                    fileAfterbanks !== undefined &&
                    fileAfterbanks.owner === currentOwner && (
                        <Row style={{ display: 'flex', marginTop: 0, width: '100%' }}>
                            <Text
                                style={{
                                    color: '#748EA0',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginTop: 8
                                }}>
                                {t('certificate.downloadTextDescDownload')}
                            </Text>
                        </Row>
                    )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].afterBanks &&
                    documentsByOwner.length === 0 &&
                    fileAfterbanks === undefined &&
                    detailsCertificate.operationData[currentOwner].afterBanksConnect && (
                        <Row style={{ display: 'flex', marginTop: 0, width: '100%' }}>
                            <Text
                                style={{
                                    color: '#748EA0',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginTop: 8
                                }}>
                                {t('certificate.downloadTextDescDownload2')}
                            </Text>
                        </Row>
                    )}

                {fileAfterbanks &&
                    fileAfterbanks.map((item) => {
                        if (item.owner === currentOwner) {
                            return (
                                <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                                    <Col xs={22}>
                                        <CheckOutlined style={{ color: '#52C41A' }} />
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontSize: 14,
                                                fontWeight: 400,
                                                marginLeft: 8
                                            }}>
                                            {' '}
                                            {item.name.substr(0, 30) + '...'}
                                        </Text>
                                    </Col>
                                    <Col
                                        xs={2}
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            cursor: 'pointer',
                                            justifyContent: 'right'
                                        }}>
                                        <div onClick={() => handleFileDownload(item.id, item)}>
                                            <DownloadOutlined style={{ color: '#2F4858', fontSize: 14 }} />
                                        </div>
                                    </Col>
                                </Row>
                            );
                        }
                    })}

                {clickTabDocumentation === '2' &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].afterBanks &&
                    (fileAfterbanks || fileBanks3Moths) && (
                        <Row
                            style={{ display: 'flex', marginTop: 22, width: '100%' }}
                            onClick={() => authorizeAfterBank()}>
                            <Col xs={24}>
                                <PlusOutlined style={{ color: '#02C3CD' }} />
                                <Text
                                    style={{
                                        color: '#02C3CD',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginLeft: 8
                                    }}>
                                    {' '}
                                    {t('certificate.bankDowload')}
                                </Text>
                            </Col>
                        </Row>
                    )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    (!detailsCertificate.operationData[currentOwner].afterBanksConnect ||
                        detailsCertificate.operationData[currentOwner].afterBanksConnect === undefined) &&
                    fileBanks3Moths &&
                    fileBanks3Moths.map((item) => {
                        return (
                            <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                                <Col xs={23}>
                                    <CheckOutlined style={{ color: '#52C41A' }} />
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            marginLeft: 10
                                        }}>
                                        {' '}
                                        {t('certificate.uploadBankExtract')}
                                    </Text>
                                </Col>

                                <Col
                                    xs={1}
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'right'
                                    }}>
                                    <div
                                        onClick={() => {
                                            modalDocument(item.id);
                                        }}>
                                        <DeleteOutlined style={{ color: '#FF4D4F' }} />
                                    </div>
                                    <div onClick={() => handleFileDownload(item.id, item)}>
                                        <DownloadOutlined style={{ marginLeft: 15, color: '#2F4858' }} />
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanks && (
                        <Row
                            style={{
                                display: 'flex',
                                marginTop: 22,
                                width: '100%',
                                background: 'white'
                            }}>
                            <Col xs={23}>
                                {fileAfterbanks || fileBanks3Moths ? (
                                    <ExclamationCircleFilled style={{ color: '#2F4858' }} />
                                ) : (
                                    <ExclamationCircleFilled style={{ color: '#2F4858' }} />
                                )}
                                <Text
                                    style={{
                                        color: '#2F4858',
                                        fontSize: 14,
                                        fontWeight: 600,
                                        marginLeft: 10
                                    }}>
                                    {' '}
                                    {t('certificate.uploadBankExtract')}
                                </Text>
                            </Col>

                            <Col
                                xs={1}
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    marginTop: -5,
                                    justifyContent: 'right'
                                }}>
                                <div
                                    className="PlusButton"
                                    style={{
                                        width: '14px',
                                        height: '14px'
                                    }}>
                                    <Upload multiple={true} {...uploadProps}>
                                        <a
                                            onClick={() => handleClickPlus(clickTab, fileBanks3MothsGroups)}
                                            style={{ border: 'none' }}>
                                            <PlusOutlined style={{ color: '#02C3CD', fontSize: 15 }} />
                                        </a>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                    )}
            </TabPane>
        </>);
	};
	const notificationB = () => {
		return (
			<div style={{ float: 'right', marginTop: '1px', marginRight: '-5px' }}>
				<Badge
					size="small"
					dot={true}
					style={{
						float: 'right',
						width: '4px',
						height: '6px',
						marginLeft: '4px'
					}}></Badge>
			</div>
		);
	};

	const documentTabs = [];

	if (
		detailsCertificate &&
		detailsCertificate.operationData &&
		detailsCertificate.operationData.operation
	) {
		for (let i = 1; i <= detailsCertificate.operationData.operation.owners; i++) {
			documentTabs.push(renderTabs(i, 'owner'));
		}
	}

	const documentTabsBanks = [];

	if (currentOwner) {
		documentTabsBanks.push(renderTabsBanks(1));
	}

	const handleClickModal0 = () => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', false, false);
	};

	const handleClickModal1 = () => {
		setLoading(true);
	};

	const getDetailsCertificate = async () => {
		const response = await axios.get(`${env.api.url}/v1/operations/${operationId}`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});

		setDetailsCertificate(response.data.data);
		setOwnerRefresh(response.data.data.operationData.certificate.currentOwner);
		setNameOperations(response.data.data);

		setCurrentOwner(response.data.data.operationData.certificate.currentOwner);

		let indexOwnerTmp = response.data.data.operationData.certificate.currentOwner.slice(-1);
		indexOwnerTmp = parseInt(indexOwnerTmp);

		setIndexOwner(indexOwnerTmp);

		if (
			response.data.data.operationData[response.data.data.operationData.certificate.currentOwner]
				.manualDoc !== undefined
		) {
			setDocAutomatic(true);
		} else {
			setDocAutomatic(false);
		}

		/* if (response.data.data.operationData[currentOwner].manualDoc) {
             setVisible(false)
         }*/
	};

	const saveData = (participant, key, value, nbric, type) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${operationId}`,
				{ key, participant, value },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					getDetailsCertificate(nbric);
					getGroupsDoc();
					getDocuments();
					setVisible4(false);
					setVisible(false);
					setClickTab(nameOperations.operationData.certificate.currentOwner);

					if (type === 'analysis') {
						navigate(`/operations/certificate/${operationId}/analysis`);
						//window.location.reload();
					}
				}
			});
	};

	const dataMultiple = (owner, key, value, owner1, key1, value1, nbric) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
				{
					values: [
						{
							key: owner,
							subkey: key,
							value: value
						},
						{
							key: owner1,
							subkey: key1,
							value: value1
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					getDetailsCertificate(nbric);
					getGroupsDoc();
					getDocuments();
					setVisible4(false);
					setVisible(false);
					setClickTab(detailsCertificate.operationData.certificate.currentOwner);
				}
			});
	};

	const handleClickModal0Cancel = () => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', true, false);
		setVisible(false);
		setDocAutomatic(true);
	};

	const authorizeAfterBank = () => {
		setVisible6(true);
	};

	const handleafterBanks = () => {
		setVisible3(false);
		setVisible6(!visible6);
		saveData(clickTab, 'afterbankCompleted', true);
	};

	const handleafterBanksCancel = (value) => {
		setVisible3(value);
		setVisible6(value);
	};

	const handleClickModal2Cancel = () => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'afterBanksConnect', false, false);
		setVisible2(false);
		setVisible1(false);
	};

	const handleClickModal2OnOk = () => {
		let owner = 'owner' + indexOwner;

		dataMultiple(owner, 'afterBanks', true, owner, 'afterBanksConnect', true, true);

		setVisible3(!visible3);
		setVisible2(!visible2);
	};

	const handleClickModal2OnOk6 = () => {
		let owner = 'owner' + indexOwner;
		dataMultiple(owner, 'afterBanks', true, owner, 'afterBanksConnect', true, true);

		setVisible6(false);
		setVisible3(!visible3);
	};

	const handleModalDownloadOptionsOptions = () => {
		setVisibleModalDownloadOptions(false);
		if (downloadOptionsRadio === 'afterBanks') {
			setVisible3(true);
		}
		if (downloadOptionsRadio === 'clavePin') {
			callToBankFlip(operationId);
		}
	};

	const onChangeDownloadRadio = (e) => {
		setDownloadOptionsRadio(e.target.value);
	};

	const dataSteps = [
		{
			title: 'document.documentation',
			info: param.stage === 'documents' ? 'documentsCertificate.desc' : 'm_temporalLine.documents ',
			numStages: 0
		},
		{
			title: 'stages.analysisStep',
			info: 'm_temporalLine.analysis',
			numStages: 1
		}
	];

	const sendUpdateDataDNI = (owner, subkey, value) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
				{
					values: [
						{
							key: owner,
							subkey: subkey,
							value:
								subkey === 'birthdate' || subkey === 'nifDueDate'
									? dayjs(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
									: value
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			
	};

	const closeUpdateData = () => {
		setModalUpdateData(false);
		//setVisible(true);

		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', false, false);
	};

	const callToBankFlip = (id) => {
		axios
			.post(
				`${env.api.url}/docs/bankflip/create-session`,
				{
					operationId: id,
					personKey: clickTab
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setWidgetLink(response.data.data);
				setModalBankFlip(true);
			});
	};

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '80vh',
					backgroundColor: 'white'
				}}>
				<LoaderLogo />

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
						top: '45%'
					}}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#4D4D4D' }}>
						{t('certificate.downloadLoad')}
					</Text>
				</div>
			</div>
		);
	}

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '100vh',
					backgroundColor: 'white'
				}}>
				<img
					src={loadingLogo}
					alt="asdf"
					style={{ position: 'absolute', top: '50%' }}
					width="100px"
					height="100px"
				/>
			</div>
		);
	} else {
		return (<>
            {valuesDataForCertificate && detailsCertificate && (
                <Layout style={{ padding: 40, height: 'auto', paddingTop: 0, marginBottom: 30 }}>
                    <div style={{ marginTop: 15 }}>
                        <BreadcrumbsParticular
                            name={nameOperations && nameOperations.subname}
                            returnPage={'/products-under-contract'}
                            certificated={true}
                        />
                    </div>
                    {alertResent && activeNbric && (
                        <div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
                            <Alert
                                message={t('alert.certificaedReSent')}
                                type="success"
                                showIcon
                                closable
                                style={{
                                    width: 400,
                                    zIndex: 1000,
                                    background: '#DFF4D4',
                                    height: 56
                                }}
                            />
                        </div>
                    )}
                    {nameOperations &&
                        nameOperations.operationData &&
                        nameOperations.operationData[`${clickTab}`].automaticDocOTPCompleted &&
                        (nameOperations.operationData[`${clickTab}`].afterbankCompleted === false ||
                            nameOperations.operationData[`${clickTab}`].afterbankCompleted === undefined) &&
                        param.stage !== 'analysis' &&
                        activeNbric && (
                            <div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
                                <Alert
                                    message={t('alert.textDownload')}
                                    type="success"
                                    showIcon
                                    closable
                                    style={{
                                        width: 700,
                                        zIndex: 1000,
                                        background: '#DFF4D4',
                                        height: 56
                                    }}
                                />
                            </div>
                        )}

                    {/*nameOperations &&
            nameOperations.operationData &&
            nameOperations.operationData[`${clickTab}`].afterbankCompleted &&
            (nameOperations.operationData[`${clickTab}`].automaticDocOTPCompleted === false ||
                nameOperations.operationData[`${clickTab}`].automaticDocOTPCompleted === undefined) &&
            param.stage !== 'analysis' && (
                <div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
                    <Alert
                        message={t('alert.textDownload2')}
                        type="success"
                        showIcon
                        closable
                        style={{
                            width: 700,
                            zIndex: 1000,
                            background: '#DFF4D4',
                            height: 56
                        }}
                    />
                </div>
                    )*/}

                    {/*nameOperations &&
            nameOperations.operationData &&
            nameOperations.operationData[`${clickTab}`].afterbankCompleted &&
            nameOperations.operationData[`${clickTab}`].automaticDocOTPCompleted &&
            param.stage !== 'analysis' && (
                <div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
                    <Alert
                        message={t('alert.textDownload3')}
                        type="success"
                        showIcon
                        closable
                        style={{
                            width: 700,
                            zIndex: 1000,
                            background: '#DFF4D4',
                            height: 56
                        }}
                    />
                </div>
                    )*/}
                    {errorPin && activeNbric && (
                        <div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
                            <Alert
                                closable
                                style={{
                                    width: 300,
                                    zIndex: 1000,
                                    background: '#FFDEDE',
                                    height: 56,
                                    borderColor: '1px solid #FF7A7B'
                                }}
                                message={
                                    <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                        {t('alert.pinError')}{' '}
                                    </Text>
                                }
                                type="error"
                                showIcon
                            />
                        </div>
                    )}

                    {param.stage === 'analysis' && (
                        <Row gutter={24} style={{ marginBottom: 50 }}>
                            <Col lg={18} md={24} sm={24} xs={24}>
                                <Card className="gb-card">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ width: '100%' }}>
                                            <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                                {t('stages.analysis')}
                                            </Text>
                                            {/* <div onClick={() => setShowStpper(!showStpper)}>   <InfoCircleOutlined style={{ color: "#02C3CD", float: "right", fontSize: 18, marginTop: -22 }} /> </div>*/}
                                        </div>
                                        <Text className="description-card-grey" style={{ marginTop: 8 }}>
                                            {t('certificate.descriptionAnalysis')}
                                        </Text>
                                    </div>
                                    {valuesDataForCertificate && (
                                        <DocumentsCertificate
                                            downloadOptionsRadio={() => setVisibleModalDownloadOptions(true)}
                                            goToAnalysis={() => setVisible5(true)}
                                            setLoading={setLoading}
                                            setValuesDataForCertificate={setValuesDataForCertificate}
                                            valuesDataForCertificate={valuesDataForCertificate}
                                        />
                                    )}
                                </Card>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={24}>
                                <Card className="gb-card" style={{ height: 400, textAlign: 'center' }}>
                                    <Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
                                        {t('document.personalAnalyst')}
                                    </Text>

                                    {nameOperations && <PersonalAnalyst analyst={nameOperations}></PersonalAnalyst>}
                                </Card>
                                <Card className="gb-card" style={{ textAlign: 'center', marginTop: 24 }}>
                                    <div
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: '#2F4858',
                                            marginBottom: 34
                                        }}>
                                        {t('document.modatTitlteSteper')}
                                    </div>
                                    <StepsNewStyle
                                        stage={param.stage === 'documents' ? 0 : 1}
                                        dataSteps={dataSteps}
                                        height={'auto'}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    )}

                    {param.stage === 'documents' && (
                        <>
                            <Row gutter={[24, 24]}>
                                <Col lg={18} md={24} sm={24} xs={24}>
                                    <Card className="gb-card" style={{ marginBottom: 50 }}>
                                        <Text style={{ color: '#2F4858', fontSize: 16, fontWeight: 600 }}>
                                            {param.stage === 'documents' ? t('stages.documents') : t('stages.analysis')}
                                        </Text>

                                        <Row style={{ display: 'flex', marginTop: 8 }}>
                                            <Col lg={24} md={24} sm={24} xs={24} style={{ width: '100%' }}>
                                                <Text className="description-card-grey" style={{ marginTop: 8 }}>
                                                    {param.stage === 'documents'
                                                        ? t('certificate.descriptionDocuments')
                                                        : t('certificate.descriptionAnalysis')}
                                                </Text>
                                            </Col>
                                        </Row>
                                        {valuesDataForCertificate && (
                                            <DocumentsCertificate
                                                downloadOptionsRadio={() => setVisibleModalDownloadOptions(true)}
                                                goToAnalysis={() => setVisible5(true)}
                                                setLoading={setLoading}
                                                setValuesDataForCertificate={setValuesDataForCertificate}
                                                valuesDataForCertificate={valuesDataForCertificate}
                                            />
                                        )}
                                    </Card>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={24}>
                                    <Card className="gb-card" style={{ height: 400, textAlign: 'center' }}>
                                        <Text style={{ fontSize: 12, fontWeight: 500, color: '#748EA0' }}>
                                            {t('document.personalAnalyst')}
                                        </Text>

                                        {nameOperations && (
                                            <PersonalAnalyst analyst={nameOperations}></PersonalAnalyst>
                                        )}
                                    </Card>
                                    <Card className="gb-card" style={{ textAlign: 'center', marginTop: 24 }}>
                                        <div
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: '#2F4858',
                                                marginBottom: 34
                                            }}>
                                            {t('document.modatTitlteSteper')}
                                        </div>
                                        <StepsNewStyle
                                            stage={param.stage === 'documents' ? 0 : 1}
                                            dataSteps={dataSteps}
                                            height={'auto'}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <Card
                                className="cardButtonStyle"
                                style={{ left: 0, marginBottom: -2, textAlign: 'center' }}>
                                <Button
                                    className="button-primari-gibobs"
                                    type="primary"
                                    style={{
                                        fontSize: '14px',
                                        width: 269,
                                        height: 40,
                                        fontWeight: 600
                                    }}
                                    onClick={() => setVisible5(true)}
                                    disabled={
                                        valuesDataForCertificate?.documentsCompleted !==
                                        valuesDataForCertificate?.documentsNeeded
                                    }>
                                    {t('certificatedModal.documentationTitle')}
                                </Button>
                            </Card>

                            {detailsCertificate &&
                                detailsCertificate.operationData &&
                                detailsCertificate.operationData[`${clickTab}`].automaticDocOTPCompleted ===
                                    undefined &&
                                currentOwner === 'owner1' &&
                                !hiddenAutorze && (
                                    <Modal
                                        className="ModalDesktopFooterColum"
                                        okButtonProps={{
                                            className: 'button-primari-gibobs',
                                            style: {
                                                width: '100%',
                                                alignSelf: 'center',
                                                height: 40,
                                                marginBottom: 10,
                                                marginLeft: -5
                                            }
                                        }}
                                        okText={t('viability.continue')}
                                        cancelText={
                                            <Text
                                                style={{
                                                    color: '#02C3CD',
                                                    fontSize: 14,
                                                    fontWeight: 500
                                                }}>
                                                {t('certificated.modal1mobileDoc')}
                                            </Text>
                                        }
                                        open={visible}
                                        title={<Text>{t('certificate.ModalVisible1Titlte')}</Text>}
                                        width={480}
                                        cancelButtonProps={{ style: { border: 'none' } }}
                                        closable={false}
                                        onOk={() => handleClickModal0()}
                                        onCancel={() => handleClickModal0Cancel()}>
                                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                                            {t('certificate.ModalVisible1Desc')}
                                        </Text>
                                    </Modal>
                                )}
                        </>
                    )}

                    <Modal
                        className="ModalDesktopCert"
                        okButtonProps={{ style: { width: '100%' } }}
                        okText={t('viability.continue')}
                        cancelText={
                            <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                {t('offers.cancel')}
                            </Text>
                        }
                        open={visible4}
                        title={<Text>{t('modal.participantsMobileTitle')}</Text>}
                        width={480}
                        cancelButtonProps={{ style: { width: '100%' } }}
                        onOk={() => handleNextParticipant()}
                        onCancel={() => {
                            setVisible4(false);
                        }}>
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                            {t('modal.participantsMobileDes')}
                        </Text>
                    </Modal>

                    <Modal
                        className="ModalDesktopCert"
                        okButtonProps={{ style: { width: '100%' } }}
                        okText={t('viability.continue')}
                        cancelText={
                            <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                {t('offers.cancel')}
                            </Text>
                        }
                        open={visible5}
                        title={<Text>{t('certificatedModal.documentationTitle')}</Text>}
                        width={480}
                        cancelButtonProps={{ style: { width: '100%' } }}
                        onOk={() => handleClickConfirmedDoc()}
                        onCancel={() => {
                            setVisible5(false);
                        }}>
                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                            {t('certificatedModal.documentationDesc')}
                        </Text>
                    </Modal>

                    <Modal
                        className="ModalDesktopCert"
                        okButtonProps={{ style: { width: '100%' } }}
                        okText={t('viability.continue')}
                        cancelText={
                            <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                {t('modal.cancelJump')}
                            </Text>
                        }
                        open={visible2}
                        title={<Text>{t('certificated.modal3mobileTitle1')}</Text>}
                        width={480}
                        cancelButtonProps={{ style: { width: '100%' } }}
                        onOk={() => {
                            handleClickModal2OnOk();
                        }}
                        onCancel={() => handleClickModal2Cancel()}>
                        <Text
                            style={{
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginTop: 8
                            }}>
                            {t('certificated.modal3mobileDesc1')}
                        </Text>
                    </Modal>
                    {detailsCertificate && detailsCertificate.operationData && (
                        <Modal
                            className="ModalDesktopCert"
                            okButtonProps={{ style: { width: '100%', height: 40 } }}
                            okText={t('viability.continue')}
                            cancelText={
                                <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                    {t('offers.cancel')}
                                </Text>
                            }
                            open={visible1}
                            title={<Text>{t('nbric.modal1titlte')}</Text>}
                            width={480}
                            cancelButtonProps={{ style: { width: '100%', height: 40 } }}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        handleClickModal1(values);
                                        form.resetFields();
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                            }}
                            onCancel={() => handleClickModal1cancel(false)}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: -34,
                                    marginBottom: -20
                                }}>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                    <img
                                        src={countPin}
                                        alt="asdf"
                                        style={{ width: 176, heigh: 176, alignSelf: 'center' }}></img>

                                    <CountDown
                                        style={{ fontSize: 22, color: '#02C3CD', fontWeight: 600 }}
                                        target={targetTime}
                                    />
                                </div>

                                <Text
                                    style={{
                                        color: '#748EA0',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        width: 400,
                                        marginTop: 20
                                    }}>
                                    {t('nbric.modalPinDescTime')}
                                </Text>
                                <Text
                                    style={{
                                        color: '#748EA0',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        width: 400,
                                        marginTop: 16
                                    }}>
                                    {t('nbric.documentsDecPinMobile')}
                                </Text>

                                <Form form={form} layout="vertical" name="pin" style={{ marginTop: 16 }}>
                                    <Row gutter={24}>
                                        <Col lg={24} xs={24}>
                                            <Form.Item name="pin" label={''} style={{ width: '100%' }}>
                                                <Input
                                                    placeholder={t('certificated.modalPin')}
                                                    style={{ width: '100%', height: 40 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Modal>
                    )}
                    <Modal
                        className="ModalDesktopCert"
                        okButtonProps={{ style: { width: '100%' } }}
                        okText={t('viability.continue')}
                        cancelText={
                            <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                {t('offers.cancel')}
                            </Text>
                        }
                        open={visible6}
                        title={<Text>{t('certificated.modal3mobileTitle1')}</Text>}
                        width={480}
                        cancelButtonProps={{ style: { width: '100%' } }}
                        onOk={() => {
                            handleClickModal2OnOk6();
                        }}
                        onCancel={() => handleClickVisible6Cancel()}>
                        <div style={{ marginTop: 16 }}>
                            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                                {t('certificated.modal6mobileDesc1')}
                            </Text>
                        </div>
                    </Modal>

                    <Modal
                        className="modalDesktopAfterBanks"
                        open={visible3}
                        //  cancelText={<Text style={{ color: "#02C3CD", fontSize: 12, fontWeight: 500 }}>{t("offers.cancel")}</Text>}
                        onCancel={() => handleafterBanksCancel(false)}
                        onOk={() => handleafterBanks()}
                        okText={t('offers.accept')}
                        okButtonProps={{ disabled: fileAfterbanks ? false : true }}
                        cancelButtonProps={{
                            style: { border: 'none', contentVisibility: 'hidden' }
                        }}
                        destroyOnClose={true}
                        width={480}
                        closable={true}>
                        <AfterBanks></AfterBanks>
                    </Modal>
                    <Modal
                        className="ModalDesktopCert"
                        okButtonProps={{ style: { width: '100%' } }}
                        okText={t('viability.continue')}
                        cancelText={
                            <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                {t('offers.cancel')}
                            </Text>
                        }
                        open={visibleModalDownloadOptions}
                        title={t('certificated.automaticDownload')}
                        width={480}
                        cancelButtonProps={{ style: { width: '100%' } }}
                        onOk={() => {
                            handleModalDownloadOptionsOptions(false);
                        }}
                        onCancel={() => setVisibleModalDownloadOptions(false)}>
                        <Text
                            style={{
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginTop: 8
                            }}>
                            {t('certificated.selectOptionDownload')}
                            <Radio.Group
                                name="radiogroup"
                                style={{
                                    marginTop: 16,
                                    fontSize: 14,
                                    color: '#748EA0',
                                    fontWeight: 400
                                }}
                                onChange={(e) => onChangeDownloadRadio(e)}>
                                <Radio
                                    value={'clavePin'}
                                    style={{
                                        color: '#748EA0'
                                    }}>
                                    {t('nbric.radio1')}
                                </Radio>
                                <Radio
                                    value={'afterBanks'}
                                    style={{
                                        color: '#748EA0',
                                        marginTop: 16
                                    }}>
                                    {t('myDocuments.getMyBankStatements')}
                                </Radio>
                            </Radio.Group>
                        </Text>
                    </Modal>

                    {widgetLink && (
                        <Modal
                            className="modalbankflip"
                            open={modalBankFlip}
                            onCancel={() => {
                                setModalBankFlip(false);
                                clearInterval(timerReference);
                            }}
                            okButtonProps={{
                                style: { border: 'none', contentVisibility: 'hidden' }
                            }}
                            cancelButtonProps={{
                                style: { border: 'none', contentVisibility: 'hidden' }
                            }}
                            destroyOnClose={true}
                            closable={true}
                            width={'auto'}>
                            <iframe
                                width={'100%'}
                                height={'800px'}
                                src={widgetLink.widgetLink}
                                style={{ marginTop: 0, border: 'none' }}></iframe>
                        </Modal>
                    )}

                    {currentOwner && (
                        <Modal
                            className="ModalDesktopCert"
                            okButtonProps={{ style: { width: '100%' } }}
                            okText={t('viability.continue')}
                            cancelText={
                                <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                    {t('offers.cancel')}
                                </Text>
                            }
                            open={modalUpdateData}
                            title={t('certificated.UpdateData')}
                            width={480}
                            cancelButtonProps={{ style: { width: '100%' } }}
                            onOk={() => {
                                closeUpdateData();
                            }}
                            onCancel={() => setModalUpdateData(false)}>
                            {detailsCertificate && (
                                <Form
                                    form={form}
                                    layout="vertical"
                                    className="gb-form-profile-headlines-mobile"
                                    name="lonbrican"
                                    initialValues={{
                                        birthdate: moment(
                                            detailsCertificate.operationData[currentOwner].birthdate
                                        ).format('DD-MM-YYYY'),
                                        nif: detailsCertificate.operationData[currentOwner].nif,
                                        phone: detailsCertificate.operationData[currentOwner].phone,
                                        nifDueDate: moment(
                                            detailsCertificate.operationData[currentOwner].nifDueDate
                                        ).format('DD-MM-YYYY')
                                    }}
                                    style={{ marginTop: 24 }}>
                                    <div style={{ marginTop: -20, marginBottom: 24 }}>
                                        <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                                            {t('clavePin.descModalDes1') +
                                                ' ' +
                                                detailsCertificate.agentEmail +
                                                ' ' +
                                                t('clavePin.descModalDes2')}
                                        </Text>
                                    </div>

                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="nif"
                                                label={t('form.dni')}
                                                style={{
                                                    width: '100%'
                                                }}>
                                                <Input
                                                    placeholder={'0000000A'}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    onChange={(e) => sendUpdateDataDNI(clickTab, 'nif', e.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="nifDueDate"
                                                label={t('form.dniDate')}
                                                style={{
                                                    width: '100%'
                                                }}>
                                                <InputDate
                                                    type="date"
                                                    style={{
                                                        width: '100%',
                                                        borderRadius: '4px',
                                                        borderColor: '#C0DAEE'
                                                    }}
                                                    onChange={(e) => sendUpdateDataDNI(clickTab, 'nifDueDate', e)}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                name="appClavePin"
                                                label={t('clavePin.descModal')}
                                                style={{
                                                    width: '100%'
                                                }}>
                                                <Select onChange={(e) => sendUpdateDataDNI(clickTab, 'appClavePin', e)}>
                                                    <Option value={true}>{t('form.yes')}</Option>
                                                    <Option value={false}>{t('form.no')}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Modal>
                    )}
                </Layout>
            )}
        </>);
	}
};

export default OperationCertificated;
