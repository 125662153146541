import React, { useState, useEffect } from "react";
import {
  InputNumber,
  Button,
  Typography,
  Card,
  Form,
  Spin,
  Select,
  Input,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  LoadingOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import env from "../../environment";
import { useSelector, useDispatch } from "react-redux";
import { refreshStages } from "../../store/auth/actions";
import numeral from "numeral";
import {
  ecoBudget,
  maxbudgetGreen,
  mortgageGreen,
  subrogationGreen,
} from "../../store/data/actions";
import { isMobile } from "react-device-detect";
import { refreshData } from "../../store/auth/actions";


const { Option } = Select;

const { Text } = Typography;

function Budget({ title, dataBudget, type }) {
  const { t } = useTranslation();
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const [budgetPress, setBudgetPress] = useState(false);
  const [form] = Form.useForm();
  const [floorM2, setFloorM2] = useState(0);
  const [price, setPrice] = useState(0);
  const [maxBudget, setMaxBudget] = useState(0);
  const [tableSolar, setTableSolar] = useState(0);
  const [loading, setLoading] = useState(false);
  const [power, setPower] = useState(0);
  const userToken = useSelector((state) => state.auth.token);
  const rol = useSelector((state) => state.auth.roles);
  const dispatch = useDispatch();
  const changeStages = useSelector((state) => state.auth.refreshStages);
  const [chargeRedirect, setChargeRedirect] = useState(false);
  const [valuesData, setDataValues] = useState();
  const navigate = useNavigate();;
  const refreshDataChange = useSelector((state) => state.auth.refreshData);
  let newMortgageId = undefined;


  useEffect(() => {
    setBudgetPress(dataBudget);
  }, [dataBudget]);




  const onFinish = (values) => {
    pushBudget(values);
    navigate("/sales-tools/new-requests/solar-value-home/result", type);
    console.log(values);
  };
  const pushBudget = (values) => {
    setMaxBudget(values.price);
    setLoading(true);
    setBudgetPress(true);
    setDataValues(values);
    calculateValues(values);
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        console.log(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  const returnToSimulate = () => {
    setBudgetPress(false);
    navigate("/sales-tools/new-requests/solar-value-home", type);
  };

  const calculateValues = (values) => {
    let s = values.floor;
    let a = Math.sqrt(s / 2);
    let h = a / 2 / Math.cos(0.62831878928161);

    const floorM2 = 4 * h * a;
    setFloorM2(Math.trunc(floorM2));

    if (0 < floorM2 && floorM2 < 50) {
      setTableSolar(4);
      setPrice(3900);
      setPower(1.32);
    } else if (50 <= floorM2 && floorM2 < 100) {
      setTableSolar(6);
      setPrice(4590);
      setPower(1.98);
    } else if (100 <= floorM2 && floorM2 < 150) {
      setTableSolar(8);
      setPrice(5990);
      setPower(3.2);
    } else if (150 <= floorM2 && floorM2 < 200) {
      setTableSolar(10);
      setPrice(6690);
      setPower(4.0);
    } else if (200 <= floorM2 && floorM2 < 300) {
      setTableSolar(12);
      setPrice(7290);
      setPower(4.8);
    } else if (300 <= floorM2 && floorM2 < 400) {
      setTableSolar(14);
      setPrice(8390);
      setPower(5.6);
    } else if (400 <= floorM2 && floorM2 < 500) {
      setTableSolar(17);
      setPrice(9290);
      setPower(6.8);
    } else if (500 <= floorM2) {
      setTableSolar(18);
      setPrice(10090);
      setPower(7.2);
    }
  };

  // const handleUploadFile = (values) => {
  //   axios
  //     .put(
  //       `${env.api.url}/api/mortgages/attachment`,
  //       {
  //         //TODO values del form
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${userToken}` },
  //       }
  //     )
  //     .then((response) => {})
  //     .catch((error) => {});
  // };

  const createMortgage = (type) => {
    setLoading(true);
    let houseState = undefined
    if (type === "green-mortgage") {
      houseState = "have_decide";
    }
    if (type === "green-subrogation") {
      houseState = "subrogation";
    }


    axios
      .get(`${env.api.url}/api/clients/createCustomerMortgage?type=${type}&houseState=${houseState}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        })
      .then((response) => {
        if (response.data.success) {
          dispatch(refreshStages(!changeStages));
          newMortgageId = response.data.data.id
          updateData("mortgage", "ecoBudget", price);

          setTimeout(() => {
            setChargeRedirect(true);
            setLoading(false);
          }, 2000);
        }
      });
  };

  const updateData = (owner, key, value) => {
    axios
      .post(
        `${env.api.url}/v1/mortgages/data/update/${newMortgageId}`,
        { key, participant: owner, value },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      .then(() => {
        dispatch(refreshData(!refreshDataChange));

      });
  };


  if (loading) {
    return <Spin indicator={antIcon} className="spin" />;
  }

  return (
    <Card
      className="CardStyleRealeState"
      style={{
        width: isMobile ? "calc(100% - 32px)" : "calc(100% - 80px)",
        marginBottom: "24px",
        marginLeft: isMobile ? "16px" : "40px",
      }}
    >
      {!budgetPress && (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          name="solarValue"
        >
          <div
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "right",
            }}
          >
            <Text strong style={{ fontSize: "14px" }}>
              {title}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "16px",
              textAlign: isMobile ? "center" : "right",
            }}
          >
            <Text style={{ fontSize: "12px" }}>{t("budget.info")}</Text>
          </div>
          <div
            className="solarValue"
            // marginRight: 20
            style={{
              placeContent: isMobile ? null : "center",
              display: isMobile ? null : "grid",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: -50,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Form.Item
                name="select"
                label={t("budget.typeHome")}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  width: isMobile ? "100%" : "280px",
                  marginTop: isMobile ? "22px" : "50px",
                  marginRight: "40px",
                }}
              >
                  <Select style={{ width: "100%" }}>
                      <Option value="individual">{t("budget.individual")}</Option>
                      <Option value="adosado">{t("budget.townhouse")}</Option>
                      <Option value="pareado">{t("budget.couplet")}</Option>
                      <Option value="casaRustica">{t("budget.rusticHouse")}</Option>
                      <Option disabled={true} value="piso"> {t("budget.house")}</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="floor"
                label={t("budget.floor")}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  width: isMobile ? "100%" : "280px",
                  marginTop: isMobile ? "22px" : "50px",
                }}
              >
                <InputNumber
                  style={{
                    borderColor: "#c0daee",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                  defaultValue={""}
                  placeholder={"0"}
                />
              </Form.Item>

              <Form.Item
                name="Nfloor"
                label={t("budget.Nfloor")}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  width: isMobile ? "100%" : "280px",
                  marginTop: isMobile ? "22px" : "50px",
                  marginLeft: isMobile ? 0 : "40px",
                  marginBottom: isMobile ? "22px" : 0,
                }}
              >
                <InputNumber
                  style={{
                    borderColor: "#c0daee",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                  defaultValue={""}
                  placeholder={"2"}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Form.Item
                name="room"
                label={t("budget.room")}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  width: isMobile ? "100%" : "280px",
                  marginTop: isMobile ? "72px" : "50px",
                }}
              >
                <InputNumber
                  style={{
                    borderColor: "#c0daee",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                  defaultValue={""}
                  placeholder={"0"}
                />
              </Form.Item>

              <Form.Item
                name="price"
                label={t("budget.price")}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  width: isMobile ? "100%" : "280px",
                  marginTop: isMobile ? "22px" : "50px",
                  marginLeft: isMobile ? 0 : "40px",
                }}
              >
                <InputNumber
                  step={100}
                  style={{
                    borderColor: "#c0daee",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                  formatter={(value) =>
                    numeral(value)
                      .format("0 $")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }
                  defaultValue={""}
                  placeholder={"0 €"}
                />
              </Form.Item>
              <Form.Item
                name="direction"
                label={t("budget.direction")}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  width: isMobile ? "100%" : "280px",
                  marginTop: isMobile ? "22px" : "50px",
                  marginLeft: isMobile ? 0 : "40px",
                }}
              >
                <Input
                  style={{
                    borderColor: "#c0daee",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                  defaultValue={""}
                  placeholder={"calle sin numero..."}
                />
              </Form.Item>
            </div>

            <div
              className="gibobs"
              style={{
                marginBottom: isMobile ? "24px" : "40px",
                marginTop: isMobile ? "32px" : "40px",
                marginLeft: isMobile ? 0 : "400px",
              }}
            >
              <Button
                className="button-primari-gibobs"
                type="primary"
                htmlType="solarValue"
              >
                {t("budget.pushBudget")}
              </Button>
            </div>
          </div>
        </Form>
      )}

      {budgetPress && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: isMobile ? "center" : "left",
            }}
          >
            <Text strong style={{ fontSize: "14px" }}>
              {title}
            </Text>
          </div>

          <div
            style={{
              width: "auto",
              marginTop: "16px",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <Text style={{ fontSize: "12px", float: "left" }}>
              {t("budget.estimate")}
            </Text>
          </div>

          <Card
            style={{
              height: 46,
              marginTop: 80,
              borderRadius: "0px",
              borderBottom: "none",
            }}
          >
            <div
              className="ratio"
              style={{ display: "flex", placeContent: "center" }}
            >
              <Text
                style={{ fontSize: "14px", marginTop: -20, fontWeight: "500" }}
              >
                {t("budget.estimateSolar")}
              </Text>
            </div>
          </Card>

          <Card>
            <div
              style={{
                display: isMobile ? null : "flex",
                flexDirection: isMobile ? "column" : "row",
                placeContent: "center",
                marginTop: -105,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  marginTop: "60px",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    float: "left",
                    marginTop: isMobile ? "52px" : "20px",
                    color: "#748EA0",
                    alignSelf: isMobile ? "center" : "left",
                  }}
                >
                  {t("budget.roof")}
                </Text>
                <Text
                  strong
                  style={{
                    color: "#02C3CD",
                    fontSize: "16px",
                    float: "left",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {numeral(floorM2).format("0,0 ") + " m2"}
                </Text>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  marginTop: isMobile ? "24px" : "80px",
                  marginLeft: isMobile ? 0 : "144px",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    float: "left",
                    color: "#748EA0",
                    alignSelf: isMobile ? "center" : "left",
                  }}
                >
                  {t("budget.budget")}
                </Text>

                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    float: "left",
                    marginTop: isMobile ? "8px" : "10px",
                    textAlign: "center",
                  }}
                >
                  {numeral(price).format("0,0 $")}
                </Text>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  marginTop: isMobile ? "24px" : "60px",
                  marginLeft: isMobile ? 0 : "144px",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    float: "left",
                    marginTop: isMobile ? 0 : "20px",
                    color: "#748EA0",
                    alignSelf: isMobile ? "center" : "left",
                  }}
                >
                  {t("budget.table")}
                </Text>
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    float: "left",
                    marginTop: isMobile ? "8px" : "10px",
                    textAlign: "center",
                  }}
                >
                  {tableSolar} {t("budget.tables2")}
                </Text>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  marginTop: isMobile ? "24px" : "80px",
                  marginLeft: isMobile ? 0 : "144px",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    float: "left",
                    color: "#748EA0",
                    alignSelf: isMobile ? "center" : "left",
                  }}
                >
                  {t("budget.power")}
                </Text>

                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    float: "left",
                    marginTop: isMobile ? "8px" : "10px",
                    textAlign: "center",
                  }}
                >
                  {power} kW
                </Text>
              </div>
            </div>
          </Card>
          <div
            className="gibobs"
            style={{
              marginBottom: "40px",
              marginTop: isMobile ? "20px" : "40px",
              placeContent: "center",
              display: "grid",
            }}
          >
            <div
              style={{
                height: 14,
                marginTop: 35,
                display: "contents",
              }}
            >
              <Button
                href={`${env.api.url
                  }/v1/solarvaluehomes/generate-solarvalue-pdf?token=${userToken}&typeHome=${valuesData !== undefined ? valuesData.select : ""
                  }&Nfloor=${valuesData !== undefined ? valuesData.Nfloor : 0
                  }&direction=${valuesData !== undefined ? valuesData.direction : 0
                  }&floor=${valuesData !== undefined ? valuesData.floor : 0
                  }&price=${valuesData !== undefined ? valuesData.price : 0
                  }&room=${valuesData !== undefined ? valuesData.room : 0
                  }&roof=${floorM2}`}
                target={"_blank"}
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  display: "flex",
                  alignSelf: "center",
                  border: "none",
                  marginTop: 30,
                  color: "#748EA0",
                  marginBottom: "40px",
                  marginLeft: isMobile ? 40 : 80,
                  marginRight: isMobile ? 40 : 0,
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                  }}
                >
                  <FilePdfOutlined style={{ fontSize: "20px" }} />
                </div>

                {t("budget.download")}
              </Button>
            </div>

            {rol !== "realestate" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginTop: "8px",
                }}
              >
                <Button
                  className="button-secundary-gibobs"
                  style={{
                    width: isMobile ? "100%" : 148,
                    marginBottom: isMobile ? 8 : 0,
                    height: 32,
                    marginRight: 10,
                  }}
                  onClick={() => {
                    returnToSimulate();
                  }}
                >
                  {t("budget.return")}
                </Button>
                {type === "mortgageGreen" &&

                  <Button
                    className="button-primari-gibobs"
                    type="primary"
                    style={{
                      width: isMobile ? "100%" : 180,
                      marginBottom: isMobile ? 8 : 0,
                      height: 32,
                      marginRight: "8px",
                    }}
                    onClick={async () => {
                      await createMortgage("green-mortgage");
                      await dispatch(mortgageGreen(true));
                      await dispatch(subrogationGreen(false));
                      await dispatch(ecoBudget(price));

                      await dispatch(maxbudgetGreen(maxBudget));
                    }}
                  >
                    {t("budget.continue")}
                  </Button>
                }

                {type === "subrogationGreen" &&
                  <Button
                    className="button-primari-gibobs"
                    type="primary"
                    style={{
                      width: isMobile ? "100%" : 210,
                      height: 32,
                    }}
                  
                    size="small"
                    onClick={async () => {
                      await createMortgage("green-subrogation");
                      await dispatch(subrogationGreen(true));
                      await dispatch(mortgageGreen(false));
                      await dispatch(ecoBudget(price));

                      await dispatch(maxbudgetGreen(maxBudget));
                    }}
                  >
                    {t("budget.continueSubrogation")}
                  </Button>
                }

              </div>
            )}
            {rol === "realestate" && (
              <div
                style={{
                  display: isMobile ? "flex" : null,
                  flexDirection: "column",
                }}
              >
                <Button
                  className="button-secundary-gibobs"
                  style={{
                    width: isMobile ? "100%" : 148,
                    height: 32,
                    marginRight: 10,
                  }}
                  onClick={() => {
                    returnToSimulate();
                  }}
                >
                  {t("budget.return")}
                </Button>
                <Button
                  className="button-primari-gibobs"
                  type="primary"
                  style={{
                    width: isMobile ? "100%" : 180,
                    marginTop: isMobile ? 8 : 0,
                    position: "relative",
                    top: 0,
                  }}
                >
                  <NavLink
                    to={{
                      pathname: "/sales-tools/mortgagesGreen",
                     
                    }}
                   
                    state={
                      price= price
                    }
                  >
                    {t("budget.continue")}
                  </NavLink>
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {chargeRedirect && <Navigate to="/home" />}
    </Card>
  );
}

export default Budget;
