import { Typography, Popover, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { MoreOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isMobileOnly } from 'react-device-detect';
import _ from 'lodash';
import { ReduxStore } from '../DocumentBoxesComponent';
import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const { Text } = Typography;

type Props = {
	clickTab: any;
	file: any;
	valuesData: any;
	modalDocument: any;
	env: any;
};

const RenderFiles = ({ clickTab, file, valuesData, modalDocument, env }: Props) => {
	const { t } = useTranslation();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [modalView, setModalView] = React.useState<boolean | false>(false);
	const [url, setUrl] = React.useState<any | undefined>(undefined);
	const [mime, setMime] = React.useState<any | undefined>(undefined);
	const [fileData, setFileData] = React.useState<any | undefined>(undefined);

	const loadSignedDocument = async (documentId: any) => {
		const response = await axios.get(`${env.api.url}/v1/documents/get-document-url/${documentId}`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});

		const responseFile = await axios.get(response.data.data.url, {
			responseType: 'blob'
		});

		return {
			...response.data.data,
			fileData: responseFile.data
		};
	};

	const handleFileDownload = async (document: any) => {
		if (!document.tags.includes('automatic')) {
			axios
				.get(`${env.api.url}/v1/documents/get-document-url/${document.id}?token=${userToken}`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
				.then((response) => {
					if (response.data.success) {
						window.open(response.data.data.url, '_blank'); //to open new page
					}
				})
				.catch((error) => {});
		} else {
			console.log('hola');
			const { url, name, mime, fileData } = await loadSignedDocument(document.id);

			setUrl(url);
			setMime(mime);
			setFileData(fileData);

			setModalView(true);
		}
	};
	const content = (item: any) => {
		return (
			<div>
				<div
					onClick={() => handleFileDownload(item)}
					style={{ display: 'flex', border: 'none', padding: 5, cursor: 'pointer' }}>
					<EyeOutlined className="see-document" style={{ marginTop: 3 }} />
					<Text className="see-document see-document-text" style={{ marginLeft: 4 }}>
						{t('doc.seeNew')}
					</Text>
				</div>
				<div
					onClick={() => modalDocument(item.id)}
					style={{ display: 'flex', border: 'none', padding: 5, cursor: 'pointer' }}>
					<DeleteOutlined className="delete-document" style={{ marginTop: 3 }} />
					<Text className="delete-document delete-document-text" style={{ marginLeft: 4 }}>
						{t('loan.delete')}
					</Text>
				</div>
			</div>
		);
	};

	const array: any[] = [];
	valuesData.map((item: any) => {
		if (item.type === file?.name && item.owner === clickTab) {
			array.push(
				<div
					className="wrapper-flex"
					style={{ justifyContent: 'space-between' }}
					key={_.uniqueId('render_file')}>
					<div>
						<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
							{item?.name.substr(0, 20)}...
						</Text>
					</div>

					<div style={{ width: 20, height: 20, textAlign: 'center' }}>
						<Popover
							trigger="hover"
							content={content(item)}
							overlayStyle={{
								width: isMobileOnly ? '32vw' : undefined
							}}>
							<MoreOutlined color="#748EA0" style={{ fontWeight: 500 }} />
						</Popover>
					</div>
				</div>
			);
		}

		if (_.isNil(item.type) && item.owner === clickTab && file.name === 'docs.othersDocs') {
			array.push(
				<div
					className="wrapper-flex"
					style={{ justifyContent: 'space-between' }}
					key={_.uniqueId('render_file')}>
					<div>
						<Text style={{ color: '#748EA0', fontSize: 12, fontWeight: 400 }}>
							{item?.name.substr(0, 20)}...
						</Text>
					</div>

					<div style={{ width: 20, height: 20, textAlign: 'center' }}>
						<Popover
							trigger="hover"
							content={content(item)}
							overlayStyle={{
								width: isMobileOnly ? '30vw' : undefined
							}}>
							<MoreOutlined color="#748EA0" style={{ fontWeight: 500 }} />
						</Popover>
					</div>
				</div>
			);
		}
	});

	return (
		<>
			{array}
			{mime && url && fileData && (
				<Modal
					className="ModalDesktopCert"
					width={800}
					style={{ height: 500 }}
					open={modalView}
					onOk={() => setModalView(!modalView)}
					onCancel={() => setModalView(!modalView)}>
					<DocViewer
						className="doc-view-modal"
						style={{ height: 500 }}
						pluginRenderers={DocViewerRenderers}
						documents={[{ uri: url, fileType: mime, fileData: fileData }]}
					/>
				</Modal>
			)}
		</>
	);
};

export default RenderFiles;
