import { useEffect, useState, useRef } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleFilled, CheckCircleFilled, CameraOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoaderLogo from '../../components/LoaderLogo';
import Webcam from 'react-webcam';

const { Text } = Typography;

type Props = {
	env: any;
	userMortgageId: any;
	setStages: any;
	setOpenModals: any;
	platform: string;
	traceSadabdell: any
};

export type ReduxStore = {
	auth: StoreAuth;
};

export type StoreAuth = {
	token: string;
	roles: string;
	operations: any;
};

function ModalsDni({ env, userMortgageId, setStages, setOpenModals, platform, traceSadabdell }: Props) {
	const { t, i18n } = useTranslation();
	const { owner } = useParams<any>();
	const [modal2, setModal2] = useState(false);
	const [saveFileDataField, setSaveFileDataField] = useState([]);
	const [modal3, setModal3] = useState(false);
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState(undefined);
	const [showImage, setShowImage] = useState(undefined);
	const [count, setCount] = useState(0);
	const handleFileInput = useRef<any>({});
	

	
	useEffect(() => {
		(async () => {
			try {
				if (imageUrl !== undefined) {
					const response2 = await axios.get(
						`${env.api.url}/v1/documents/get-document-url/${imageUrl.id}`,
						{
							headers: { Authorization: `Bearer ${userToken}` }
						}
					);
					setShowImage(response2.data.data);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		})();
	}, [imageUrl]);

	useEffect(() => {
		setModal2(true);
		saveData();
		if (platform === 'sabadell') {
			traceSadabdell('paso dni foto',  i18n.language === 'cat' ? 'ca' : 'es')

		}
	

	}, []);

	const saveData = () => {
		const key = 'viewModalsAutomatic';
		const participant = owner;
		const value = true;
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${userMortgageId}`,
				{ key, participant, value },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
	};

	const saveFileData = (file: any, name: any) => {
		const documentFile = saveFileDataField;
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile);
	};

	const handleUploadFile = (file: any) => {
		setLoading(true);
		setSaveFileDataField([]);
		axios
			.put(
				`${env.api.url}/v1/documents/operation/attachment`,
				{
					operationId: userMortgageId,
					file: file[0].file,
					fileName: file[0].fileName,
					owner: owner,
					type: 'nif',
					page: 't'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setImageUrl(response.data.data);
				setLoading(false);
				if (platform !== 'gibobs') {
					setModal2(false);
					setModal3(true);
				}
			})
			.catch((error) => console.log(error));
	};

	const handleSecondPhoto = () => {
		setCount(count + 1);
		if (platform === 'gibobs') {
			if (count < 1) {
				setShowImage(false);
			} else {
				setStages();
			}
		} else {
			if (count < 1) {
				setModal3(false);
				setModal2(true);
			} else {
				setModal3(false);
				setStages();
			}
		}
	};

	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: 'user'
	};

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '80vh',
					backgroundColor: 'white'
				}}>
				<LoaderLogo />
			</div>
		);
	}

	const handleClick = () => {
		handleFileInput.current.click();
	};

	async function encodeFileAsBase64URL(file: any) {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener('loadend', () => {
				resolve(reader.result);
			});
			reader.readAsDataURL(file);
		});
	}

	const handleImageChange = async (event: any) => {
		const base64URL = await encodeFileAsBase64URL(event.target.files[0]);
		let tmpImg = undefined;
		tmpImg = (base64URL as string).split(',')[1];
		saveFileData(tmpImg, 'dni');
	};

	const deleteDocumentModal = (imageUrl: any) => {
		axios
			.get(`${env.api.url}/v1/documents/delete-document/${imageUrl.id}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})

		if (platform === 'gibobs') {
			setShowImage(false);
		}
	};

	return (
		<div>
			<Modal
				className={isMobileOnly ? 'ModalMobileFooterColum' : 'ModalDesktopFooterColum'}
				// open={modal2}
				open={true}
				onCancel={() => {
					setModal2(false);
					setOpenModals(false);
				}}
				maskClosable={false}
				width={isMobileOnly ? 328 : platform === 'gibobs' ? 504 : 480}
				title={count === 0 ? t('dni.photo1') : t('dni.photo2')}
				cancelButtonProps={{
					style: { display: 'none' }
				}}
				okButtonProps={{
					style: { display: 'none' }
				}}
				destroyOnClose={true}
				closable={true}>
				<div style={{ marginBottom: isMobileOnly ? -10 : platform === 'gibobs' ? -10 : 20, marginTop: platform === 'gibobs' ? 16 : 44 }}>
					<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
						{t('dni.instructionMessage')}
					</Text>
				</div>
				{!isMobileOnly && platform === 'gibobs' && (
					!showImage ? (
						<Webcam
							audio={false}
							height={platform === 'gibobs' ? 283 : 300}
							width={platform === 'gibobs' ? 420 : 400}
							videoConstraints={videoConstraints}
							screenshotFormat="image/jpeg"
							forceScreenshotSourceSize={true}>
							{({ getScreenshot, size }) => (
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<div
										onClick={() => {
											let imageSrc = getScreenshot();
											imageSrc = imageSrc?.replace('data:image/jpeg;base64,', '');
											saveFileData(imageSrc, 'dni');
										}}
										style={{
											width: 40,
											height: 40,
											borderRadius: 50,
											backgroundColor: '#748EA0',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											cursor: 'pointer'
										}}>
										<CameraOutlined style={{ fontSize: 24, color: '#fff' }} />
									</div>
								</div>
							)}
						</Webcam>
					) : (
						<div style={{ height: "auto" }}>
							<img
								style={{
									width: isMobileOnly ? 260 : platform === 'gibobs' ? 420 : 400,
									height: isMobileOnly ? 300 : platform === 'gibobs' ? 283 : "auto",
									marginTop: 24
								}}
								src={showImage.url}
								alt="asdf"></img>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
								<CloseCircleFilled style={{ fontSize: 24, color: '#748EA0' }} onClick={() => deleteDocumentModal(imageUrl)} />
								<CheckCircleFilled style={{ fontSize: 24, color: '#748EA0' }} onClick={() => handleSecondPhoto()} />
							</div>
						</div>
					)

				)}

				{!isMobileOnly && platform !== 'gibobs' && (
					<Webcam
						audio={false}
						height={platform === 'gibobs' ? 283 : 300}
						width={platform === 'gibobs' ? 420 : 400}
						videoConstraints={videoConstraints}
						screenshotFormat="image/jpeg"
						forceScreenshotSourceSize={true}>
						{({ getScreenshot, size }) => (
							<Button
								className="button-primari-gibobs"
								type="primary"
								onClick={() => {
									let imageSrc = getScreenshot();
									imageSrc = imageSrc?.replace('data:image/jpeg;base64,', '');
									saveFileData(imageSrc, 'dni');
								}}
							>
								{t('take.photo')}
							</Button>
						)}
					</Webcam>
				)}

				{isMobileOnly && (
					<div style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
						<input
							type="file"
							accept="image/jpeg"
							capture="environment"
							style={{ display: 'none' }}
							ref={handleFileInput}
							onChange={handleImageChange}
						/>
						<Button className="button-primari-gibobs" type="primary" onClick={handleClick}>
							{t('take.photo')}
						</Button>
					</div>
				)}
			</Modal>

			<Modal
				className={isMobileOnly ? 'ModalCommonSmall' : 'ModalCommon'}
				open={modal3}
				maskClosable={false}
				style={{height: isMobileOnly ? undefined : 656}}
				
				onCancel={() => {
					setModal3(false);
					setModal2(true);
					deleteDocumentModal(imageUrl);
				}}
				onOk={() => {
					handleSecondPhoto();
				}}
				width={isMobileOnly ? 328 : 480}
				okText={<span>{t('offers.accept')}</span>}
				title={count === 0 ? t('dni.photo1') : t('dni.photo2')}
				cancelText={<span>{t('viability.repeat')}</span>}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '46%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '46%', height: 40 }
				}}
				destroyOnClose={true}
				closable={true}>
				<div style={{ marginBottom: isMobileOnly ? -10 : 10, marginTop: -16 }}>
					<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
						{t('modalDocuments3.desc')}
					</Text>
				</div>

				<div style={{ marginTop: 30 }}>
					{showImage !== undefined && (
						<div style={{ height: "auto" }}>
							<img
								style={{ width: isMobileOnly ? 260 : 400, height: isMobileOnly ? 300 : "auto" }}
								src={showImage.url}
								alt="asdf"></img>
						</div>
					)}
				</div>
			</Modal>
		</div>
	);
}

export default ModalsDni;
