export const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV || '';

let nodeEnv = env.trim();

if (nodeEnv === 'production') {
	nodeEnv = 'prod';
}

const environment = require(`./environments/env.${nodeEnv}.json`);
export default environment;
