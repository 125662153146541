import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Dropdown, Layout, Menu, Col, Row, Button, Typography } from 'antd';
import { UserOutlined, LogoutOutlined, RetweetOutlined, FlagOutlined } from '@ant-design/icons';
import Spain from '../../assets/Banderas/Spain.svg';
import Cataluna from '../../assets/Banderas/Cataluna.svg';
import EstadosUnidos from '../../assets/Banderas/EstadosUnidos.png';
import Gibobs from '../../assets/icons/Gibobs.svg';
import axios from 'axios';
import { useTranslation, withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import env from '../../environment';
import { useSelector, useDispatch } from 'react-redux';
import { callAct, changeRol } from '../../store/auth/actions';
import { logout } from '../../store/auth/actions';
import MenuComponent from '../menu/menuComponent';
import { collapseMenu } from '../../store/data/actions';
import menuMobile from '../../assets/icons/menuMobile.svg';
import topStartups from '../../assets/onboarding/bandera_top_startups.png';
import user from '../../assets/icons/user.png';

const { Header } = Layout;
const { Content, Sider } = Layout;
const { Text } = Typography;

function HeaderComponentMovile({ routesApp, npsComponent, selectedMenuItem, alertClosed }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [dataPersonal, setDataPersonal] = useState(null);
	const changeCallAct = useSelector((state) => state.auth.callAct);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const collapsed = useSelector((state) => state.data.collapseMenu);
	const changePage = useSelector((state) => state.data.changePage);
	const myRef = useRef();
	const rolUser = useSelector((state) => state.auth.roles);
	const allRoles = useSelector((state) => state.auth.allRoles);
	let temp = Array.isArray(allRoles)
		? allRoles
		: allRoles.length === 1 && allRoles[0] === 'realestate'
		? allRoles
		: allRoles.split(',');
	let arrayRoles = temp;
	const rol = useSelector((state) => state.auth.roles);
	const userToken = useSelector((state) => state.auth.token);
	const { t } = useTranslation();
	const dataPersonalOperation = () => {
		axios
			.get(`${env.api.url}/v1/user/profile`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setDataPersonal(response.data.data.profile);
				dispatch(callAct(false));
			});
	};

	useEffect(() => {
		dataPersonalOperation();
	}, [changeCallAct]);
	useEffect(() => {
		dataPersonalOperation();
	}, []);

	/*useEffect(() => {
		return navigate.listen(() => {
			myRef.current?.scrollTo(0, 0);
		});
	}, [myRef, navigate]);*/

	useEffect(() => {
		myRef.current?.scrollTo(0, 0);
	}, [changePage]);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};
	const onCollapseData = () => {
		dispatch(collapseMenu(!collapsed));
	};

	const subMenu1 = (
		<Menu>
			<Menu.Item onClick={() => changeLanguage('es')}>
				<img src={Spain} alt="SpainFlag" height="15px" style={{ marginRight: '5px' }} />
				ESPAÑOL
			</Menu.Item>
			<Menu.Item onClick={() => changeLanguage('en')}>
				<img src={EstadosUnidos} alt="EUFlag" height="15px" style={{ marginRight: '5px' }} />
				INGLES
			</Menu.Item>
			<Menu.Item onClick={() => changeLanguage('cat')}>
				<img src={Cataluna} alt="CataloniaFlag" height="15px" style={{ marginRight: '5px' }} />
				CATALAN
			</Menu.Item>
		</Menu>
	);

	const logoutUser = () => {
		dispatch(logout({ navigate }));
	};
	const createAccountFriend = () => {
		dispatch(changeRol('friend'));
	};

	const createAccountParticular = () => {
		if (allRoles !== undefined && Array.isArray(arrayRoles)) {
			let rol = arrayRoles.find((item) => item === 'realestate' || item === 'client');
			if (rol === 'realestate' || rol === 'client') {
				dispatch(changeRol('client'));
			}
		}
		if (allRoles !== undefined && !Array.isArray(arrayRoles)) {
			if (allRoles === 'realestate') {
				dispatch(changeRol('client'));
			}
		}
		if (allRoles !== undefined && Array.isArray(arrayRoles)) {
			if (allRoles === 'client' || allRoles === 'hadmin' || allRoles === 'friend') {
				dispatch(changeRol('client'));
			}
		}
	};

	const createAccountINMO = () => {
		if (allRoles !== undefined && Array.isArray(arrayRoles)) {
			let rol = arrayRoles.find((item) => item === 'client');
			if (rol === 'client') {
				dispatch(changeRol('realestate'));
			}
		}
		if (allRoles !== undefined) {
			if (rolUser === 'client') {
				dispatch(changeRol('realestate'));
			}
		}
		if (allRoles !== undefined) {
			if (rolUser === 'friend') {
				dispatch(changeRol('realestate'));
			}
		}
	};
	const subMenu2 = (
		<>
			{arrayRoles[0] === 'realestate' && rolUser === 'realestate' && (
				<>
					<Menu.Item onClick={() => createAccountParticular()}>
						<NavLink
							to={{
								pathname: `/dashboard-client`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.particular')}</Text>
						</NavLink>
					</Menu.Item>
				</>
			)}

			{arrayRoles[0] === 'realestate' && rolUser === 'client' && (
				<>
					<Menu.Item onClick={() => createAccountINMO()}>
						<NavLink
							to={{
								pathname: `/dashboard-business`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.company')}</Text>
						</NavLink>
					</Menu.Item>
				</>
			)}
			{arrayRoles[0] === 'realestate' && rolUser === 'friend' && (
				<>
					<Menu.Item onClick={() => createAccountParticular()}>
						<NavLink
							to={{
								pathname: `/dashboard-client`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.particular')}</Text>
						</NavLink>
					</Menu.Item>
					<Menu.Item onClick={() => createAccountINMO()}>
						<NavLink
							to={{
								pathname: `/dashboard-business`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.company')}</Text>
						</NavLink>
					</Menu.Item>
				</>
			)}

			{Array.isArray(arrayRoles) &&
				(arrayRoles[0] === 'hadmin' || arrayRoles[0] === 'client') &&
				(rolUser === 'hadmin' || rolUser === 'client') && (
					<Menu.Item onClick={() => createAccountFriend()}>
						<NavLink
							to={{
								pathname: `/friend`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.plain')}</Text>
						</NavLink>
					</Menu.Item>
				)}

			{Array.isArray(arrayRoles) &&
				(arrayRoles[0] === 'hadmin' || arrayRoles[0] === 'client') &&
				rolUser === 'friend' && (
					<Menu.Item onClick={() => createAccountParticular()}>
						<NavLink
							to={{
								pathname: `/dashboard-client`
							}}>
							<Text style={{ color: '#02C3CD' }}>{t('header.particular')}</Text>
						</NavLink>
					</Menu.Item>
				)}
		</>
	);

	const menu = (
		<Menu>
			{allRoles && allRoles.includes('realestate') && (
				<Menu.SubMenu
					style={{ display: 'flex' }}
					title={
						<>
							<RetweetOutlined style={{ marginTop: '5px', marginRight: 5 }} />
							{t('header.changeProfile')}
						</>
					}>
					{subMenu2}
				</Menu.SubMenu>
			)}
			<Menu.Item>
				<NavLink
					to={{
						pathname: `/my-account`,
						state: {
							dataPersonal: dataPersonal
						}
					}}>
					<UserOutlined style={{ marginRight: 5 }} />
					{t('init.account')}
				</NavLink>
			</Menu.Item>
			{/* <Menu.Item style={{ display: 'flex' }}>
				<FlagOutlined style={{ marginTop: '5px' }} />
				<Dropdown overlay={subMenu1} placement="bottomCenter">
					<Text>{t('init.languages')}</Text>
				</Dropdown>
			</Menu.Item> */}
			<Menu.SubMenu
				style={{ display: 'flex' }}
				title={
					<>
						<FlagOutlined style={{ marginTop: '5px', marginRight: 5 }} />
						{t('init.languages')}
					</>
				}>
				{subMenu1}
			</Menu.SubMenu>
			<Menu.Item danger onClick={logoutUser}>
				<LogoutOutlined style={{ marginRight: 5 }} />
				{t('init.logout')}
			</Menu.Item>
		</Menu>
	);

	const handleClickMenu = () => {
		//setShowMenu(!showMenu);
		dispatch(collapseMenu(!collapsed));
	};
	return (
		<>
			<Row>
				<Col xs={24} sm={24}>
					<Header
						className="ant-layout-header g-header"
						style={{
							zIndex: 1000,
							backgroundColor: '#FFFFFF',
							boxShadow: '0 0 2px #666',
							display: 'flex',
							justifyContent: 'space-between',
							position: 'fixed',
							width: '100%'
						}}>
						<div style={{ width: 'auto' }}>
							<div
								style={{
									marginTop: 5,
									height: 28,
									width: 28,
									textAlign: 'center'
								}}
								onClick={() => handleClickMenu()}>
								<img src={menuMobile} alt="menuMobile" height={14} style={{ marginBottom: 4 }} />
							</div>
						</div>

						<div
							style={{ position: 'relative', marginLeft: '5%' }}
							onClick={() => navigate('/dashboard-client')}>
							<img
								src={Gibobs}
								style={{
									marginTop: 3,
									height: '60px',
									alignSelf: 'center'
								}}
								alt="Gibobs"
							/>
						</div>
						<div style={{ width: 'auto', marginTop: 8 }}>
							{dataProfile && dataProfile.avatar ? (
								<Dropdown
									overlay={menu}
									placement="bottomRight"
									arrow
									trigger={['click']}
									style={{ marginTop: 8 }}>
									<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
										<Button
											style={{ border: 'none', width: 36, height: 40 }}
											icon={
												<img
													style={{
														border: 'none',
														width: 36,
														height: 36,
														borderRadius: 20
													}}
													size={36}
													src={`data:image/png;base64,${
														dataProfile && dataProfile.avatar.data
													}`}></img>
											}
										/>
									</a>
								</Dropdown>
							) : (
								<Dropdown
									overlay={menu}
									placement="bottomRight"
									arrow
									trigger={['click']}
									style={{ marginTop: 12 }}>
									<Button
										style={{ border: 'none', width: 36, height: 40 }}
										icon={
											<img
												style={{
													border: 'none',
													width: 36,
													height: 36,
													borderRadius: 20
												}}
												size={36}
												src={user}></img>
										}
									/>
								</Dropdown>
							)}
						</div>
					</Header>
					{(location.pathname === '/dashboard-client' || location.pathname === '/friend') && (
						<img
							src={topStartups}
							alt="Logo top startups"
							style={{
								position: 'absolute',
								top: alertClosed ? 64 : 175,
								right: 18,
								zIndex: 2,
								width: 'auto',
								height: 84,
								transition: 'top 0.2s ease'
							}}
						/>
					)}
				</Col>
			</Row>

			<Sider
				trigger={null}
				width={'100%'}
				collapsedWidth={64}
				collapsible
				collapsed={false}
				style={{
					backgroundColor: '#ffffff',
					// position: "absolute",
					zIndex: 20,
					minHeight: rol === 'realestate' ? 140 : 200,
					marginTop: '140px',
					display: collapsed ? undefined : 'none',
					width: '100%',
					boxShadow: collapsed && '0px 900px 0px 900px rgba(47, 72, 88, 0.5)'
				}}>
				<MenuComponent
					selectedMenuItem={selectedMenuItem}
					// style={{ minHeight: "100%" }}
					getData={onCollapseData}
					rolUser={rol}
				/>
			</Sider>

			<Content>
				<div ref={myRef} className="mobileComponentScroll">
					{npsComponent}
					{routesApp}
				</div>
			</Content>
		</>
	);
}

export default withTranslation()(HeaderComponentMovile);
