import React, { useEffect, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';
import { Form, Input, Select, Typography, Row, Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputDate, ProvinceSelector } from 'afrodita';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;

type Props = {
	setDocSigned: any;
	nameBank: any;
	dataMortgage: any;
	clickTab: any;
	form: any;
	countries: any;
	sabadellSigned?: boolean;
	setSabadellSigned: any;
};

export default function RenderSingFile({
	setDocSigned,
	nameBank,
	dataMortgage,
	clickTab,
	form,
	countries,
	sabadellSigned,
	setSabadellSigned
}: Props) {
	const [dataURL, setDataURL] = React.useState<string | null>(null);
	const padRef = React.useRef<SignatureCanvas>(null);
	const { t } = useTranslation();
	const [index, setIndex] = useState(undefined);
	const [initialValues, setInitialValues] = useState(undefined);
	const [participant, setParticipant] = useState(undefined);
	const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);

	const options = [
		{ label: 'marital.community', value: 'married_property' },
		{
			label: 'marital.separation',
			value: 'married_separation'
		},
		{ label: 'marital.Divorced', value: 'divorced' },
		{ label: 'marital.Single', value: 'single' },
		{ label: 'marital.Widower', value: 'widowed' }
	];

	useEffect(() => {
		const index2 = clickTab.charAt(clickTab.length - 1);
		setIndex(index2 - 1);
	}, []);

	useEffect(() => {
		if (dataMortgage && !isNaN(index)) {
			if (clickTab.includes('owner')) {
				setParticipant(dataMortgage?.owners[index]);
			} else {
				setParticipant(dataMortgage?.guarantors[index]);
			}
		}
	}, [index]);

	console.log('dataMortgage', dataMortgage);

	useEffect(() => {
		checkFields();
	}, [initialValues]);

	useEffect(() => {
		form.resetFields();
		if (!isNaN(index) && participant) {
			const updatedValues = {
				name: participant?.name ? participant.name : undefined,
				firstSurname: participant?.firstSurname ? participant.firstSurname : undefined,
				lastSurname: participant?.lastSurname ? participant.lastSurname : undefined,
				email: participant?.email ? participant?.email : undefined,
				nif: participant?.nif ? participant?.nif : undefined,
				phone: participant?.phone ? participant?.phone : undefined,
				address: participant?.address ? participant?.address : undefined,
				population: participant?.population ? participant?.population : undefined,
				postalCode: participant?.postalCode ? participant?.postalCode : undefined,
				birthdate: participant?.birthdate
					? moment(participant?.birthdate, 'YYYY-MM-DD').format('DD-MM-YYYY')
					: undefined,
				taxResidency: participant?.taxResidency ? participant?.taxResidency : undefined,
				birthCountry: participant?.birthCountry ? participant?.birthCountry : undefined,
				cityzenship: participant?.cityzenship ? participant?.cityzenship : undefined,
				nationality: participant?.nationality ? participant?.nationality : undefined,
				maritalStatus: participant?.maritalStatus ? participant?.maritalStatus : undefined,
				province: participant?.province ? participant.province : undefined
			};
			form.setFieldsValue(updatedValues);
			setInitialValues(updatedValues);
		}
	}, [index, participant]);

	const checkFields = () => {
		const { name, firstSurname, lastSurname } = form.getFieldsValue();
		if (name && firstSurname && lastSurname) {
			setIsCheckboxDisabled(false);
		} else {
			setIsCheckboxDisabled(true);
		}
	};

	const clear = () => {
		padRef.current?.clear();
	};

	const trim = () => {
		const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png');
		if (url) {
			setDataURL(url);
			setDocSigned(url);
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
			{initialValues && nameBank !== 'consent_caixabank' && nameBank !== 'consent_bankinter' && (
				<Form
					form={form}
					initialValues={initialValues}
					onValuesChange={checkFields}
					layout="vertical"
					style={{ width: '100%' }}>
					<Row gutter={[16, 16]} align={'middle'}>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="name"
								label={t('form.name')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.firstSurname}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.name')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="firstSurname"
								label={t('form.firstSurname')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.firstSurname}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.firstSurname')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="lastSurname"
								label={t('form.lastSurname')}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.lastSurname}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.lastSurname')}
								/>
							</Form.Item>
						</Col>
						{nameBank !== 'consent_caja_rural_sur' && nameBank !== 'consent_sabadell' && (
							<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
								<Form.Item
									className="input-sign"
									name="email"
									label={t('appraisal.emailSecure')}
									rules={[
										{ type: 'email', message: t('form.emailNotValid') },
										{ required: true, message: t('form.required2') }
									]}
									style={{ flex: '1 1 200px' }}>
									<Input
										disabled={!!participant?.email}
										style={{ width: '100%', height: 40 }}
										placeholder={t('appraisal.emailSecure')}
									/>
								</Form.Item>
							</Col>
						)}
						{nameBank !== 'consent_sabadell' && (
							<>
								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="phone"
										label={t('form.phone')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Input
											disabled={!!participant?.phone}
											style={{ width: '100%', height: 40 }}
											placeholder={t('form.phone')}
										/>
									</Form.Item>
								</Col>
								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="nif"
										label={t('form.identification')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Input
											disabled={!!participant?.nif}
											style={{ width: '100%', height: 40 }}
											placeholder={t('0000000X')}
										/>
									</Form.Item>
								</Col>
							</>
						)}
						{nameBank === 'consent_ibercaja' && (
							<>
								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										name="maritalStatus"
										label={t('form.maritalStatus')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ width: '100%', height: 40 }}>
										<Select placeholder={t('form.maritalStatus')} style={{ width: '100%' }}>
											{options.map((status) => {
												return (
													<Option key={status.value} value={status.value}>
														{t(status.label)}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Col>
								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="nationality"
										label={t('form.nationality')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Select
											showSearch
											disabled={!!participant?.nationality}
											style={{ width: '100%' }}
											placeholder={t('form.nationality')}
											filterOption={(input: any, option: any) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}>
											{countries &&
												countries.countries.map((item: any) => (
													<Option key={item.code} value={item.code}>
														{item.name_es}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>

								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="cityzenship"
										label={t('form.country')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Select
											showSearch
											disabled={!!participant?.cityzenship}
											style={{ width: '100%' }}
											placeholder={t('form.country')}
											filterOption={(input: any, option: any) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}>
											{countries &&
												countries.countries.map((item: any) => (
													<Option key={item.code} value={item.code}>
														{item.name_es}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>

								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="birthCountry"
										label={t('form.birthCountry')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Select
											showSearch
											disabled={!!participant?.birthCountry}
											style={{ width: '100%' }}
											placeholder={t('form.birthCountry')}
											filterOption={(input: any, option: any) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}>
											{countries &&
												countries.countries.map((item: any) => (
													<Option key={item.code} value={item.code}>
														{item.name_es}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>

								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="taxResidency"
										label={t('form.taxResidency')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Select
											showSearch
											disabled={!!participant?.taxResidency}
											style={{ width: '100%' }}
											placeholder={t('form.taxResidency')}
											filterOption={(input: any, option: any) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}>
											{countries &&
												countries.countries.map((item: any) => (
													<Option key={item.code} value={item.code}>
														{item.name_es}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>

								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="birthdate"
										label={t('form.birthdate')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<InputDate type="date" style={{ width: '100%', height: 40 }} />
									</Form.Item>
								</Col>
							</>
						)}
						{nameBank === 'consent_sabadell_extra' && (
							<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
								<Form.Item
									className="input-sign"
									name="birthdate"
									label={t('form.birthdate')}
									rules={[{ required: true, message: t('form.required2') }]}
									style={{ flex: '1 1 200px' }}>
									<InputDate type="date" style={{ width: '100%', height: 40 }} />
								</Form.Item>
							</Col>
						)}
						{(nameBank === 'consent_caja_rural_sur' ||
							nameBank === 'consent_abanca' ||
							nameBank === 'consent_ibercaja') && (
							<>
								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="address"
										label={t('form.address')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Input
											disabled={!!participant?.address}
											style={{ width: '100%', height: 40 }}
											placeholder={t('form.address')}
										/>
									</Form.Item>
								</Col>
								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="population"
										label={t('form.population')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Input
											disabled={!!participant?.population}
											style={{ width: '100%', height: 40 }}
											placeholder={t('form.population')}
										/>
									</Form.Item>
								</Col>
								{nameBank === 'consent_ibercaja' && (
									<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
										<Form.Item
											className="select-sign"
											name="province"
											label={t('form.provinces')}
											rules={[{ required: true, message: t('form.required2') }]}
											style={{ flex: '1 1 200px' }}>
											<ProvinceSelector
												disabled={!!participant?.province}
												placeholder={t('form.provinces')}
												style={{ width: '100%', height: 40 }}
											/>
										</Form.Item>
									</Col>
								)}
								<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
									<Form.Item
										className="input-sign"
										name="postalCode"
										label={t('m_register.postalCode')}
										rules={[{ required: true, message: t('form.required2') }]}
										style={{ flex: '1 1 200px' }}>
										<Input
											disabled={!!participant?.postalCode}
											style={{ width: '100%', height: 40 }}
											placeholder={t('m_register.postalCode')}
										/>
									</Form.Item>
								</Col>
							</>
						)}
					</Row>
				</Form>
			)}

			{initialValues && nameBank === 'consent_bankinter' && (
				<Form form={form} initialValues={initialValues} layout="vertical" style={{ width: '100%' }}>
					<Row gutter={[16, 16]} align={'middle'} style={{ marginTop: 24 }}>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="name"
								label={t('form.name')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input style={{ width: '100%', height: 40 }} placeholder={t('form.name')} />
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="email"
								label={t('appraisal.emailSecure')}
								rules={[
									{ type: 'email', message: t('form.emailNotValid') },
									{ required: true, message: t('form.required2') }
								]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.email}
									style={{ width: '100%', height: 40 }}
									placeholder={t('appraisal.emailSecure')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="phone"
								label={t('form.phone')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.phone}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.phone')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8} style={{ marginBottom: 24 }}>
							<Form.Item
								className="input-sign"
								name="nif"
								label={t('form.identification')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.nif}
									style={{ width: '100%', height: 40 }}
									placeholder={t('0000000X')}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			)}
			{nameBank !== 'consent_sabadell' ? (
				<div
					style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
					<span style={{ marginTop: 32 }}>Tu firma:</span>
					<SignaturePad
						ref={padRef}
						canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
						onEnd={trim}
					/>
					<div className="sigPreview">
						<button
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 16,
								width: 120,
								background: '#02C3CD',
								color: '#ffffff',
								border: 'none',
								padding: 4,
								borderRadius: 4
							}}
							onClick={clear}>
							Borrar
						</button>
					</div>
				</div>
			) : (
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: 16, marginBottom: 24 }}>
					<Checkbox
						disabled={isCheckboxDisabled}
						value={sabadellSigned}
						onChange={(e) => setSabadellSigned(e.target.checked)}>
						<Text style={{ fontSize: 12, fontWeight: 400, color: '#748EA0' }}>
							{t('doc.sabadellSign')}
						</Text>
					</Checkbox>
				</div>
			)}
		</div>
	);
}
