import React, { useState, useEffect } from 'react';
import {
	Layout,
	Card,
	Typography,
	Button,
	Col,
	Row,
	Tabs,
	Badge,
	Upload,
	message,
	Steps,
	Modal,
	Form,
	Input,
	Select,
	Alert,
	Switch,
	Popover,
	Radio,
	notification
} from 'antd';
import {
	InfoCircleOutlined,
	CheckOutlined,
	PlusOutlined,
	DeleteOutlined,
	DownloadOutlined,
	ExclamationOutlined,
	ExclamationCircleOutlined,
	ExclamationCircleFilled
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import LayoutProducts from '../Products/LayoutProducts';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import env from '../../environment';
import stepGif from '../../assets/step.gif';
import AfterBanks from '../../components/AfterBanks/Afterbanks';
import { saveAs } from 'file-saver';
import LoaderLogo from '../../components/LoaderLogo';
import DocumentsCertificate from './DocumentsCertifcate';
import countPin from '../../assets/Products/countPin.png';
import CountDown from 'ant-design-pro/lib/CountDown';
import { InputDate } from 'afrodita';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

var zip = require('jszip')();

const { Text } = Typography;
const { TabPane } = Tabs;
const { Step } = Steps;
const { Option } = Select;
const { confirm } = Modal;

const OperationCertificated = () => {
	const { t } = useTranslation();
	const param = useParams();
	const navigate = useNavigate();;
	const { operationId } = useParams();
	// const operations = useSelector((state) => state.auth.operations);
	// const nameOperations =
	//   operations !== undefined &&
	//   operations.find((operation) => operation.operationId === operationId);
	const [nameOperations, setNameOperations] = useState(undefined);
	const [docAutomatic, setDocAutomatic] = useState(undefined);
	const [clickTab, setClickTab] = useState('owner1');
	const [valuesDataForCertificate, setValuesDataForCertificate] = useState([]);
	const userToken = useSelector((state) => state.auth.token);
	const [valuesData, setValuesData] = useState([]);
	const [saveFileDataField, setSaveFileDataField] = useState(null);
	const [tags, setTags] = useState([]);
	const [, setOwner] = useState('');
	const activeNbric = false;
	const [visible, setVisible] = useState(activeNbric); //true para activar
	const [visible1, setVisible1] = useState(false);
	const [visible2, setVisible2] = useState(false);
	const [visible3, setVisible3] = useState(false);
	const [visible4, setVisible4] = useState(false);
	const [visible5, setVisible5] = useState(false);
	const [visible6, setVisible6] = useState(false);
	const [form] = Form.useForm();
	const [showStpper, setShowStpper] = useState(false);
	const [fileGroup, setFileGroup] = useState([]);
	const [loading, setLoading] = useState(false);
	let indexOwner = nameOperations?.operationData?.certificate?.currentOwner?.slice(-1);
	indexOwner = parseInt(indexOwner);
	let currentOwner = nameOperations && nameOperations.operationData.certificate.currentOwner;
	const [clickTabDocumentation, setClickTabDocumentation] = useState('1');
	const [documentsByOwner, setDocumentsByOwner] = useState([]);

	// const [docAutomatic, setDocAutomatic] = useState(
	//   nameOperations &&
	//     nameOperations.operationData[currentOwner].manualDoc !== undefined
	//     ? true
	//     : false
	// );

	const [detailsCertificate, setDetailsCertificate] = useState(undefined);
	const [account, setAccount] = useState(null);

	const [fileBanks3Moths, setFileBanks3Moths] = useState(undefined); //upload manual
	const [fileBanks3MothsGroups, setFileBanks3MothsGroups] = useState(undefined); //upload manual

	const [fileAfterbanks, setFileAfterbanks] = useState(undefined); //upload automatic
	
	const [alertResent, setAlertResent] = useState(false);
	const [hiddenAutorze, setHiddenAutorze] = useState(false);
	const [, setOwnerRefresh] = useState('');
	const [errorPin, setErrorPin] = useState(false);
	var myVar = undefined;
	const targetTime = new Date().getTime() + 300000;

	const [, setVisibleButtonParticipants] = useState(undefined);
	const [visibleModalDownloadOptions, setVisibleModalDownloadOptions] = useState(false);
	const [downloadOptionsRadio, setDownloadOptionsRadio] = useState(undefined);
	const [modalUpdateData, setModalUpdateData] = useState(false);

	let externalId = undefined;

	const [modalBankFlip, setModalBankFlip] = useState(false);
	const [widgetLink, setWidgetLink] = useState(undefined);
	let timerReference = undefined;
	const [completedBankFlip, setCompletedBankFlip] = useState(false);

	const uploadProps = {
		beforeUpload: (file) => {
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	let countShowMessage = 0;

	useEffect(() => {
		if (modalBankFlip && widgetLink) {
			timerReference = setInterval(() => {
				getBankFlipProcess();
			}, 2000);

			if (completedBankFlip) {
				clearInterval(timerReference);
			}
		}
	}, [widgetLink, modalBankFlip]);

	useEffect(() => {
		getGroupsDoc();
		getDocuments();
		getAutomaticDocumentation(true);
		// if (nameOperations && nameOperations.operationData) {
		if (nameOperations) {
			// setClickTab(nameOperations.operationData.certificate.currentOwner);
			setClickTab(currentOwner);
		}
		getDetailsCertificate('');
		//esto lo hago para siempre sea manual, cuando se habilite nbric hay que quitarlo

		// let owner = "owner" + indexOwner
		// saveData(owner, "manualDoc", true, false)

		////////////////////////////////////////////
	}, []);

	useEffect(() => {
		getGroupsDoc();
		getDocuments();
	}, [clickTab]);

	useEffect(() => {
		if (detailsCertificate && detailsCertificate.operationData) {
			setClickTab(detailsCertificate.operationData.certificate.currentOwner);
		}
	}, [detailsCertificate]);

	useEffect(() => {
		if (visible3) {
			myVar = setInterval(() => {
				getDocuments();
			}, 10000);

			if (fileAfterbanks) {
				myStopFunction();
			}
		}
	}, [visible3]);

	const getBankFlipProcess = () => {
		axios
			.get(
				`${env.api.url}/docs/bankflip/check-provider-reference/${widgetLink.providerReferences.id}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.data.flags.sessionCompleted) {
					setCompletedBankFlip(true);
					setModalBankFlip(false);
					clearInterval(timerReference);
					window.location.reload();
				}
			})
			.catch((error) => {});
	};

	const callToBankFlip = (id) => {
		axios
			.post(
				`${env.api.url}/docs/bankflip/create-session`,
				{
					operationId: id,
					personKey: clickTab
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setWidgetLink(response.data.data);
				setModalBankFlip(true);
			});
	};

	const handleModalDownloadOptionsOptions = () => {
		setVisibleModalDownloadOptions(false);
		if (downloadOptionsRadio === 'afterBanks') {
			setVisible6(true);
		}
		if (downloadOptionsRadio === 'clavePin') {
			callToBankFlip(operationId);
		}
	};

	const onChangeDownloadRadio = (e) => {
		setDownloadOptionsRadio(e.target.value);
	};
	const myStopFunction = () => {
		clearInterval(myVar);
	};

	const handleGetDocOficialZip = () => {
		if (account) {
			axios
				.all(
					account.map((l) =>
						axios.get(l.data.data.url, {
							responseType: 'arraybuffer'
						})
					)
				)
				.then((res) => {
					const docs = res.map((doc, i) => {
						return {
							name: account[i].data.data.name,
							base64: Buffer.from(doc.data, 'binary').toString('base64')
						};
					});
					exportZip(docs);
				});
		}
	};

	const exportZip = (newArray) => {
		newArray.map((archivo) => {
			zip.file(archivo.name, archivo.base64, { base64: true });
		});
		zip.generateAsync({ type: 'blob' }).then(function (content) {
			saveAs(content, `Gibobs-documents-oficials.zip`);
		});
		zip = require('jszip')();
	};

	if (loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					height: '100vh',
					backgroundColor: 'white'
				}}>
				<LoaderLogo />

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
						top: '45%'
					}}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#4D4D4D' }}>
						{' '}
						Procesando tu solicitud...
					</Text>
				</div>
			</div>
		);
	}

	const getAutomaticDocumentation = (nbric) => {
		axios
			.get(
				`${env.api.url}/v1/operations/${operationId}/automatic-documentation/check/${clickTab}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.valid) {
					setHiddenAutorze(false);
				}
				/* if (nameOperations.operationData[currentOwner].manualDoc !== true) {
                     setVisible(true)
                 }*/
				if (nameOperations.operationData[currentOwner].manualDoc === false && nbric) {
					setVisible(response.data.data.valid);
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getGroupsDoc = () => {
		axios
			.get(`${env.api.url}/v1/documents/request-operation-documentation/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				// response.data.data.documents.reverse()

				response.data.data.documents = _.orderBy(
					response.data.data.documents,
					[
						(d) => {
							return d.participants[clickTab];
						},
						(d) => d.description
					],
					['desc', 'asc']
				);

				response.data.data.documents.find((item) => item.name !== 'bankreader');

				setValuesDataForCertificate(response.data.data);

				setFileBanks3MothsGroups(
					response.data.data.documents.find((item) => item.name === 'bankreader')
				);

				setVisibleButtonParticipants(
					response.data.data.documents.find(
						(item) =>
							item.completed[clickTab] === false && item.participants[clickTab] === 'required'
					)
				);

				// response.data.data.documents.filter((item) => item.name !== "bankreader")

				// response.data.data.documents = _.orderBy(response.data.data.documents, [(d) => {
				//     return d.description
				// }], ["asc"])
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const handleUploadFile = (file) => {
		axios
			.put(
				`${env.api.url}/v1/documents/operation/attachment`,
				{
					operationId: operationId,
					file: file[1].file,
					fileName: file[1].fileName,
					owner: clickTab,
					type: fileGroup.name,
					tags: tags
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getDocuments();
				getGroupsDoc();

				if (countShowMessage === 0) {
					message.success(t('document.message'));
					countShowMessage = 1;
				}
			})
			.catch((error) => {
				console.log(error)
			});
		countShowMessage = 0;
	};

	const saveFileData = (file, name) => {
		const documentFile = [saveFileDataField];
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile);
	};

	const handleClickPlus = (idowner, file) => {
		setFileGroup(file);
		let array = [idowner];
		if (idowner === 'buy') {
			setTags(array);
			setOwner('owner1');
		} else {
			setOwner(idowner);
		}
	};

	const getDocuments = async () => {
		const response = await axios.get(
			`${env.api.url}/v1/documents/get-operation-documents/${operationId}`,
			{
				headers: { Authorization: `Bearer ${userToken}` }
			}
		);

		const unsorted = response.data.data;

		const sorted = unsorted
			.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
			.reverse();

		let temp = undefined;

		if (nameOperations && nameOperations.stage === 'analysis') {
			temp = sorted.filter((item) => item.owner === clickTab);
			setValuesData(temp);
		} else {
			temp = sorted.filter((item) => item.owner === currentOwner && item.type !== 'bankreader');
			setValuesData(temp);
		}

		let documentsTemp = response.data.data.filter(
			(item) => item.type !== 'nif' && item.owner === currentOwner && item.type !== 'bankreader'
		);

		setDocumentsByOwner(documentsTemp);

		let tempFileAfterbanks = [];
		tempFileAfterbanks = response.data.data.filter(
			(item) =>
				item.tags.includes('automatic') && item.type === 'bankreader' && item.owner === currentOwner
		);

		if (tempFileAfterbanks.length === 0) {
			tempFileAfterbanks = undefined;
		}
		setFileAfterbanks(tempFileAfterbanks);

		let tempFileBanks3Moths = [];
		tempFileBanks3Moths = response.data.data.filter(
			(item) => item.tags.length === 0 && item.type === 'bankreader' && item.owner === currentOwner
		);
		if (tempFileBanks3Moths.length === 0) {
			tempFileBanks3Moths = undefined;
		}
		setFileBanks3Moths(tempFileBanks3Moths);

		///////////////////////////////////////////////
		//descargar un zip con todos los documentos

		if (documentsTemp.length > 0) {
			await axios
				.all(
					documentsTemp.map((l) =>
						axios.get(`${env.api.url}/v1/documents/get-document-url/${l.id}`, {
							headers: { Authorization: `Bearer ${userToken}` }
						})
					)
				)
				.then((res) => {
					const merged = [].concat(res);
					setAccount(merged);
				});
		}
	};

	const deleteDocument = (documentId) => {
		axios
			.get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				getDocuments();
				getGroupsDoc();
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const modalDocument = (documentId) => {
		confirm({
			className: 'ModalCommonConfirm',
			title: 'Borrar documento',
			icon: null,
			content: '¿Seguro que deseas borrar este documento?',
			okText: 'Borrar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk() {
				deleteDocument(documentId);
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	const handleFileDownload = (documentId, item) => {
		axios
			.get(`${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					const link = document.createElement('a');
					link.href = response.data.data.url;
					link.setAttribute('download', `${item.name}`);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getDetailsCertificate = async () => {
		const response = await axios.get(`${env.api.url}/v1/operations/${operationId}`, {
			headers: { Authorization: `Bearer ${userToken}` }
		});

		setNameOperations(response.data.data);
		setDetailsCertificate(response.data.data);
		setOwnerRefresh(response.data.data.operationData.certificate.currentOwner);

		if (
			response.data.data.operationData[response.data.data.operationData.certificate.currentOwner]
				.manualDoc !== undefined
		) {
			setDocAutomatic(true);
		} else {
			setDocAutomatic(false);
		}
		/* if (response.data.data.operationData[currentOwner].manualDoc) {
             setVisible(false)
         }*/
	};

	const saveData = async (participant, key, value, nbric) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${operationId}`,
				{ key, participant, value },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					getDetailsCertificate(nbric);
					getGroupsDoc();
					getDocuments();
					getAutomaticDocumentation(nbric);
					setVisible4(false);
					setVisible(false);
					setClickTab(nameOperations.operationData.certificate.currentOwner);
				}
			});
	};

	const dataMultiple = async (owner, key, value, owner1, key1, value1, nbric) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
				{
					values: [
						{
							key: owner,
							subkey: key,
							value: value
						},
						{
							key: owner1,
							subkey: key1,
							value: value1
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					getDetailsCertificate(nbric);
					getGroupsDoc();
					getDocuments();
					getAutomaticDocumentation(nbric);
					setVisible4(false);
					setVisible(false);
					setClickTab(detailsCertificate.operationData.certificate.currentOwner);
				}
			});
	};

	const renderDocumentInformation = (index, type) => {
		const data = valuesData.filter((item) => {
			let documentFlag = false;

			if (item.owner === `${type}${index}`) {
				documentFlag = true;
			} else if (type === 'owner' && index === 1 && !item.owner) {
				documentFlag = true;
			}

			return documentFlag;
		});

		const files = data.map((item) => {
			if (item.type !== null && item.page !== null && item.owner) {
				return (
                    //documentacion validada

                    (<Row
						style={{
							display: 'flex',
							marginTop: 22,
							marginLeft: -10,
							width: '100%'
						}}>
                        <Col xs={2}>
							<CheckOutlined style={{ color: '#52C41A' }} />
						</Col>
                        <Col xs={19}>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
								{' '}
								{item.name.substr(0, 25)}
							</Text>
						</Col>
                        <Col xs={3} style={{ display: 'flex', width: '100%' }}>
							<div
								hidden={item.type === 'nif'}
								style={{ marginRight: 22 }}
								onClick={() => {
									modalDocument(item.id);
								}}>
								<DeleteOutlined style={{ color: '#FF4D4F' }} />
							</div>
							<div
								onClick={() => handleFileDownload(item.id, item)}
								style={{
									justifyContent: item.type === 'nif' ? 'right' : undefined,
									marginLeft: item.type === 'nif' ? 40 : 5
								}}>
								<DownloadOutlined style={{ color: '#2F4858' }} />
							</div>
						</Col>
                    </Row>)
                );
			}
		});

		const filesDoc =
			valuesDataForCertificate.documents &&
			valuesDataForCertificate.documents.map((item) => {
				if (item.completed[clickTab] === false) {
					if (item.participants[clickTab] === 'required') {
						return (
                            //documentacion requerida

                            (<Row
								style={{
									display: 'flex',
									marginTop: 22,
									marginLeft: -10,
									width: '100%'
								}}>
                                <Col xs={2}>
									<ExclamationCircleFilled style={{ color: '#2F4858' }} />
								</Col>
                                <Col xs={21}>
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
										{' '}
										{item.title}
									</Text>
								</Col>
                                <Col xs={1} style={{ display: 'flex', width: '100%' }}>
									<div
										className="PlusButton"
										style={{
											width: '14px',
											height: '14px',
											marginLeft: 10
										}}>
										<Upload multiple={true} {...uploadProps}>
											<a onClick={() => handleClickPlus(clickTab, item)} style={{ border: 'none' }}>
												<PlusOutlined style={{ color: '#02C3CD', fontSize: 15 }} />
											</a>
										</Upload>
									</div>
								</Col>
                            </Row>)
                        );
					} else if (
						detailsCertificate &&
						detailsCertificate.operationData &&
						item.participants[clickTab] === 'optional' &&
						item.name !== 'bankreader'
					) {
						if (item.completed[clickTab] === false) {
							return (
                                //documentacion opcional

                                (<Row
									style={{
										display: 'flex',
										marginTop: 22,
										marginLeft: -10,
										width: '100%'
									}}>
                                    <Col xs={2}>
										<ExclamationCircleOutlined style={{ color: '#2F4858' }} />
									</Col>
                                    <Col xs={21}>
										<Text
											style={{
												color: '#2F4858',
												fontSize: 14,
												fontWeight: 400
											}}>
											{' '}
											{item.title}
										</Text>
									</Col>
                                    <Col xs={1} style={{ display: 'flex', width: '100%' }}>
										<div
											className="PlusButton"
											style={{
												width: '14px',
												height: '14px',
												marginLeft: 10
											}}>
											<Upload multiple={true} {...uploadProps}>
												<a
													onClick={() => handleClickPlus(clickTab, item)}
													style={{ border: 'none' }}>
													<PlusOutlined style={{ color: '#02C3CD', fontSize: 15 }} />
												</a>
											</Upload>
										</div>
									</Col>
                                </Row>)
                            );
						}
					}
				}
			});
		return (
			<div style={{ marginLeft: '15px', marginBottom: 100 }}>
				{files}
				{filesDoc}
			</div>
		);
	};

	const renderTabs = (index, type) => {
		let requireFlag = false;

		if (valuesDataForCertificate.documents) {
			valuesDataForCertificate.documents.forEach((data) => {
				if (data.completed[`${type}${index}`] === false) {
					if (data.participants[`${type}${index}`] === 'required') {
						requireFlag = true;
					}
				}
			});
		}

		return (
			<TabPane
				tab={
					<span>
						{requireFlag ? notificationB() : null}

						{t(`mydocuments.participant`) + ' ' + index}
					</span>
				}
				key={`${type}${index}`}>
				<Text
					style={{
						marginTop: 24,
						color: '#2F4858',
						fontSize: 12,
						fontWeight: 400
					}}>
					{t('stages.documents').toUpperCase()}&nbsp;
					<Text
						style={{
							marginTop: 24,
							color: '#02C3CD',
							fontSize: 12,
							fontWeight: 400
						}}>
						{t('certificate.of').toUpperCase() +
							' ' +
							nameOperations.operationData[`owner${index}`].name.toUpperCase()}
					</Text>
				</Text>

				{renderDocumentInformation(index, type)}
			</TabPane>
		);
	};

	const handleChangeSwitch = (value) => {
		//setVisible(false)

		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', value);
		setDocAutomatic(value);
		getAutomaticDocumentation(true);
		getDetailsCertificate('');
	};

	const handleChangeSwitchBank = (value) => {
		setVisible(false);
		let owner = 'owner' + indexOwner;
		saveData(owner, 'afterBanks', value);
		getAutomaticDocumentation(true);
		getDetailsCertificate('');
	};

	const renderTabsBanks = (index) => {
		let requireFlag = false;
		let requireFlag2 = false;

		if (
			valuesDataForCertificate.documents &&
			detailsCertificate &&
			detailsCertificate.operationData &&
			detailsCertificate.operationData.operation.owners
		) {
			for (let index = 1; index <= detailsCertificate.operationData.operation.owners; index++) {
				valuesDataForCertificate.documents.forEach((data) => {
					if (data.completed[`owner${index}`] === false) {
						if (data.participants[`owner${index}`] === 'required') {
							requireFlag = true;
						}
					}
				});
			}
		}

		if (valuesDataForCertificate.documents) {
			valuesDataForCertificate.documents.forEach((data) => {
				if (data.completed[`owner${index}`] === false) {
					if (fileAfterbanks === undefined && fileBanks3Moths === undefined) {
						requireFlag2 = true;
					}
				}
			});
		}

		return (<>
            <TabPane
                tab={
                    <span>
                        {requireFlag ? notificationB() : null}

                        {t('Doc. oficial')}
                    </span>
                }
                key={1}
                style={{ height: 'auto' }}>
                <div style={{ display: 'flex', marginTop: 20 }}>
                    {detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].manualDoc === false &&
                        activeNbric && (
                            <div style={{ display: 'flex' }}>
                                <Text
                                    style={{
                                        marginRight: 10,
                                        color: '#2F4858',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        marginBottom: 38
                                    }}>
                                    {t('certificate.downloadSwitch')}
                                </Text>

                                <Switch defaultChecked={true} onChange={() => handleChangeSwitch(true)} />
                            </div>
                        )}

                    {detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].manualDoc &&
                        activeNbric && (
                            <div style={{ display: 'flex' }}>
                                <Text
                                    style={{
                                        marginRight: 10,
                                        marginLeft: 30,
                                        color: '#2F4858',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        marginBottom: 38
                                    }}>
                                    {t('certificate.downloadSwitchManual')}
                                </Text>

                                <Switch defaultChecked={false} onChange={() => handleChangeSwitch(false)} />
                            </div>
                        )}
                </div>

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].manualDoc && (
                        <Card
                            className="card-legend"
                            style={{
                                borderColor: '#C0F0F3',
                                borderRadius: 4,
                                background: '#EFFCFC'
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex' }}>
                                    <ExclamationCircleFilled
                                        style={{
                                            color: '#2F4858',
                                            marginRight: 16,
                                            marginTop: 2
                                        }}
                                    />
                                    <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 600 }}>
                                        {t('certificate.legend3')}{' '}
                                    </Text>
                                </div>
                                <div style={{ display: 'flex', marginTop: 8 }}>
                                    <ExclamationCircleOutlined
                                        style={{
                                            color: '#2F4858',
                                            marginRight: 16,
                                            marginTop: 2
                                        }}
                                    />
                                    <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                        {t('certificate.legend2')}{' '}
                                    </Text>
                                </div>
                                <div style={{ display: 'flex', marginTop: 8 }}>
                                    <CheckOutlined
                                        style={{
                                            color: '#52C41A',
                                            marginRight: 16,
                                            marginTop: 2
                                        }}
                                    />{' '}
                                    <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                        {t('certificate.legend1')}{' '}
                                    </Text>
                                </div>
                            </div>
                        </Card>
                    )}

                {nameOperations && nameOperations.operationData && (
                    <div style={{ marginTop: 24 }}>
                        <Text
                            style={{
                                marginTop: 24,
                                color: '#2F4858',
                                fontSize: 12,
                                fontWeight: 400
                            }}>
                            {t('stages.documents').toUpperCase()}&nbsp;
                            <Text
                                style={{
                                    marginTop: 24,
                                    color: '#02C3CD',
                                    fontSize: 12,
                                    fontWeight: 400
                                }}>
                                {t('certificate.of').toUpperCase() +
                                    ' ' +
                                    nameOperations.operationData[currentOwner].name.toUpperCase()}
                            </Text>
                        </Text>
                    </div>
                )}
                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted === undefined &&
                    (detailsCertificate.operationData[currentOwner].manualDoc === undefined ||
                        detailsCertificate.operationData[currentOwner].manualDoc === false) &&
                    currentOwner !== 'owner1' && (
                        <Text
                            style={{
                                marginRight: 10,
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginBottom: 38
                            }}>
                            {t('certificate.downloadParticipants2')}
                        </Text>
                    )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    currentOwner === 'owner1' &&
                    !detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted && (
                        <Text
                            style={{
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginTop: 8
                            }}>
                            {t('certificate.infoNbricDesc')}
                        </Text>
                    )}

                {docAutomatic &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    (detailsCertificate.operationData[currentOwner].manualDoc ||
                        detailsCertificate.operationData[currentOwner].manualDoc === undefined) &&
                    renderDocumentInformation(indexOwner, 'owner')}

                {account !== null &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    detailsCertificate.operationData[currentOwner].manualDoc !== undefined &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted && (
                        <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                            <Col xs={2}>
                                <CheckOutlined style={{ color: '#52C41A' }} />
                            </Col>
                            <Col xs={20}>
                                <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {' '}
                                    {t('certificate.docOficialZip')}
                                </Text>
                            </Col>
                            <Col xs={2} style={{ display: 'flex', width: '100%' }}>
                                <div onClick={() => handleGetDocOficialZip()}>
                                    <DownloadOutlined
                                        style={{
                                            marginRight: 22,
                                            color: '#2F4858',
                                            fontSize: 14
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}

                {account !== null &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted &&
                    currentOwner !== 'owner1' && (
                        <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                            <Col xs={2}>
                                <CheckOutlined style={{ color: '#52C41A' }} />
                            </Col>
                            <Col xs={20}>
                                <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {' '}
                                    {t('certificate.docOficialZip')}
                                </Text>
                            </Col>
                            <Col xs={2} style={{ display: 'flex', width: '100%' }}>
                                <div onClick={() => handleGetDocOficialZip()}>
                                    <DownloadOutlined
                                        style={{
                                            marginRight: 22,
                                            color: '#2F4858',
                                            fontSize: 14
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}

                {account === null &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].manualDoc &&
                    detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted && (
                        //mostrar hasta que me diga el back que ya estan listos los docs
                        (<div style={{ marginTop: 8, display: 'flex', marginBottom: 200 }}>
                            <CheckOutlined style={{ color: '#52C41A', marginRight: 10, marginTop: 15 }} />
                            <Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
                                {t('certificate.docOficialDesc')}
                            </Text>
                        </div>)
                    )}
                {/*!docAutomatic &&
                    <div style={{ marginTop: 8 }}>
                        <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 400 }}>{t("certificate.docDniValidated")}</Text>
                </div>*/}
            </TabPane>
            <TabPane
                tab={
                    <span>
                        {requireFlag2 ? notificationB() : null}

                        {t('Doc. bancaria')}
                    </span>
                }
                key={2}
                style={{ marginBottom: 100 }}>
                {((detailsCertificate &&
                    detailsCertificate.operationData &&
                    (!detailsCertificate.operationData[currentOwner].afterBanksConnect ||
                        detailsCertificate.operationData[currentOwner].afterBanksConnect === undefined) &&
                    fileAfterbanks === undefined &&
                    !detailsCertificate.operationData[currentOwner].afterBanks) ||
                    (detailsCertificate &&
                        detailsCertificate.operationData &&
                        !detailsCertificate.operationData[currentOwner].afterBanks)) && (
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Text
                            style={{
                                marginLeft: 20,
                                marginRight: 10,
                                color: '#2F4858',
                                fontSize: 14,
                                fontWeight: 400,
                                marginBottom: 38
                            }}>
                            {t('certificate.downloadSwitch')}
                        </Text>

                        <Switch defaultChecked={true} onChange={() => handleChangeSwitchBank(true)} />
                    </div>
                )}

                {((detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanks &&
                    fileAfterbanks === undefined) ||
                    (detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].afterBanks)) && (
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Text
                            style={{
                                marginRight: 10,
                                marginLeft: 30,
                                color: '#2F4858',
                                fontSize: 14,
                                fontWeight: 400,
                                marginBottom: 38
                            }}>
                            {t('certificate.downloadSwitchManual')}
                        </Text>

                        <Switch defaultChecked={false} onChange={() => handleChangeSwitchBank(false)} />
                    </div>
                )}

                {((detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanks &&
                    fileAfterbanks === undefined) ||
                    (detailsCertificate &&
                        detailsCertificate.operationData &&
                        detailsCertificate.operationData[currentOwner].afterBanks)) && (
                    <Card
                        className="card-legend"
                        style={{
                            borderColor: '#C0F0F3',
                            borderRadius: 4,
                            background: '#EFFCFC'
                        }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>
                                <ExclamationOutlined
                                    style={{ color: '#2F4858', marginRight: 16, marginTop: 2 }}
                                />
                                <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 600 }}>
                                    {t('certificate.legend3')}{' '}
                                </Text>
                            </div>
                            <div style={{ display: 'flex', marginTop: 8 }}>
                                <ExclamationCircleOutlined
                                    style={{ color: '#2F4858', marginRight: 16, marginTop: 2 }}
                                />
                                <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {t('certificate.legend2')}{' '}
                                </Text>
                            </div>
                            <div style={{ display: 'flex', marginTop: 8 }}>
                                <CheckOutlined style={{ color: '#52C41A', marginRight: 16, marginTop: 2 }} />{' '}
                                <Text style={{ olor: '#2F4858', fontSize: 14, fontWeight: 400 }}>
                                    {t('certificate.legend1')}{' '}
                                </Text>
                            </div>
                        </div>
                    </Card>
                )}

                {nameOperations && nameOperations.operationData && (
                    <div style={{ marginTop: 24 }}>
                        <Text
                            style={{
                                marginTop: 24,
                                color: '#2F4858',
                                fontSize: 12,
                                fontWeight: 400
                            }}>
                            {t('stages.documents').toUpperCase()}&nbsp;
                            <Text
                                style={{
                                    marginTop: 24,
                                    color: '#02C3CD',
                                    fontSize: 12,
                                    fontWeight: 400
                                }}>
                                {t('certificate.of').toUpperCase() +
                                    ' ' +
                                    nameOperations.operationData[currentOwner].name !==
                                    undefined && nameOperations.operationData[currentOwner].name.toUpperCase()}
                            </Text>
                        </Text>
                    </div>
                )}

                {clickTabDocumentation === '2' && (
                    <Row style={{ display: 'flex', marginTop: 0, width: '100%' }}>
                        <Text
                            style={{
                                color: '#748EA0',
                                fontSize: 14,
                                fontWeight: 400,
                                marginTop: 8
                            }}>
                            {t('certificate.downloadTextDesc')}
                        </Text>
                    </Row>
                )}

                {/*(detailsCertificate && detailsCertificate.operationData && fileBanks3Moths === undefined) &&
                    (detailsCertificate.operationData[currentOwner].afterBanksConnect === false ||
                        detailsCertificate.operationData[currentOwner].afterBanksConnect === undefined) &&
                    (detailsCertificate.operationData[currentOwner].afterBanks === false || detailsCertificate.operationData[currentOwner].afterBanks === undefined) &&
                    indexOwner !== 1 &&

                    <><br /><Text style={{ marginRight: 10, color: "#748EA0", fontSize: 14, fontWeight: 400, marginBottom: 38 }}>{t("certificate.downloadParticipants2")}</Text></>

                    */}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanksConnect &&
                    documentsByOwner.length === 0 &&
                    fileAfterbanks !== undefined &&
                    fileAfterbanks.owner === currentOwner && (
                        <Row style={{ display: 'flex', marginTop: 0, width: '100%' }}>
                            <Text
                                style={{
                                    color: '#748EA0',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginTop: 8
                                }}>
                                {t('certificate.downloadTextDescDownload')}
                            </Text>
                        </Row>
                    )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].afterBanks &&
                    documentsByOwner.length === 0 &&
                    fileAfterbanks === undefined &&
                    detailsCertificate.operationData[currentOwner].afterBanksConnect && (
                        <Row style={{ display: 'flex', marginTop: 0, width: '100%' }}>
                            <Text
                                style={{
                                    color: '#748EA0',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginTop: 8
                                }}>
                                {t('certificate.downloadTextDescDownload2')}
                            </Text>
                        </Row>
                    )}

                {fileAfterbanks &&
                    fileAfterbanks.map((item) => {
                        if (item.owner === currentOwner) {
                            return (
                                <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                                    <Col xs={2}>
                                        <CheckOutlined style={{ color: '#52C41A' }} />
                                    </Col>
                                    <Col xs={20}>
                                        <Text
                                            style={{
                                                color: '#2F4858',
                                                fontSize: 14,
                                                fontWeight: 400
                                            }}>
                                            {' '}
                                            {item.name.substr(0, 30) + '...'}
                                        </Text>
                                    </Col>
                                    <Col xs={2} style={{ display: 'flex', width: '100%' }}>
                                        <div onClick={() => handleFileDownload(item.id, item)}>
                                            <DownloadOutlined
                                                style={{
                                                    marginRight: 22,
                                                    color: '#2F4858',
                                                    fontSize: 14
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            );
                        }
                    })}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    (!detailsCertificate.operationData[currentOwner].afterBanksConnect ||
                        detailsCertificate.operationData[currentOwner].afterBanksConnect === undefined) &&
                    fileBanks3Moths &&
                    fileBanks3Moths.map((item) => {
                        return (
                            <Row style={{ display: 'flex', marginTop: 22, width: '100%' }}>
                                <Col xs={2}>
                                    <CheckOutlined style={{ color: '#52C41A' }} />
                                </Col>
                                <Col xs={19}>
                                    <Text
                                        style={{
                                            color: '#2F4858',
                                            fontSize: 14,
                                            fontWeight: 400
                                        }}>
                                        {' '}
                                        {t('certificate.uploadBankExtract')}
                                    </Text>
                                </Col>
                                <Col xs={3} style={{ display: 'flex', width: '100%' }}>
                                    <div onClick={() => handleFileDownload(item.id, item)}>
                                        <DownloadOutlined style={{ marginRight: 10, color: '#2F4858' }} />
                                    </div>
                                    <div
                                        onClick={() => {
                                            modalDocument(item.id);
                                        }}>
                                        <DeleteOutlined style={{ color: '#FF4D4F' }} />
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}

                {clickTabDocumentation === '2' &&
                    detailsCertificate &&
                    detailsCertificate.operationData &&
                    !detailsCertificate.operationData[currentOwner].afterBanks &&
                    (fileAfterbanks || fileBanks3Moths) && (
                        <Row
                            style={{ display: 'flex', marginTop: 22, width: '100%' }}
                            onClick={() => authorizeAfterBank()}>
                            <Col xs={2}>
                                <PlusOutlined style={{ color: '#02C3CD' }} />
                            </Col>
                            <Col xs={22}>
                                <Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
                                    {' '}
                                    {t('certificate.bankDowload')}
                                </Text>
                            </Col>
                        </Row>
                    )}

                {detailsCertificate &&
                    detailsCertificate.operationData &&
                    detailsCertificate.operationData[currentOwner].afterBanks && (
                        <Row
                            style={{
                                display: 'flex',
                                marginTop: 22,
                                width: '100%',
                                background: 'white'
                            }}>
                            <Col xs={2}>
                                {fileAfterbanks || fileBanks3Moths ? (
                                    <ExclamationCircleOutlined style={{ color: '#2F4858' }} />
                                ) : (
                                    <ExclamationOutlined style={{ color: '#2F4858' }} />
                                )}
                            </Col>
                            <Col xs={20}>
                                <Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
                                    {' '}
                                    {fileBanks3Moths
                                        ? t('certificate.addUploadBankExtract')
                                        : t('certificate.uploadBankExtract')}
                                </Text>
                            </Col>
                            <Col xs={2} style={{ display: 'flex', width: '100%', marginTop: -5 }}>
                                <div
                                    className="PlusButton"
                                    style={{
                                        width: '14px',
                                        height: '14px',
                                        width: '100%',
                                        textAlign: 'right'
                                    }}>
                                    <Upload multiple={true} {...uploadProps}>
                                        <a
                                            onClick={() => handleClickPlus(clickTab, fileBanks3MothsGroups)}
                                            style={{ border: 'none' }}>
                                            <PlusOutlined style={{ color: '#02C3CD', fontSize: 15 }} />
                                        </a>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                    )}
            </TabPane>
        </>);
	};
	//aqui termina

	const notificationB = () => {
		return (
			<div style={{ float: 'right', marginTop: '1px', marginRight: '-5px' }}>
				<Badge
					size="small"
					dot={true}
					style={{
						float: 'right',
						width: '4px',
						height: '6px',
						marginLeft: '4px'
					}}></Badge>
			</div>
		);
	};

	const documentTabs = [];

	if (
		detailsCertificate &&
		detailsCertificate.operationData &&
		detailsCertificate.operationData.operation
	) {
		for (let i = 1; i <= detailsCertificate.operationData.operation.owners; i++) {
			documentTabs.push(renderTabs(i, 'owner'));
		}
	}

	const documentTabsBanks = [];

	documentTabsBanks.push(renderTabsBanks(1));
	


	const handleClickModal0 = () => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', false, false);
		nbricRequest();
		setVisible(false);
		//setVisible1(true);
	};

	const nbricRequest = () => {
		setLoading(true);
		axios
			.post(
				`${env.api.url}/v1/documents/automatic/request`,
				{
					operationId: operationId,
					checkService: true,
					checkCertificate: true
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.success) {
					setLoading(false);
					setVisible(false);
					setVisible1(true);
				}
			})
			.catch(() => {
				setLoading(false);
				setModalUpdateData(true);

				notification.error({
					message: 'Algun dato de su usuario esta mal',
					description: 'Su usuario tiene algún dato mal, consulte con su inmobiliaria'
				});
			});
	};

	const handleClickModal1 = (values) => {
		setLoading(true);
		pinRequests(values);
	};

	const checkPin = (id) => {
		axios
			.get(
				`${env.api.url}/v1/nbric/check-otp/${id}`,

				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.status === true || response.data.data.status === false) {
					if (response.data.data.status) {
						setLoading(false);
						setVisible1(false);
						saveData(clickTab, 'automaticDocOTPCompleted', true);
						setVisible2(true);
					} else {
						setErrorPin(true);
						setLoading(false);
						// esto ponerlo desde el principio
						setModalUpdateData(true);
					}
				} else {
					setTimeout(() => {
						checkPin(externalId);
					}, 1500);
				}
			})
			.catch((error) => {
				console.log(error); //fixme
			});
	};

	const pinRequests = (values) => {
		axios
			.post(
				`${env.api.url}/v1/documents/nbric/confirm/otp`,
				{
					operationId: operationId,
					owner: clickTab,
					otp: values.pin
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				externalId = response.data.data.externalId;
				checkPin(response.data.data.externalId);
			})
			.catch(() => {
				setErrorPin(true);
				setLoading(false);
			});
	};

	const handleClickModal0Cancel = () => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', true, false);
		setVisible(false);
		setDocAutomatic(true);
	};

	const handleafterBanks = () => {
		setVisible3(false);
		setVisible6(!visible6);
	};

	const handleafterBanksCancel = (value) => {
		setVisible3(value);
		setVisible6(value);
	};

	const authorizeAfterBank = () => {
		setVisible6(true);
	};

	const handleClickConfirmedDoc = () => {
		saveData(currentOwner, 'confirmedDocumentation', true, false);
		setVisible5(false);
		navigate(`/operations/certificate/${operationId}/analysis`);
	};

	const handleNextParticipant = async () => {
		let temp = indexOwner + 1;
		let owner = 'owner' + temp;

		await dataMultiple(
			currentOwner,
			'confirmedDocumentation',
			true,
			'certificate',
			'currentOwner',
			owner,
			false
		);

		setVisible4(false);
		window.location.reload();
	};

	
	const handleClickModal1cancel = (value) => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', true, false);
		setVisible1(value);
		setDocAutomatic(true);
	};

	const handleClickModal2Cancel = () => {
		let owner = 'owner' + indexOwner;
		saveData(owner, 'afterBanksConnect', false, false);
		setVisible2(false);
		setVisible1(false);
	};

	const handleClickModal2OnOk = () => {
		let owner = 'owner' + indexOwner;

		dataMultiple(owner, 'afterBanks', true, owner, 'afterBanksConnect', true, true);

		setVisible3(!visible3);
		setVisible2(!visible2);
	};

	const handleClickModal2OnOk6 = () => {
		let owner = 'owner' + indexOwner;
		dataMultiple(owner, 'afterBanks', true, owner, 'afterBanksConnect', true, true);

		setVisible6(false);
		setVisible3(!visible3);
	};


	const handleClickVisible6Cancel = () => {
		setVisible6(false);
		setVisible3(false);
	};

	const closeUpdateData = () => {
		setModalUpdateData(false);
		//setVisible(true);

		let owner = 'owner' + indexOwner;
		saveData(owner, 'manualDoc', false, false);
		nbricRequest();
	};

	const sendUpdateDataDNI = (owner, subkey, value) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
				{
					values: [
						{
							key: owner,
							subkey: subkey,
							value:
								subkey === 'birthdate' || subkey === 'nifDueDate'
									? dayjs(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
									: value
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				console.log(response)
			});
	};
	return (
        (<Layout style={{ padding: 16, marginTop: -25, marginBottom: 180 }}>
            <LayoutProducts name={nameOperations && nameOperations.subname} />
            {param.stage === 'analysis' && (
				<>
					<Card className="gcardMovile">
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ width: '100%' }}>
								<Text className="title-card-mobile">{t('stages.analysis')}</Text>
								<div onClick={() => setShowStpper(!showStpper)}>
									{' '}
									<InfoCircleOutlined
										style={{
											color: '#02C3CD',
											float: 'right',
											fontSize: 18,
											marginTop: -22
										}}
									/>{' '}
								</div>
							</div>
							<Text className="description-card-grey" style={{ marginTop: 8 }}>
								{t('certificate.descriptionAnalysis')}
							</Text>
						</div>
					</Card>
					<div className="gb-tabs-mobile">
						<DocumentsCertificate
							setClickTab={setClickTab}
							clickTab={clickTab}
							downloadOptionsRadio={() => setVisibleModalDownloadOptions(true)}
							setValuesDataForCertificate={setValuesDataForCertificate}
							valuesDataForCertificate={valuesDataForCertificate}
						/>
					</div>
				</>
			)}
            {param.stage === 'documents' && (
				<>
					{alertResent && activeNbric && (
						<div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
							<Alert
								message={t('alert.certificaedReSent')}
								type="success"
								showIcon
								closable
								style={{ zIndex: 1000, background: '#DFF4D4' }}
							/>
						</div>
					)}
					{nameOperations &&
						nameOperations.operationData &&
						nameOperations.operationData[`${clickTab}`].automaticDocOTPCompleted &&
						activeNbric && (
							<div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
								<Alert
									message={t('alert.textDownload')}
									type="success"
									showIcon
									closable
									style={{ width: 350, zIndex: 1000, background: '#DFF4D4' }}
								/>
							</div>
						)}
					{errorPin && activeNbric && (
						<div style={{ alignSelf: 'center', position: 'absolute', top: 20 }}>
							<Alert
								message={
									<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
										{t('alert.pinError')}
									</Text>
								}
								type="error"
								closable
								showIcon
								style={{ zIndex: 1000, background: '#FFDEDE', width: '100%' }}
							/>
						</div>
					)}
					<Card className="gcardMovile">
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ width: '100%' }}>
								<Text className="title-card-mobile">{t('stages.documents')}</Text>
								<div onClick={() => setShowStpper(!showStpper)}>
									{' '}
									<InfoCircleOutlined
										style={{
											color: '#02C3CD',
											float: 'right',
											fontSize: 18,
											marginTop: -22
										}}
									/>{' '}
								</div>
							</div>

							<Row style={{ display: 'flex', marginTop: 8 }}>
								<Col lg={16} md={14} sm={14} xs={24} style={{ width: '100%' }}>
									<Text className="description-card-grey" style={{ marginTop: 8 }}>
										{t('certificate.descriptionDocuments')}
									</Text>
								</Col>
							</Row>
						</div>
					</Card>
					<div className="gb-tabs-mobile">
						<DocumentsCertificate
							setClickTab={setClickTab}
							clickTab={clickTab}
							downloadOptionsRadio={() => setVisibleModalDownloadOptions(true)}
							setValuesDataForCertificate={setValuesDataForCertificate}
							valuesDataForCertificate={valuesDataForCertificate}
						/>
						{/* <Tabs
              type="card"
              style={{
                color: "#2F4858",
                fontStyle: "normal",
                fontWeight: "normal",
                marginTop: 24,
                backgroundColor: "white",
              }}
              activeKey={tabContinue}
              onChange={(values) => handleSetTabContinue(values)}
            >
              {documentTabsBanks}
            </Tabs> */}
					</div>

					{detailsCertificate &&
						detailsCertificate.operationData &&
						detailsCertificate.operationData[`${clickTab}`].automaticDocOTPCompleted ===
							undefined &&
						currentOwner === 'owner1' &&
						!hiddenAutorze && (
							<Modal
								className="ModalMobileFooterColum"
								okButtonProps={{ className: 'button-primari-gibobs' }}
								okText={t('viability.continue')}
								cancelText={
									<Text style={{ color: '#02C3CD', fontSize: 12, fontWeight: 500 }}>
										{t('certificated.modal1mobileDoc')}
									</Text>
								}
								open={visible}
								title={<Text>{t('certificate.ModalVisible1Titlte')}</Text>}
								width={328}
								cancelButtonProps={{ style: { border: 'none' } }}
								closable={false}
								onOk={() => handleClickModal0()}
								onCancel={() => handleClickModal0Cancel()}>
								<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
									{t('certificate.ModalVisible1Desc')}
								</Text>
							</Modal>
						)}

					{param.stage === 'documents' && (
						<Modal
							className="modal-documents-movile"
							width={328}
							open={showStpper}
							closable={true}
							onCancel={() => {
								setShowStpper(!showStpper);
							}}
							footer={false}
							title={
								<Text
									style={{
										fontSize: 16,
										fontWeight: 600,
										color: '#2F4858',
										marginTop: 24
										//marginBottom: 54
									}}>
									{t('document.modatTitlteSteper')}
								</Text>
							}>
							<Row>
								<Col lg={24} xs={24}>
									<Steps
										className="StepsDocumentsMovile"
										direction="vertical"
										size="small"
										status="error"
										icon="none"
										current={0}>
										<Step
											icon={
												<img
													alt="loading"
													src={stepGif}
													width="24px"
													height="24px"
													style={{ marginLeft: -5, marginTop: -10 }}></img>
											}
											status={'process'}
											title={
												<Text
													style={{
														fontSize: 16,
														fontWeight: 400,
														color: '#02C3CD'
													}}>
													{t('document.documentation')}{' '}
												</Text>
											}
											description={
												<Text
													style={{
														fontSize: 14,
														fontWeight: 400,
														color: '#748EA0',
														position: 'relative',
														left: 0,
														top: 0
													}}>
													{t('m_temporalLine.documents')}
												</Text>
											}
										/>

										<Step
											style={{ marginBottom: 28 }}
											title={
												<Text
													style={{
														fontSize: 16,
														fontWeight: 400,
														color: '#C0DAEE'
													}}>
													{t('stages.analysisStep')}{' '}
												</Text>
											}
											description={
												<Text
													style={{
														fontSize: 14,
														fontWeight: 400,
														color: '#C0DAEE',
														position: 'relative',
														left: 0,
														top: 0
													}}>
													{t('m_temporalLine.analysis')}
												</Text>
											}
										/>
									</Steps>
								</Col>
							</Row>
						</Modal>
					)}

					{detailsCertificate &&
						detailsCertificate.operationData &&
						indexOwner === detailsCertificate.operationData.operation.owners &&
						(account || valuesData) &&
						valuesDataForCertificate &&
						(fileAfterbanks || fileBanks3Moths) && (
							<Card className="cardButtonStyle" style={{ left: 0, marginBottom: -2 }}>
								<Button
									className="button-primari-gibobs"
									type="primary"
									style={{
										fontSize: '14px',
										width: '100%',
										height: 40,
										fontWeight: 600
									}}
									onClick={() => setVisible5(true)}
									disabled={
										detailsCertificate &&
										detailsCertificate.operationData &&
										!detailsCertificate.operationData[currentOwner].automaticDocOTPCompleted &&
										valuesDataForCertificate &&
										valuesDataForCertificate.documentsCompleted !==
											valuesDataForCertificate.documentsNeeded
									}>
									{t('certificatedModal.documentationTitle')}
								</Button>
							</Card>
						)}
				</>
			)}
            {param.stage === 'analisys' && (
				<Modal
					className="modal-documents-movile"
					width={328}
					open={showStpper}
					closable={true}
					onCancel={() => {
						setShowStpper(!showStpper);
					}}
					footer={false}
					title={
						<Text
							style={{
								fontSize: 16,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: 24,
								marginBottom: -20
							}}>
							{t('document.modatTitlteSteper')}
						</Text>
					}>
					<Row>
						<Col lg={24} xs={24}>
							<Steps
								className="StepsDocumentsMovile"
								direction="vertical"
								size="small"
								status="error"
								icon="none"
								current={0}>
								<Step
									title={
										<Text
											style={{
												fontSize: 16,
												fontWeight: 400,
												color: '#C0DAEE'
											}}>
											{t('document.documentation')}{' '}
										</Text>
									}
									description={
										<Text
											style={{
												fontSize: 14,
												fontWeight: 400,
												color: '#C0DAEE',
												position: 'relative',
												left: 50,
												top: -30
											}}>
											{t('m_temporalLine.documents')}
										</Text>
									}
								/>

								<Step
									status={'process'}
									icon={
										<img
											alt="loading"
											src={stepGif}
											width="24px"
											height="24px"
											style={{ marginLeft: -5, marginTop: -10 }}></img>
									}
									style={{ marginBottom: 28 }}
									title={
										<Text
											style={{
												fontSize: 16,
												fontWeight: 400,
												color: '#02C3CD'
											}}>
											{t('stages.analysisStep')}{' '}
										</Text>
									}
									description={
										<Text
											style={{
												fontSize: 14,
												fontWeight: 400,
												color: '#748EA0',
												position: 'relative',
												left: 50,
												top: -30
											}}>
											{t('m_temporalLine.analysis')}
										</Text>
									}
								/>
							</Steps>
						</Col>
					</Row>
				</Modal>
			)}
            {nameOperations && nameOperations.operationData && (
				<Modal
					className="ModalMobileCert"
					okButtonProps={{ style: { width: '100%', height: 40 } }}
					okText={t('viability.continue')}
					cancelText={
						<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
							{t('offers.cancel')}
						</Text>
					}
					open={visible1}
					title={<Text>{t('nbric.modal1titlte')}</Text>}
					width={328}
					cancelButtonProps={{ style: { width: '100%', height: 40 } }}
					onOk={() => {
						form
							.validateFields()
							.then((values) => {
								handleClickModal1(values);
								form.resetFields();
							})
							.catch((info) => {
								console.log('Validate Failed:', info);
							});
					}}
					onCancel={() => handleClickModal1cancel(false)}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginTop: -34,
							marginBottom: -20
						}}>
						<div
							style={{
								textAlign: 'center',
								display: 'flex',
								flexDirection: 'column'
							}}>
							<img
								src={countPin}
								alt="asdf"
								style={{ width: 176, heigh: 176, alignSelf: 'center' }}></img>

							<CountDown
								style={{ fontSize: 22, color: '#02C3CD', fontWeight: 600 }}
								target={targetTime}
							/>
						</div>

						<Text
							style={{
								color: '#748EA0',
								fontSize: 14,
								fontWeight: 400,
								marginTop: 20
							}}>
							{t('nbric.modalPinDescTime')}
						</Text>
						<Text
							style={{
								color: '#748EA0',
								fontSize: 14,
								fontWeight: 400,
								marginTop: 16
							}}>
							{t('nbric.documentsDecPinMobile')}
						</Text>

						<Form form={form} layout="vertical" name="pin" style={{ marginTop: 16 }}>
							<Row gutter={24}>
								<Col lg={24} xs={24}>
									<Form.Item name="pin" label={''} style={{ width: '100%' }}>
										<Input
											placeholder={t('certificated.modalPin')}
											style={{ width: '100%', height: 40 }}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</div>
				</Modal>
			)}
            <Modal
				className="ModalMobileCert"
				okButtonProps={{ style: { width: '100%' } }}
				okText={t('viability.continue')}
				cancelText={
					<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
						{t('modal.cancelJump')}
					</Text>
				}
				open={visible2}
				title={<Text>{t('certificated.modal3mobileTitle1')}</Text>}
				width={328}
				cancelButtonProps={{ style: { width: '100%' } }}
				onOk={() => {
					handleClickModal2OnOk();
				}}
				onCancel={() => handleClickModal2Cancel()}>
				<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
					{t('certificated.modal3mobileDesc1')}
				</Text>
			</Modal>
            <Modal
				className="ModalMobileCert"
				okButtonProps={{ style: { width: '100%' } }}
				okText={t('viability.continue')}
				cancelText={
					<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
						{t('offers.cancel')}
					</Text>
				}
				open={visible4}
				title={<Text>{t('modal.participantsMobileTitle')}</Text>}
				width={328}
				cancelButtonProps={{ style: { width: '100%' } }}
				onOk={() => handleNextParticipant()}
				onCancel={() => {
					setVisible4(false);
				}}>
				<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
					{t('modal.participantsMobileDes')}
				</Text>
			</Modal>
            <Modal
				className="ModalMobileCert"
				okButtonProps={{ style: { width: '100%' } }}
				okText={t('viability.continue')}
				cancelText={
					<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
						{t('offers.cancel')}
					</Text>
				}
				open={visible5}
				title={<Text>{t('certificatedModal.documentationTitle')}</Text>}
				width={328}
				cancelButtonProps={{ style: { width: '100%' } }}
				onOk={() => handleClickConfirmedDoc()}
				onCancel={() => {
					setVisible5(false);
				}}>
				<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
					{t('certificatedModal.documentationDesc')}
				</Text>
			</Modal>
            <Modal
				className="ModalMobileCert"
				okButtonProps={{ style: { width: '100%' } }}
				okText={t('viability.continue')}
				cancelText={
					<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
						{t('offers.cancel')}
					</Text>
				}
				open={visible6}
				title={<Text>{t('certificated.modal3mobileTitle1')}</Text>}
				width={328}
				cancelButtonProps={{ style: { width: '100%' } }}
				onOk={() => {
					handleClickModal2OnOk6();
				}}
				onCancel={() => handleClickVisible6Cancel()}>
				<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
					{t('certificated.modal6mobileDesc1')}
				</Text>
			</Modal>
            <Modal
				className="modalMobileAfterBanks"
				open={visible3}
				//  cancelText={<Text style={{ color: "#02C3CD", fontSize: 12, fontWeight: 500 }}>{t("offers.cancel")}</Text>}
				onCancel={() => handleafterBanksCancel(false)}
				onOk={() => handleafterBanks()}
				okButtonProps={{ disabled: fileAfterbanks ? false : true }}
				cancelButtonProps={{
					style: { border: 'none', contentVisibility: 'hidden' }
				}}
				destroyOnClose={true}
				closable={true}>
				<AfterBanks owner={clickTab}></AfterBanks>
			</Modal>
            <Modal
				className="ModalDesktopCert"
				okButtonProps={{ style: { width: '100%' } }}
				okText={t('viability.continue')}
				cancelText={
					<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
						{t('offers.cancel')}
					</Text>
				}
				open={visibleModalDownloadOptions}
				title={t('certificated.automaticDownload')}
				width={480}
				cancelButtonProps={{ style: { width: '100%' } }}
				onOk={() => {
					handleModalDownloadOptionsOptions(false);
				}}
				onCancel={() => setVisibleModalDownloadOptions(false)}>
				<Text
					style={{
						color: '#748EA0',
						fontSize: 14,
						fontWeight: 400,
						marginTop: 8
					}}>
					{t('certificated.selectOptionDownload')}
					<Radio.Group
						name="radiogroup"
						style={{
							marginTop: 16,
							fontSize: 14,
							color: '#748EA0',
							fontWeight: 400
						}}
						onChange={(e) => onChangeDownloadRadio(e)}>
						<Radio
							value={'clavePin'}
							style={{
								color: '#748EA0'
							}}>
							{t('nbric.radio1')}
						</Radio>
						<Radio
							value={'afterBanks'}
							style={{
								color: '#748EA0',
								marginTop: 16
							}}>
							{t('myDocuments.getMyBankStatements')}
						</Radio>
					</Radio.Group>
				</Text>
			</Modal>
            <Modal
				className="ModalDesktopCert"
				okButtonProps={{ style: { width: '100%' } }}
				okText={t('viability.continue')}
				cancelText={
					<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 500 }}>
						{t('offers.cancel')}
					</Text>
				}
				open={modalUpdateData}
				title={t('certificated.UpdateData')}
				width={480}
				cancelButtonProps={{ style: { width: '100%' } }}
				onOk={() => {
					closeUpdateData();
				}}
				onCancel={() => setModalUpdateData(false)}>
				{detailsCertificate && (
					<Form
						form={form}
						layout="vertical"
						className="gb-form-profile-headlines-mobile"
						name="lonbrican"
						initialValues={{
							birthdate: dayjs(detailsCertificate.operationData[currentOwner].birthdate).format(
								'DD-MM-YYYY'
							),
							nif: detailsCertificate.operationData[currentOwner].nif,
							phone: detailsCertificate.operationData[currentOwner].phone,
							nifDueDate: dayjs(detailsCertificate.operationData[currentOwner].nifDueDate).format(
								'DD-MM-YYYY'
							)
						}}
						style={{ marginTop: 24 }}>
						<div style={{ marginTop: -20, marginBottom: 24 }}>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{t('clavePin.descModalDes1') +
									' ' +
									detailsCertificate.agentEmail +
									' ' +
									t('clavePin.descModalDes2')}
							</Text>
						</div>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									name="nif"
									label={t('form.dni')}
									style={{
										width: '100%'
									}}>
									<Input
										placeholder={'0000000A'}
										style={{
											width: '100%'
										}}
										onChange={(e) => sendUpdateDataDNI(clickTab, 'nif', e.target.value)}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="nifDueDate"
									label={t('form.dniDate')}
									style={{
										width: '100%'
									}}>
									<InputDate
										type="date"
										style={{
											width: '100%',
											borderRadius: '4px',
											borderColor: '#C0DAEE'
										}}
										onChange={(e) => sendUpdateDataDNI(clickTab, 'nifDueDate', e)}
									/>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item
									name="appClavePin"
									label={t('clavePin.descModal')}
									style={{
										width: '100%'
									}}>
									<Select onChange={(e) => sendUpdateDataDNI(clickTab, 'appClavePin', e)}>
										<Option value={true}>{t('form.yes')}</Option>
										<Option value={false}>{t('form.no')}</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				)}
			</Modal>
            {widgetLink && (
				<Modal
					className="modalbankflip"
					open={modalBankFlip}
					onCancel={() => {
						setModalBankFlip(false);
						clearInterval(timerReference);
					}}
					okButtonProps={{
						style: { border: 'none', contentVisibility: 'hidden' }
					}}
					cancelButtonProps={{
						style: { border: 'none', contentVisibility: 'hidden' }
					}}
					destroyOnClose={true}
					closable={true}
					width={800}>
					<iframe
						width={'100%'}
						height={'800px'}
						src={widgetLink.widgetLink}
						style={{ marginTop: 0, border: 'none' }}></iframe>
				</Modal>
			)}
        </Layout>)
    );
};

export default OperationCertificated;
