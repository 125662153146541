import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Avatar, Button, Modal, Typography, message, Row, Divider } from 'antd';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import env from '../../../../../environment';
import AvatarGenerico from '../../../../../assets/AvatarGenerico.jpg';
import ReviewsCustomer from '../../../../../components/widgets/ReviewsCustomer';
import bola from '../../../../../assets/onboarding/bola.svg';
import arrow from '../../../../../assets/onboarding/ayuda.png';

const { Text } = Typography;

const AnalystInformation = ({ name, phone, email, image, valuesDataAgent, dataValues }) => {
	const { t } = useTranslation();
	const [showModalAvatar, setShowModalAvatar] = useState(false);
	const params = useParams();
	const userToken = useSelector((state) => state.auth.token);

	const sendEmail = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/sales-agents/contact-agent-b2b/${params.operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(() => {
				message.success('Tu mensaje fue enviado');
			});
	};

	return (
		<>
			{!isMobile && (
				<div style={{ textAlign: 'center' }}>
					<div
						style={{
							fontSize: 14,
							fontWeight: 600,
							color: '#2F4858',
							marginBottom: 8
						}}>
						{t('details.commercial')}
					</div>

					{image !== undefined && image !== null ? (
						<img src={image} alt="imageAgent" height={58} />
					) : (
						<Avatar size={58} icon={<UserOutlined />} />
					)}

					<div
						style={{
							fontSize: 12,
							fontWeight: 500,
							color: '#2F4858',
							lineHeight: '16px',
							marginTop: 12
						}}>
						{name}
					</div>
					<div
						style={{
							fontSize: 18,
							fontWeight: 700,
							color: '#02C3CD',
							lineHeight: '24px'
						}}>
						{phone}
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginTop: 12,
							justifyContent: 'center'
						}}>
						<div
							style={{
								fontSize: 12,
								fontWeight: 400,
								color: '#748EA0',
								marginRight: 12
							}}>
							{email}
						</div>
						<Button
							onClick={() => sendEmail()}
							type="primary"
							icon={<MailOutlined style={{ fontSize: 12 }} />}
							style={{ height: 24, width: 24 }}
						/>
					</div>
				</div>
			)}
			{isMobile && !showModalAvatar && (
				<div
					style={{ position: 'fixed', bottom: 30, right: 16 }}
					onClick={() => setShowModalAvatar(!showModalAvatar)}>
					<Avatar
						size={80}
						icon={<UserOutlined />}
						src={
							dataValues && dataValues.analyst && dataValues.analyst.avatar !== null
								? dataValues.analyst.avatar.link
								: 'https://gibobs-assets.s3.eu-central-1.amazonaws.com/assets-dev/6008ddb40164df16002f94c6b1_608a71a603b0c458502cd74a93?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIS4QMZRS5JUUI7VA%2F20210719%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210719T064357Z&X-Amz-Expires=300&X-Amz-Signature=7cca0a6445382b1fb12bf1176fb7a3b3e205a9b852e61288ec043e42e51f25ef&X-Amz-SignedHeaders=host' //""
						}
						style={{ border: '2px solid #02C3CD' }}
					/>
					<div
						style={{
							position: 'absolute',
							width: 35,
							height: 25,
							left: 'calc(85% - 68px - 0.36px)',
							top: 0,
						}}>
						<img src={bola} alt="bola"></img>
					</div>
				</div>
			)}
			<Modal
				className="ModalAnalyst"
				width={328}
				style={{ heigth: 'auto' }}
				open={showModalAvatar}
				closable={false}
				centered
				maskClosable={true}
				footer={null}
				onCancel={() => setShowModalAvatar(!showModalAvatar)}>
				<div >
					<Row>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between'
								}}>
								<Text
									style={{
										fontSize: '24px',
										fontWeight: 700,
										color: '#2F4858'
									}}>
									{t('typeMortgage.modalContactText1')}
								</Text>
								<Text
									style={{
										fontSize: 14,
										fontWeight: 400,
										color: '#2F4858'
									}}>
									{t('typeMortgage.modalContactText2')}&nbsp;
									<b>{dataValues && dataValues?.analyst?.name}</b>,<br />
									{t('typeMortgage.modalContactText3')}.
								</Text>
							</div>
							<img
								style={{
									width: 64,
									height: 64,
									float: 'right',
									borderRadius: 30
								}}
								alt=""
								src={
									dataValues && dataValues.analyst && dataValues.analyst.avatar !== null
										? dataValues?.analyst?.avatar?.link
										: AvatarGenerico
								}></img>
						</div>
					</Row>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{valuesDataAgent && (
							<Text
								style={{
									color: '#2F4858',
									fontSize: 14,
									fontWeight: 600,
									marginTop: 12
								}}>
								{t(`salesAgentExperience.${valuesDataAgent?.salesAgent}`)}
							</Text>
						)}

					</div>
					<Row>
						<hr
							color="#C0DAEE"
							size={1}
							border={'1px solid #C0DAEE'}
							style={{ width: '100%', marginTop: 16 }}></hr>
					</Row>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
							<Text
								style={{
									fontSize: 20,
									fontWeight: 700,
									color: '#02C3CD'
								}}>
								{dataValues && dataValues?.analyst?.phone}
							</Text>
							<a
								href={`https://api.whatsapp.com/send?phone=%2B34${dataValues?.analyst?.phone}`}
								target="_blank">
								<Button
									type="primary"
									icon={<WhatsAppOutlined style={{ fontSize: 12 }} />}
									style={{ height: 24, width: 24, borderRadius: 4 }}></Button>
							</a>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Text
								style={{
									fontSize: 12,
									fontWeight: 600,
									color: '#2F4858',
									marginTop: 5
								}}>
								{dataValues && dataValues?.analyst?.email}
							</Text>
							<a href={`mailto:${dataValues?.analyst?.email}`} target="_blank">
								<Button
									type="primary"
									icon={<MailOutlined style={{ fontSize: 12 }} />}
									style={{ height: 24, width: 24, borderRadius: 4 }}></Button>
							</a>
						</div>
					</div>
					<div className="reviews-analyst-styles" style={{ marginTop: 24 }}>
						<div className="customer-feedback" style={{ marginBottom: 16, color: '#2F4858' }}>
							{t('onboarding.opinionsCustomers')}
						</div>
						<ReviewsCustomer />
					</div>

					<div style={{ marginTop: 24, textAlign: 'center' }}>
						<Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
							{t('advantages.gibobs')}
						</Text>
						<Divider style={{ marginTop: 8, marginBottom: 8 }}></Divider>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							{t('advantages.gibobs2')}
						</Text>{' '}
						<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>
							{t('advantages.gibobs3')}
						</Text>
						<div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
							<img src="/images/documents/startup.png" style={{ width: 100, height: 80 }} alt="startup" />
						</div>
					</div>
				</div>
				<img src={arrow} alt="arrow"
					onClick={() => setShowModalAvatar(!showModalAvatar)}
					style={{
						position: 'absolute',
						bottom: -80,
						right: 0,
						zIndex: 1000,

					}}
				/>
			</Modal>
		</>
	);
};

export default AnalystInformation;
