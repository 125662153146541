import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography, message } from 'antd';
import { useSelector } from 'react-redux';
import ProgressBySteps from './ProgressBySteps';
import ModalsDni from './Modal/ModalsDni';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import LoaderLogo from '../components/LoaderLogo';
import Afterbanks from '../AfterBanks/Afterbanks';
import ErrorAfterbanksModal from './Modal/ErrorAfterbanksModal';
import ModalManyThanksDocumentation from './Modal/ModalManyThanksDocumentation';
import { isMobileOnly } from 'react-device-detect';
import IframeResizer from 'iframe-resizer-react';
import { BulbOutlined } from '@ant-design/icons';

const { Text } = Typography;

type Props = {
	userMortgageId: any;
	stage: any;
	env: any;
	images: Images;
	setValuesData: any;
	setClickTab: any;
	clickTab: any;
	platform: string;
	traceSadabdell: any;
};

type Images = {
	dataPhotos: any;
	errorModal: any;
	dataBanks: any;
	dataMoreBanks: any;
	psd2: any;
	footerGibobs: any;
	footerMobileDocs: any;
	gifBank: any;
	camera: any;
};

export type ReduxStore = {
	auth: StoreAuth;
};

export type StoreAuth = {
	token: string;
	roles: string;
	operations: any;
};

let count = 0;
export const AutomaticDocumentation = ({
	clickTab,
	setValuesData,
	setClickTab,
	env,
	platform,
	userMortgageId,
	stage,
	traceSadabdell,
	images: {
		dataPhotos,
		errorModal,
		dataBanks,
		dataMoreBanks,
		psd2,
		footerGibobs,
		footerMobileDocs,
		gifBank,
		camera
	}
}: Props) => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const userToken = useSelector<ReduxStore, string>((state) => state.auth.token);
	const [nameOperations, setNameOperations] = useState<any>(undefined);
	const [openModals, setOpenModals] = useState(false);
	const [widgetLink, setWidgetLink] = useState<any>(undefined);
	const [stages, setStages] = useState(1);
	const [loading, setLoading] = useState(false);
	const [participants, setParticipants] = useState(1);
	const [guarantors, setGuarantors] = useState(0);
	const [afterBanksVisible, setAfterBanksVisible] = useState(false);
	const [afterBanksVisibleModal, setAfterBanksVisibleModal] = useState(false);
	const [thanksVisibleModal, setThanksVisibleModal] = useState(false);
	const [fileAfterbanks, setFileAfterbanks] = useState<any>(undefined); //upload automatic
	const [stagesAfterBanks, setStagesAfterBanks] = useState(1);
	const [photo, setPhoto] = useState(0);
	const handleFileInput = useRef<any>({});
	const [imageUrl, setImageUrl] = useState(undefined);
	const [saveFileDataField, setSaveFileDataField] = useState([]);

	const { operationId, owner } = useParams<any>();

	let myVar: any = undefined;
	let myVarAf: any = undefined;
	const [completedBankFlip, setCompletedBankFlip] = useState(false);

	useEffect(() => {
		getDataOperation();
		if (stages === 1 && platform === 'sabadell') {
			traceSadabdell(
				stages === 1 ? 'paso dni' : 'conexion datos',
				i18n.language === 'cat' ? 'ca' : 'es'
			);
		}
	}, []);

	useEffect(() => {
		if (widgetLink && stages === 2) {
			if (completedBankFlip) {
				clearInterval(myVar);
			}
			myVar = setInterval(() => {
				getBankFlipProcess();
			}, 2000);
		}
		if (stages === 2 && platform === 'sabadell') {
			traceSadabdell('conexion datos', i18n.language === 'cat' ? 'ca' : 'es');
		}
	}, [widgetLink && stages === 2]);

	useEffect(() => {
		if (stages === 3 && stagesAfterBanks === 1 && platform === 'sabadell') {
			traceSadabdell(
				stagesAfterBanks === 1 ? 'movimientos bancarios' : 'movimientos bancarios listado',
				i18n.language === 'cat' ? 'ca' : 'es'
			);
		}
		if (stages === 3 && stagesAfterBanks === 2 && platform === 'sabadell') {
			traceSadabdell(
				'movimientos bancarios listado',
				i18n.language === 'cat' ? 'ca' : 'es',
				i18n.language === 'cat' ? 'ca' : 'es'
			);
		}
		if (stages === 3) {
			if (afterBanksVisible) {
				clearInterval(myVarAf);
			}
			myVarAf = setInterval(() => {
				getDocuments(owner);
			}, 2000);
		}

		return () => {
			clearInterval(myVarAf);
		};
	}, [stages]);

	const changeCount = () => {
		count = 1;
	};

	const handleClick = () => {
		handleFileInput.current.click();
	};

	async function encodeFileAsBase64URL(file: any) {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener('loadend', () => {
				resolve(reader.result);
			});
			reader.readAsDataURL(file);
		});
	}

	const handleUploadFile = (file: any) => {
		setLoading(true);
		setSaveFileDataField([]);
		axios
			.put(
				`${env.api.url}/v1/documents/operation/attachment`,
				{
					operationId: operationId,
					file: file[0].file,
					fileName: file[0].fileName,
					owner: owner,
					type: 'nif',
					page: 't'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setImageUrl(response.data.data);
				message.success(t('doc.dniSuccess'));
				setLoading(false);
				if (photo === 0) {
					setPhoto(1);
				} else if (photo === 1) {
					setStages(2);
				}
			})
			.catch((error) => console.log(error));
	};

	const saveFileData = (file: any, name: any) => {
		const documentFile = saveFileDataField;
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile);
	};

	const handleImageChange = async (event: any) => {
		const base64URL = await encodeFileAsBase64URL(event.target.files[0]);
		let tmpImg = undefined;
		tmpImg = (base64URL as string).split(',')[1];
		saveFileData(tmpImg, 'dni');
	};

	const getBankFlipProcess = () => {
		axios
			.get(
				`${env.api.url}/docs/bankflip/check-provider-reference/${widgetLink.providerReferences.id}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.data.data.flags.sessionCompleted) {
					setAfterBanksVisible(false);
					setStagesAfterBanks(1);
					if (platform !== 'gibobs') {
						//paso 3 elinado
						setStages(3);
					}
					if (platform === 'gibobs') {
						//paso 3 elinado
						checkNextStep();
					}

					setFileAfterbanks(false);
					setCompletedBankFlip(true);
					clearInterval(myVar);
					setWidgetLink(undefined);
				}
			})
			.catch((error) => {});
	};

	const getDocuments = async (owner: any) => {
		const response = await axios.get(
			`${env.api.url}/v1/documents/get-operation-documents/${operationId}`,
			{
				headers: { Authorization: `Bearer ${userToken}` }
			}
		);
		let tempFileAfterbanks = [];
		tempFileAfterbanks = response.data.data.filter(
			(item: any) =>
				item.tags.includes('automatic') && item.type === 'bankreader' && item.owner === owner
		);
		if (tempFileAfterbanks.length !== 0 && count === 0) {
			setAfterBanksVisible(false);
			setStagesAfterBanks(3);
			setFileAfterbanks(tempFileAfterbanks);
		}
		if (tempFileAfterbanks.length !== 0 && count === 1) {
			setAfterBanksVisible(true);
			//setStagesAfterBanks(3);
			setFileAfterbanks(tempFileAfterbanks);
		}
	};

	const getDataOperation = () => {
		axios
			.get(`${env.api.url}/v1/operations/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setGuarantors(response?.data?.data?.operationData?.mortgage?.guarantors);
				setNameOperations(response.data.data);
				setParticipants(response?.data?.data?.operationData?.mortgage?.participants);
			})
			.catch((error) => {});
	};

	const callToBankFlip = () => {
		setLoading(true);
		axios
			.post(
				`${env.api.url}/docs/bankflip/create-session`,
				{
					operationId: operationId,
					personKey: owner
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setLoading(false);
				setWidgetLink(response.data.data);
			});
	};

	const documentTabs = [];

	for (let i = 1; i <= participants; i++) {
		documentTabs.push(`owner${i}`);
	}

	for (let i = 1; i <= guarantors; i++) {
		documentTabs.push(`guarantor${i}`);
	}
	const checkNextStep = () => {
		clearInterval(myVarAf);
		if (participants > 1 && owner === 'owner1') {
			navigate(`/${operationId}/owner2/automatic-documentation`);
			setStages(1);
			setStagesAfterBanks(1);
		} else {
			navigate(`/operations/${operationId}/documents`);
			window.location.reload();
		}
		// if (owner !== documentTabs[documentTabs.length - 1]) {
		// 	navigate(
		// 		`/${operationId}/${documentTabs[documentTabs.indexOf(owner) + 1]}/automatic-documentation`
		// 	);
		// 	setStages(1);
		// } else {
		// 	navigate(`/operations/${operationId}/documents`);
		// }
	};

	const clearInformation = () => {
		count = 0;
		clearInterval(myVarAf);
	};

	return (
		<div style={{ padding: isMobileOnly ? 10 : 20, textAlign: 'center' }}>
			<div
				className="wrapper-flex"
				style={{
					marginBottom: 10,
					marginTop: platform === 'gibobs' && 16,
					zIndex: 999,
					cursor: 'pointer',
					position: 'relative',
					width: 100
				}}
				onClick={() => {
					navigate(`/operations/${operationId}/documents`);
					window.location.reload();
				}}>
				<ArrowLeftOutlined />
				<div className="owner-title-documentation">{t(`mydocuments.${owner}`)}</div>
			</div>
			<ProgressBySteps
				steps={platform === 'gibobs' ? 2 : 3}
				stage={stages}
				env={env}
				platform={platform}
			/>
			{stages === 1 && (
				<>
					{loading && (
						<div
							style={{
								textAlign: 'center',
								height: '50vh',
								backgroundColor: 'white'
							}}>
							<LoaderLogo />
						</div>
					)}
					{platform !== 'gibobs' ? (
						<div style={{ marginBottom: 20 }}>
							<div
								className="need-documents-title"
								dangerouslySetInnerHTML={{
									__html: t('doc.needDocumentsOwners', { owner: t(`mydocuments.${owner}`) })
								}}
							/>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									marginTop: isMobileOnly ? -20 : null
								}}>
								<div
									style={{
										background: '#B8DEEF',
										borderRadius: 8,
										width: 552,
										padding: '8px 16px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										textAlign: 'left'
									}}>
									<img src={camera} alt="icon camera" style={{ marginRight: 8 }} />
									<div
										style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}
										dangerouslySetInnerHTML={{
											__html: t('doc.camera')
										}}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img src={dataPhotos} alt="dataPhotos" height={isMobileOnly ? 200 : 300} />
							</div>
						</div>
					) : (
						<div style={{ marginBottom: 32 }}>
							{isMobileOnly ? (
								<>
									<div
										className="need-documents-title"
										style={{ marginBottom: isMobileOnly && 16 }}
										dangerouslySetInnerHTML={{
											__html:
												photo > 0
													? t('doc.dniBack', { owner: t(`mydocuments.${owner}`) })
													: t('doc.dniFront', { owner: t(`mydocuments.${owner}`) })
										}}></div>
									<img
										src={
											photo > 0
												? '/images/documents/dni_trasera.png'
												: '/images/documents/dni_delantera.png'
										}
										alt="Image DNI"
									/>
								</>
							) : (
								<>
									<div
										className="need-documents-title"
										dangerouslySetInnerHTML={{
											__html: t('doc.needDocumentsOwners', { owner: t(`mydocuments.${owner}`) })
										}}></div>
									<div style={{ marginBottom: 32 }}>
										<img src="/images/documents/dni_delantera.png" alt="Image DNI" />
										<img
											src="/images/documents/dni_trasera.png"
											alt="Image DNI"
											style={{ marginLeft: 16 }}
										/>
									</div>
								</>
							)}
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginTop: 16,
									justifyContent: 'center',
									gap: 8
								}}>
								<BulbOutlined style={{ fontSize: 18, color: '#748EA0' }} />
								<Text
									style={{
										fontSize: isMobileOnly ? 12 : 14,
										fontWeight: 400,
										color: '#748EA0',
										textAlign: isMobileOnly ? 'left' : 'center'
									}}>
									{t('doc.permissions')}
								</Text>
							</div>
						</div>
					)}
					{!isMobileOnly && (
						<div>
							<Button
								className="button-secundary-gibobs"
								style={{ marginRight: 8 }}
								type="dashed"
								onClick={() => {
									setStages(2);
									callToBankFlip();
								}}>
								{platform === 'gibobs' ? t('doc.attachDNI') : t('modalDocuments2.bottonNo')}
							</Button>
							<Button
								onClick={() => setOpenModals(true)}
								className="button-primari-gibobs"
								style={{ width: 200 }}
								type="primary">
								{platform === 'gibobs' ? t('take.photo') : t('init.continueButton')}
							</Button>
						</div>
					)}
					{isMobileOnly &&
						(platform === 'gibobs' ? (
							<div
								style={{
									position: 'fixed',
									width: '100%',
									height: 80,
									bottom: 16,
									left: 0,
									zIndex: 999,
									padding: 16
								}}>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<input
										type="file"
										accept="image/jpeg"
										capture="environment"
										style={{ display: 'none' }}
										ref={handleFileInput}
										onChange={handleImageChange}
									/>
									<Button
										className="button-primari-gibobs"
										type="primary"
										onClick={handleClick}
										style={{ width: '100%' }}>
										{t('doc.openCamera')}
									</Button>
								</div>
								<Button
									style={{
										color: '#02C3CD',
										borderColor: '#fff',
										width: '100%',
										boxShadow: 'none',
										borderRadius: 8
									}}
									onClick={() => {
										setStages(2);
										callToBankFlip();
									}}>
									{t('doc.attachDNI')}
								</Button>
							</div>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Button
									onClick={() => setOpenModals(true)}
									className="button-primari-gibobs"
									type="primary">
									{t('init.continueButton')}
								</Button>
								<Button
									className="button-secundary-gibobs"
									style={{
										border: 'none',
										backgroundColor: platform === 'gibobs' ? '#FAFEFE' : '#f5f5f5',
										boxShadow: undefined
									}}
									type="dashed"
									onClick={() => {
										setStages(2);
										callToBankFlip();
									}}>
									{t('modalDocuments2.bottonNo')}
								</Button>
							</div>
						))}
				</>
			)}
			{stages === 2 && (
				<div style={{ marginBottom: 200 }}>
					{loading && widgetLink?.widgetLink !== undefined ? (
						<div
							style={{
								textAlign: 'center',
								height: '50vh',
								backgroundColor: 'white'
							}}>
							<LoaderLogo />
						</div>
					) : (
						<>
							<IframeResizer
								log
								src={widgetLink?.widgetLink}
								style={{
									width: '1px',
									minWidth: '100%',
									border: 'none',
									minHeight: platform !== 'gibobs' ? 800 : 720,
									marginTop: !isMobileOnly && -50
								}}
								checkOrigin={['https://bkfp.io', 'https://widget.bankflip.io']}
								heightCalculationMethod="lowestElement"
								inPageLinks
							/>
							{widgetLink?.widgetLink && (
								<div style={{ marginTop: 0 }}>
									<Button
										style={{
											border: isMobileOnly && 'none',
											backgroundColor: isMobileOnly && platform !== 'gibobs' && '#f5f5f5',
											boxShadow: isMobileOnly && undefined
										}}
										className="button-secundary-gibobs"
										type="dashed"
										onClick={() => {
											//paso 3 elinado
											if (platform !== 'gibobs') {
												setStages(3);
											}
											if (platform === 'gibobs') {
												checkNextStep();
												//paso 3 elinado
											}
											clearInterval(myVar);
										}}>
										{platform === 'gibobs' ? t('doc.attachDNI') : t('modalDocuments2.bottonNo')}
									</Button>
								</div>
							)}
						</>
					)}
				</div>
			)}
			{stages === 3 && stagesAfterBanks === 1 && (
				<div style={{ marginBottom: 200 }}>
					<div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
						<div
							style={{
								width: platform === 'sabadell' ? undefined : 500,
								textAlign: platform === 'sabadell' ? undefined : 'left',
								marginBottom: -5
							}}
							className="need-documents-title"
							dangerouslySetInnerHTML={{
								__html:
									platform === 'sabadell'
										? t('doc.bankMovementsDescription')
										: t('doc.bankMovementsDescriptionGibobs')
							}}
						/>
					</div>

					{platform !== 'sabadell' && (
						<div
							style={{
								width: '100%',
								justifyContent: 'center',
								display: 'flex',
								marginBottom: 24
							}}>
							<Card className="gb-card-banks-onboarding" style={{ width: 552 }}>
								<div style={{ display: 'flex' }}>
									<img src={psd2} alt="psd2" style={{ height: 48, width: 48 }}></img>
									<div style={{ marginLeft: 8 }}>
										<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
											{t('doc.bankMovementsDescriptionGibobs1')}
										</Text>
										&nbsp;
										<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 600 }}>
											{t('doc.bankMovementsDescriptionGibobs2')}
										</Text>
									</div>
								</div>
							</Card>
						</div>
					)}
					<div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
						<img
							src={platform === 'sabadell' ? dataPhotos : dataBanks}
							alt="dataBanks"
							height={isMobileOnly ? 200 : 300}
						/>
					</div>
					{!isMobileOnly && (
						<div>
							<Button
								className="button-secundary-gibobs"
								style={{ marginRight: 8 }}
								type="dashed"
								onClick={() => {
									clearInterval(myVarAf);
									checkNextStep();
								}}>
								{t('modalDocuments2.bottonNo')}
							</Button>
							<Button
								onClick={() => {
									setAfterBanksVisible(true);
									setStagesAfterBanks(2);
								}}
								className="button-primari-gibobs"
								style={{ width: 200 }}
								type="primary">
								{t('init.continueButton')}
							</Button>
						</div>
					)}

					{isMobileOnly && (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Button
								onClick={() => {
									setAfterBanksVisible(true);
									setStagesAfterBanks(2);
								}}
								className="button-primari-gibobs"
								type="primary">
								{t('init.continueButton')}
							</Button>
							<Button
								className="button-secundary-gibobs"
								style={{
									marginRight: 8,
									border: 'none',
									backgroundColor: platform === 'gibobs' ? '#FAFEFE' : '#f5f5f5',
									boxShadow: undefined
								}}
								type="dashed"
								onClick={() => {
									clearInterval(myVarAf);
									checkNextStep();
								}}>
								{t('modalDocuments2.bottonNo')}
							</Button>
						</div>
					)}
				</div>
			)}
			{stages === 3 && stagesAfterBanks === 2 && (
				<div style={{ marginBottom: 200 }}>
					{platform !== 'sabadell' && !isMobileOnly && (
						<>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<img src={gifBank} alt="asdf" style={{ height: 77, width: 300 }}></img>
								<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
									{t('bank.newConnected')}
								</Text>
							</div>

							<div
								style={{
									width: '100%',
									justifyContent: 'center',
									display: 'flex',
									marginBottom: 24
								}}>
								<Card
									className="gb-card-banks-onboarding"
									style={{ width: 960, marginBottom: -40, height: 64, marginTop: 24 }}>
									<div style={{ display: 'flex' }}>
										<img src={psd2} alt="psd2" style={{ height: 48, width: 48 }}></img>
										<div style={{ marginLeft: 8 }}>
											<div
												style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}
												dangerouslySetInnerHTML={{
													__html: t('banks.newInfoOnboarding')
												}}></div>
										</div>
									</div>
								</Card>
							</div>
						</>
					)}
					{platform !== 'sabadell' && isMobileOnly && (
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<img src={gifBank} alt="asdf" style={{ height: 72, width: 300 }}></img>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
								{t('Conectando con tu banco...')}
							</Text>
						</div>
					)}
					{platform !== 'sabadell' && isMobileOnly && (
						<div
							style={{
								width: '100%',
								justifyContent: 'center',
								display: 'flex',
								marginBottom: 24
							}}>
							<Card className="gb-card-banks-onboarding" style={{ marginTop: 24 }}>
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<img src={psd2} alt="psd2" style={{ height: 48, width: 48 }}></img>
									<div style={{ marginLeft: 8 }}>
										<div
											style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}
											dangerouslySetInnerHTML={{
												__html: t('banks.newInfoOnboarding')
											}}></div>
									</div>
								</div>
							</Card>
						</div>
					)}
					<Afterbanks type={'mortgage'} nameOwner={owner} env={env} />

					{platform !== 'sabadell' && !isMobileOnly && (
						<div style={{ width: '100%', marginBottom: 24, marginTop: 10 }}>
							<img src={footerGibobs} alt="footerGibobs" style={{ width: '100%' }}></img>
						</div>
					)}
					{platform !== 'sabadell' && isMobileOnly && (
						<div style={{ width: '100%', marginBottom: 24, marginTop: 10 }}>
							<img
								src={footerMobileDocs}
								alt="footerMobileDocs"
								style={{ width: '90%', height: '10%' }}></img>
						</div>
					)}

					<Button
						className="button-secundary-gibobs"
						style={{ marginRight: 8, marginTop: 5 }}
						type="dashed"
						onClick={() => {
							checkNextStep();
						}}>
						{t('modalDocuments2.bottonNo')}
					</Button>
					{(fileAfterbanks?.length >= 2 || owner !== 'owner1') && (
						<Button
							onClick={() => {
								setAfterBanksVisible(false);
								setStagesAfterBanks(3);
							}}
							className="button-primari-gibobs"
							style={{ width: 200 }}
							type="primary">
							{t('init.continueButton')}
						</Button>
					)}
				</div>
			)}
			{stages === 3 && stagesAfterBanks === 3 && (
				<div style={{ marginBottom: 200 }}>
					<div
						style={{ marginBottom: -5 }}
						className="need-documents-title"
						dangerouslySetInnerHTML={{
							__html: t('doc.moreBankStatementsDescription')
						}}
					/>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img
							src={platform === 'sabadell' ? dataPhotos : dataMoreBanks}
							alt="dataMoreBanks"
							height={isMobileOnly ? 200 : 300}
						/>
					</div>

					{!isMobileOnly && (
						<div>
							<Button
								className="button-secundary-gibobs"
								style={{ marginRight: 8 }}
								type="dashed"
								onClick={() => {
									setAfterBanksVisible(true);
									setStagesAfterBanks(2);
									changeCount();
								}}>
								{t('doc.moreBankStatementsButton')}
							</Button>
							<Button
								onClick={() => {
									clearInterval(myVarAf);
									setThanksVisibleModal(true);
									setAfterBanksVisible(true);
								}}
								className="button-primari-gibobs"
								style={{ width: 200 }}
								type="primary">
								{t('certificated.buttonFinish')}
							</Button>
						</div>
					)}
					{isMobileOnly && (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Button
								onClick={() => {
									clearInterval(myVarAf);
									setThanksVisibleModal(true);
									setAfterBanksVisible(true);
								}}
								className="button-primari-gibobs"
								style={{ width: '100%' }}
								type="primary">
								{t('certificated.buttonFinish')}
							</Button>
							<Button
								className="button-secundary-gibobs"
								style={{
									marginRight: 8,
									width: '100%',
									backgroundColor: platform === 'gibobs' ? '#FAFEFE' : '#f5f5f5',
									boxShadow: undefined
								}}
								type="dashed"
								onClick={() => {
									setAfterBanksVisible(true);
									setStagesAfterBanks(2);
									changeCount();
								}}>
								{t('doc.moreBankStatementsButton')}
							</Button>
						</div>
					)}
				</div>
			)}
			<ErrorAfterbanksModal
				visible={afterBanksVisibleModal}
				errorModal={errorModal}
				onCancel={() => {
					setAfterBanksVisible(true);
					setAfterBanksVisibleModal(false);
					setFileAfterbanks(false);
					setStagesAfterBanks(2);
				}} //Reintentar
				onCreate={() => {
					setAfterBanksVisible(false);
					setAfterBanksVisibleModal(false);
					setFileAfterbanks(false);
					checkNextStep();
					clearInformation();
				}} //Finalizar
			/>
			<ModalManyThanksDocumentation
				visible={thanksVisibleModal}
				owner={owner}
				numParticipants={participants}
				onCreate={() => {
					checkNextStep();
					clearInformation();
					setThanksVisibleModal(false);
				}}

				//Continue
			/>

			{nameOperations && openModals && (
				<ModalsDni
					env={env}
					userMortgageId={operationId}
					setOpenModals={() => setOpenModals(false)}
					setStages={() => {
						setOpenModals(false);
						setStages(2);
						callToBankFlip();
					}}
					platform={platform}
					traceSadabdell={traceSadabdell}
				/>
			)}
		</div>
	);
};
