import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import arrow from '../../../assets/onboarding/arrow.svg';
import mortgageIcon from '../../../assets/icons/mortgageIcon.svg';
import SubrogacionMobile from '../../../assets/icons/SubrogacionMobile.svg';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import questionIcon from '../../../assets/onboarding/questionIcon.svg';

const WelcomeSelection = ({  }) => {
	let { operationId } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();;
	const userToken = useSelector((state) => state.auth.token);
	const dataProfile = useSelector((state) => state.data.dataProfile);
	const [loading, setLoading] = useState(false);
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const [visibleModalExplination, setVisibleModalExplination] = useState(false);

	useEffect(() => {
		sessionStorage.setItem('modalSub', false);
	}, []);

	const optionsOperations = (title, icon, subtype) => {
		return (
			<Col xs={24} lg={12}>
				<div
					onClick={() => sendTypeOperation(subtype)}
					className={
						icon === mortgageIcon
							? 'caja-externa centrar-contenido background-mortgage'
							: 'caja-externa centrar-contenido background-subrogation'
					}>
					<div class="caja-interna" style={{ fontFamily: 'Inter' }}>
						<div style={{ textAlign: 'center' }}>
							<img src={icon} alt="mortgageIcon" style={{ marginTop: 3 }} />
							<div style={{ display: 'flex', marginTop: 12 }}>
								<div className="text">{t(title)}</div>
								<img src={arrow} alt="arrow" />
							</div>
						</div>
					</div>
				</div>
			</Col>
		);
	};

	if (loading) {
		return <Spin indicator={antIcon} className="spin" />;
	}

	const sendTypeOperation = (subtype) => {
		if (operationId === undefined) {
			setLoading(true);
			axios
				.get(`${env.api.url}/api/clients/createCustomerMortgage?type=${subtype}`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
				.then((response) => {
					if (response.data.success) {
						const id = response.data.data.id;
						axios
							.post(
								`${env.api.url}/v1/mortgages/data/multiple/update/${response.data.data.id}`,
								{
									values: [
										{
											key: 'mortgage',
											subkey: 'type',
											value: subtype
										},
										{
											key: 'process',
											subkey: 'operationTypeToBeDefined',
											value: false
										}
									]
								},

								{
									headers: { Authorization: `Bearer ${userToken}` }
								}
							)
							.then((response) => {
								if (response.data.success) {
									setLoading(false);
									axios
										.post(
											`${env.api.url}/campaigns/runner/experiment/by-event`,
											{
												event: 'questions-mortgages',
												data: {
													operationId: id
												}
											},
											{
												headers: { Authorization: `Bearer ${userToken}` }
											}
										)
										.then((response) => {
											//setOption(response.data.data);

											if (response.data.data.mortgageQuestions === '2022') {
												navigate(`/testA/operations/${id}/basic`);
												//window.location.reload()
											} else {
												navigate(`/operations/${id}/basic`);
												// window.location.reload()
											}
										});
								}
							});
					}
				});
		} else {
			axios
				.post(
					`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
					{
						values: [
							{
								key: 'mortgage',
								subkey: 'type',
								value: subtype
							},
							{
								key: 'process',
								subkey: 'operationTypeToBeDefined',
								value: false
							}
						]
					},
					{
						headers: { Authorization: `Bearer ${userToken}` }
					}
				)
				.then((response) => {
					if (response.data.success) {
						setLoading(false);

						navigate(`/testA/operations/${operationId}/basic`);
						window.location.reload();
					}
				});
		}
	};

	return (
        (<div
			className={!isMobileOnly && 'desktop-background'}
			style={{ marginBottom: isMobileOnly && 400 }}>
            <div
				className={isMobileOnly ? 'welcome-page' : 'welcome-page'}
				style={{
					width: !isMobileOnly && '60%',
					marginTop: !isMobileOnly ? 100 : 20,
					// height: !isMobileOnly && "90vh",
				}}>
				<div className="title">
					{t('onboarding.whatNeed')}

					<img
						style={{ marginLeft: 8 }}
						src={questionIcon}
						alt="questionIcon"
						onClick={() => setVisibleModalExplination(true)}
					/>
				</div>

				<div style={{ display: 'flex' }}>
					<div className="description">{t('typeMortgage.hello')}</div>
					<div className="name">{dataProfile && dataProfile.name},</div>
				</div>
				<div className="description">{t('onboarding.getMortgage')}</div>
				<div className="description" style={{ marginTop: 0 }}>
					{t('onboarding.chooseOption')}
				</div>
				<Row
					gutter={36}
					style={{
						margin: '0px',
						marginTop: isMobileOnly ? 24 : 90,
						rowGap: 24
					}}>
					{optionsOperations('onboarding.findBestMortgage', mortgageIcon, 'mortgage')}
					{optionsOperations('onboarding.improveCurrentMortgage', SubrogacionMobile, 'subrogation')}
				</Row>
			</div>
            <Modal
				centered
				title={<span style={{ width: 300 }}>{t('modal.onboardingpage1')}</span>}
				className="modal-onboarding-stepper"
				width={500}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { display: 'none' }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { display: 'none' }
				}}
				cancelText={t('form.back')}
				open={visibleModalExplination}
				onCancel={() => {
					setVisibleModalExplination(!visibleModalExplination);
				}}
				destroyOnClose={true}
				closable={true}>
				{t('modal.onboardingpage1Text')}
			</Modal>
        </div>)
    );
};

export default WelcomeSelection;
