import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import env from '../../environment';
import { notificationGibobs } from '../../Pages/HerramientasVenta/reusableItems';
import copyIcon from '../../assets/modalAmigobs/CopyIcon.svg';
import closableIcon from '../../assets/modalAmigobs/closableIcon.png';
import { getAnalytics, logEvent } from 'firebase/analytics';
import googleIcon from '../../assets/icons/googleIcon.svg';
import starsGoogle from '../../assets/icons/stars-google.svg';
import { isMobileOnly } from 'react-device-detect';
import { RWebShare } from 'react-web-share';

const { Text } = Typography;

function ModalFriend({ visible, onCancel }) {
    const { t } = useTranslation();
    const userToken = useSelector((state) => state.auth.token);
    const [dataProfile, setDataPersonal] = useState([]);
    const analytics = getAnalytics();

    const temp = `Si estás pensando en comprarte una casa o cambiarte de hipoteca porque tu cuota no para de subir, gibobs puede ayudarte. 💪🏡\n1. Es 100% gratuito para ti por sus acuerdos con los bancos\n2. Te buscarán la mejor hipoteca entre todas las opciones de mercado\n3. Tendrás un analista personal que te acompañará en todo el proceso\nYo lo he usado y estoy muy contento. Deja que analicen tu caso y vean si pueden ayudarte también a tí. 🎉\n`;

    useEffect(() => {
        dataPersonalOperation();
    }, []);

    const dataPersonalOperation = () => {
        axios
            .get(`${env.api.url}/v1/user/profile`, {
                headers: { Authorization: `Bearer ${userToken}` }
            })
            .then((response) => {
                setDataPersonal(response.data.data.profile);
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const copyToClipBoard = () => {
        logEvent(analytics, "select_content", {
            content_type: "b_copy_link",
        })
        var aux = document.createElement('input');
        aux.setAttribute(
            'value',
            `https://www.gibobs.com/plan-amigobs/?friend=${dataProfile?.friendName}`
        );
        document.body.appendChild(aux);
        aux.select();
        document.execCommand('copy');
        document.body.removeChild(aux);
        notificationGibobs('success', t('form.codeCopiedSuccessfully'));
    };

    const renderLinks = () => {
        if (dataProfile?.friendLink) {
            return (
                <>
                    <Row className="copyLink">
                        <Col span={16}>
                            <Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
                                {dataProfile?.friendLink}
                            </Text>
                        </Col>
                        <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div
                                onClick={() => copyToClipBoard()}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <Text style={{ fonSize: 14, fontWeight: 500, color: '#0088C6' }}>
                                    {t('modalFriend.copyLink')}
                                </Text>
                                <img src={copyIcon} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 453 }}>
                        <RWebShare
                            data={{
                                text: window.encodeURIComponent(temp),
                                url: dataProfile?.friendLink,
                                title: t("amigobs.shareLink"),
                            }}
                            onClick={() =>
                                logEvent(analytics, "select_content", {
                                    content_type: "b_share_link",
                                }
                                )}>
                            <Button className="buttonLink">{t('modalFriend.shareLink')}</Button>
                        </RWebShare>
                    </Row>
                    <Row style={{ marginTop: 30, color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
                        <Text>
                            {t('modalFriend.discover')}&nbsp;
                            <a href="https://www.gibobs.com/plan-amigobs-terminos-y-condiciones/" style={{ color: '#2F4858', textDecoration: 'underline' }}>
                                {t('modalFriend.details')}
                            </a>
                        </Text>
                    </Row>
                </>
            );
        }
    };

    const renderLinksMobile = () => {
        if (dataProfile?.friendLink) {
            return (
                <>
                    <div style={{ borderRadius: 9, background: 'white', padding: 18 }}>
                        <Row>
                            <Col span={24}>
                                <Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
                                    {dataProfile?.friendLink}
                                </Text>
                            </Col>
                            <div
                                onClick={() => copyToClipBoard()}
                                style={{
                                    marginTop: 9,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                <Text style={{ color: '#0088C6', fontSize: 14, fontWeight: 500 }}>
                                    {t('modalFriend.copyLink')}
                                </Text>&nbsp;
                                <img src={copyIcon} />
                            </div>
                        </Row>
                    </div>
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <RWebShare
                            data={{
                                text: temp,
                                url: dataProfile?.friendLink,
                                title: t("amigobs.shareLink")
                            }}
                            onClick={() =>
                                logEvent(analytics, "select_content", {
                                    content_type: "b_share_link",
                                }
                                )}>
                            <Button className="buttonLink" style={{ width: '100%' }}>
                                {t('modalFriend.shareLink')}
                            </Button>
                        </RWebShare>
                    </Row>
                    <Row style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}>
                        <Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
                            {t('modalFriend.discover')}&nbsp;
                            <a href="https://www.gibobs.com/plan-amigobs-terminos-y-condiciones/" style={{ color: '#2F4858', textDecoration: 'underline' }}>
                                {t('modalFriend.details')}
                            </a>
                        </Text>
                    </Row>
                </>
            );
        }
    };

    const renderGoogleReviews = (mobile) => {
        return(
            <div style={{ 
                display: 'flex',
                justifyContent: 'center',
                flexDirection: !mobile && 'column',
                padding: 16, 
                width: !mobile && 150,
                borderRadius: 8,
                gap: mobile && 10,
                backgroundColor: !mobile && 'rgba(255, 255, 255, 0.9)' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img alt='Google icon' src={googleIcon} height={20} />
                    <div style={{ display:'flex', alignItems: 'center' }}>
                        <img alt='stars' src={starsGoogle} height={16} />
                        <div className="rating-styles">{t('reviews.numberStarsGoogle')}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: mobile && 'column', marginTop: 4 }}>
                    <a href={'https://www.google.com/search?q=gibobs+all+banks&rlz=1C5CHFA_enES1002ES1002&oq=gibob[…]99i512j0i20i263i512j69i60l3.2548j0j7&sourceid=chrome&ie=UTF-8'} target="_blank">
                        <div className="reviews-styles">{t('reviews.quantityGoogle')}</div>
                    </a>
                    <div className="customer-feedback" style={{ margin: '0px 6px' }}>
                        {t('onboarding.excellentReview')}
                    </div>
                </div>
            </div>
        )
    }

    if (isMobileOnly) {
        return (
            <Modal
                className="ModalAmigobs"
                open={visible}
                footer={null}
                centered
                onCancel={onCancel}
                closable={true}
                maskClosable={true}
                closeIcon={<img style={{ width: 24, height: 24 }} src={closableIcon} alt="closableIcon"></img>}>
                <Row style={{
                    height: '90vh',
                    backgroundImage: 'url(https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/app/Amigobs_mob.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 8,
                }}>
                    <div style={{ position: 'absolute', bottom: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <Text style={{
                            color: '#037FB8',
                            fontSize: 20,
                            fontWeight: 600,
                            lineHeight: 1.2
                        }}>
                            {t('modalFriend.invite1')}<br />
                            <span style={{ color: '#2F4858' }}>
                                {t('modalFriend.invite2')}
                            </span>
                        </Text>
                        <div style={{ marginTop: 24 }}>{renderLinksMobile()}</div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>{renderGoogleReviews(true)}</div>
                    </div>
                </Row>
            </Modal>
        );
    } else {
        return (
            <Modal
                className="ModalAmigobs"
                open={visible}
                width={1120}
                footer={null}
                onCancel={() => onCancel()}
                maskClosable={false}
                closeIcon={<img style={{ width: 40, height: 40 }} src={closableIcon} alt="closableIcon"></img>}>
                <Row
                    style={{
                        padding: 40,
                        backgroundImage: 'url(https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/app/Amigobs_desk.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: 10,
                    }}>
                    <Col span={13}>
                        <Text style={{ color: '#2F4858', fontSize: 24, fontWeight: 600 }}>
                            {t('modalFriend.plan')}
                        </Text><br />
                        <Text
                            style={{
                                color: '#037FB8',
                                fontSize: 40,
                                fontWeight: 600,
                                lineHeight: 1.2,
                                marginTop: -20
                            }}>
                            {t('modalFriend.invite1')} <br />
                            <span style={{ color: '#2F4858', fontSize: 40, fontWeight: 600 }}>
                                {t('modalFriend.invite2')}
                            </span>
                        </Text>
                        {renderLinks()}
                    </Col>
                    <Col span={11} style={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end'}}>
                        {renderGoogleReviews(false)}
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default ModalFriend;
