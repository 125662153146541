import React, { useState } from 'react';
import { Card, Typography, Progress, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import question from '../../../../assets/loans/question.png';
import chebron from '../../../../assets/loans/chebron.png';
import euriborIcon from '../../../../assets/loans/euriborIcon.svg';
import { isMobileOnly } from 'react-device-detect';
import EuriborCalculator from '../Modals/EuriborCalculator';
import { getAnalytics, logEvent } from 'firebase/analytics';

const { Text } = Typography;

const EuriborCalculatorWidget = ({ loan }) => {
	const { t } = useTranslation();
	let today = moment();
	const [calculateQuote, setCalculateQuote] = useState(false);
	const analytics = getAnalytics();

	const textEuribor = () => {
		if (loan.loan.diffReviewQuota < 0 && loan?.pendingMonthsToReviewQuota === 1) {
			return 'loan.oneMonthPositiveVariableRateMessage';
		} else if (loan.loan.diffReviewQuota < 0 && loan?.pendingMonthsToReviewQuota !== 1) {
			return 'loan.positiveVariableRateMessage';
		} else if (loan.loan.diffReviewQuota > 0 && loan?.pendingMonthsToReviewQuota === 1) {
			return 'loan.oneMonthNegativeVariableRateMessage';
		}  else if (loan.loan.diffReviewQuota > 0 && loan?.pendingMonthsToReviewQuota !== 1) {
			return 'loan.negativeVariableRateMessage';
		}
		else {
			return 'loan.negativeVariableRateMessage';
		}
	};
	return (
		<>
			<Card
				className="gcardMovile card-heigth-loan"
				style={{
					marginTop: isMobileOnly ? 12 : 16,
					height: 'calc(100% - 36px)'
				}}>
				<div
					style={{
						height: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column'
					}}>
					{loan.loan.rateType === 'variable' && (
						<>
							<Row gutter={24}>
								<Col span={3}>
									{loan.loan.diffReviewQuota > 0 && (
										<ArrowUpOutlined
											style={{
												color: '#FF4D4F',
												fontSize: 20,
												marginTop: 15
											}}
										/>
									)}
									{loan.loan.diffReviewQuota < 0 && (
										<ArrowDownOutlined
											style={{
												color: '#52C41A',
												fontSize: 20,
												marginTop: 15
											}}
										/>
									)}
								</Col>
								<Col span={21}>
									<div
										style={{
											fontSize: 14,
											color: '#748EA0',
											fontWeight: 400
										}}
										dangerouslySetInnerHTML={{
											__html: t(textEuribor(), {
												nextReview: loan?.pendingMonthsToReviewQuota,
												quotaDifference: parseInt(Math.abs(loan?.loan?.diffReviewQuota))
											})
										}}
									/>
								</Col>
							</Row>

							<Row style={{ alignItems: 'center', marginTop: isMobileOnly && 24 }}>
								<Col span={3}>
									<img src={question} alt="asdf" style={{ width: 24, height: 24 }}></img>
								</Col>
								<Col span={21}>
									<Text
										style={{
											fontSize: 14,
											color: '#748EA0',
											fontWeight: 400
										}}>
										{t('loan.changeEuribor')}
									</Text>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<div style={{ textAlign: 'right', marginBottom: isMobileOnly && 24 }}>
										<a
											onClick={() => {
												setCalculateQuote(!calculateQuote);
												logEvent(analytics, 'select_content', {
													content_type: 'widget.euribor.calculator',
													content_id: 'button.calculate.quota'
												});
											}}
											style={{
												fontSize: 14,
												color: '#02C3CD',
												fontWeight: 500
											}}>
											{t('loan.changeEuriborQuote')}
										</a>
										<img
											src={chebron}
											alt="asdf"
											style={{
												width: 16,
												height: 16,
												marginTop: 0,
												marginLeft: 6
											}}></img>
									</div>
								</Col>
							</Row>
						</>
					)}
					{loan.loan.rateType === 'fixed' && (
						<div
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between'
							}}>
							<img src={euriborIcon} alt="euriborIcon" height={35} />
							<div
								style={{
									color: '#748EA0',
									fontSize: 14,
									fontWeight: 400,
									marginTop: 16
								}}
								dangerouslySetInnerHTML={{
									__html: t('loan.descriptionEuriborWithoutData')
								}}
							/>
						</div>
					)}
				</div>
			</Card>
			<EuriborCalculator
				key={'calculateQuote'}
				visible={calculateQuote}
				onCreate={() => setCalculateQuote(false)}
				onCancel={() => setCalculateQuote(false)}
				title={'loan.tinTitle'}
				text={t('loan.tinDescription')}
				loan={loan}
			/>
		</>
	);
};

export default EuriborCalculatorWidget;
